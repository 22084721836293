import { writable } from 'svelte/store'
import AsyncLock from 'async-lock'
import { apiCall } from '../lib/api'
import { appInfo } from '../lib/appInfo'
import { executeWhenNotCritical } from './critical'
import { reloadPage } from '../lib/utils'
import { getSettings, isYaoKiosk, updateSettings } from './yaoKiosk'

const lock = new AsyncLock()

export const bundle = writable(null)
export default bundle

export let currentBundle
bundle.subscribe($bundle => {
  if (!window.$yo) window.$yo = {}
  window.$yo.bundle = $bundle

  currentBundle = $bundle
})

window.addEventListener('backendWsMessage', event => {
  if (event.detail.type === 'bundleDate' && new Date(event.detail.bundleDate).valueOf() !== currentBundle?.timestamp.valueOf()) {
    executeWhenNotCritical('updateBundle', () => updateBundle())
  }
})

export async function updateBundle () {
  await lock.acquire('updateBundle', async () => {
    console.log('Updating bundle')
    const prevVersion = bundle?.serverVersion ?? appInfo.serverVersion
    const newBundle = await apiCall('GET', '/api/app/bundle')

    if (isYaoKiosk && typeof newBundle.settings?.employeeAccessCode === 'string') {
      const settings = await getSettings()
      if (settings.password !== newBundle.settings.employeeAccessCode) {
        console.log('Employee access code updated! New code: ' + newBundle.settings.employeeAccessCode)
        executeWhenNotCritical('updateEmployeeAccessCode', async () => {
          const settings = await getSettings()
          settings.password = newBundle.settings.employeeAccessCode
          await updateSettings(settings)
          await reloadPage()
        })
      }
    }

    if (prevVersion !== newBundle.serverVersion) {
      console.log('Server version updated!')
      executeWhenNotCritical('reload', () => reloadPage(true))
    } else {
      bundle.set(newBundle)
    }
  })
}
