<script>
  import { fly, scale } from 'svelte/transition'
  import { tC } from '../stores/i18n'
  import { appendToBody } from '../lib/utils'
  import { second } from '../stores/timer'
  import { createEventDispatcher } from 'svelte'
  import ThumbsUp from 'svelte-feather/components/ThumbsUp.svelte'
  import Check from 'svelte-feather/components/Check.svelte'
  import BrandButton from './BrandButton.svelte'

  const dispatch = createEventDispatcher()

  export let phoneNumber
  export let estimatedWaitingTime

  const startTime = Date.now()
  const closeTime = startTime + 20000

  $: remainingTime = $second && Math.max(0, Math.round((closeTime - Date.now()) / 1000))

  $: if (remainingTime <= 0) dispatch('close')
</script>

<style lang="scss">
  main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background: $brand-color;
    color: white;
    font-size: 4rem;

    padding: 4rem;
    gap: 3vh;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 2;

    .thumbs-up-icon :global(svg) {
      width: 12rem;
      height: 12rem;
    }

    h1 {
      font-size: 6rem;
    }

    :global(.brand-button) {
      font-size: 3rem;
      height: auto;
      padding: 1.25rem 5rem;
    }
  }
</style>

<main transition:scale|global use:appendToBody>
  <div in:scale|global={{ delay: 400 }} class="thumbs-up-icon">
    <ThumbsUp />
  </div>

  <h1 class="title is-1" in:fly|global={{ delay: 500, y: 50 }}>{$tC({ de: 'Erfolgreich registriert!', en: 'Successfully registered!' })}</h1>
  <h2 class="title is-2" in:fly|global={{ delay: 600, y: 50 }}>{$tC({ de: 'Ihre Telefonnummer:', en: 'Your phone number:' })}<br /><strong>{phoneNumber}</strong></h2>
  {#if estimatedWaitingTime > 0}
    <h2 class="title is-2" in:fly|global={{ delay: 700, y: 50 }}>{$tC({ de: 'Geschätzte Wartezeit:', en: 'Estimated waiting time:' })}<br /><strong>{estimatedWaitingTime} {$tC({ de: 'Minuten', en: 'minutes' })}</strong></h2>
  {/if}
  <h2 class="title is-2" in:fly|global={{ delay: 800, y: 50 }}>{$tC({ de: 'Sie haben soeben eine Willkommens-SMS erhalten. Sobald ein Tisch für Sie frei wird, erhalten Sie eine weitere SMS-Benachrichtigung. Folgen Sie dann den Anweisungen in der Nachricht. Bis später!', en: 'You have just received a welcome SMS. As soon as a table becomes available for you, you will receive another SMS notification. Then follow the instructions in the message. See you later!' })}</h2>
  <h3 class="title is-3" in:fly|global={{ delay: 900, y: 50 }}>{$tC({ de: '(Falls Sie die Willkommens-SMS nicht erhalten haben und auch in den nächsten 30 Sekunden nicht erhalten, wenden Sie sich bitte an einen Mitarbeiter.)', en: '(If you have not received the welcome SMS and do not receive it in the next 20 seconds, please contact a member of staff.)' })}</h3>

  <div in:scale|global={{ delay: 1100, y: 50 }}>
    <BrandButton --border-color="white" --bg-color="white" on:click={() => dispatch('close')}><Check /> {$tC({ de: 'Alles klar!', en: 'Okay!' })} ({remainingTime})</BrandButton>
  </div>
</main>
