<script>
  import { tC } from '../stores/i18n'
  import ModalDialog from './ModalDialog.svelte'
  import BrandButton from './BrandButton.svelte'
  import { userData } from '../stores/session'
  import { allergens } from '../../payload/i18nConstants'
  import { createEventDispatcher } from 'svelte'
  import Check from 'svelte-feather/components/Check.svelte'

  const dispatch = createEventDispatcher()

  function toggle (value) {
    $userData.allergenFilter = (
      $userData.allergenFilter.includes(value)
        ? $userData.allergenFilter.filter(v => v !== value)
        : [...$userData.allergenFilter, value]
    ).sort()
  }
</script>

<style lang="scss">
  main {
    column-count: 2;
    column-gap: 0.5rem;
    width: 70vw;

    :global(.brand-button) {
      margin-bottom: 0.5rem;
    }

    margin-bottom: -0.5rem;
  }
</style>

<ModalDialog title={$tC({ de: 'Allergene ausschließen', en: 'Exclude Allergens' })} closeable on:close>
  <main>
    {#each Object.values(allergens) as { value, label }}
      <BrandButton class="is-fullwidth" toggle selected={$userData.allergenFilter.includes(value)} on:click={() => toggle(value)}>
        {$tC(label)}
      </BrandButton>
    {/each}
  </main>

  <hr />

  <BrandButton class="is-fullwidth is-primary" on:click={() => dispatch('close')}><Check /> {$tC({ de: 'Fertig', en: 'Done' })}</BrandButton>
</ModalDialog>
