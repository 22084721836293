<script>
  import { tC } from '../stores/i18n'
  import BrandButton from './BrandButton.svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Field, Input, Toast } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import session from '../stores/session'
  import { getProcessNumber } from '../../shared/lib/misc'
  import table from '../stores/table'
  import { second } from '../stores/timer'
  import { formatMinutesSeconds } from '../lib/utils'
  import bundle from '../stores/bundle'

  const dispatch = createEventDispatcher()

  let code = ''
  let formEl

  const originalSessionId = $session.id

  $: if (originalSessionId !== $session.id) dispatch('close')

  let wrongCode = false
  let wrongCodeInterval = null

  function codeWasWrong () {
    wrongCode = true
    if (wrongCodeInterval) clearTimeout(wrongCodeInterval)
    setTimeout(() => {
      wrongCode = false
      wrongCodeInterval = null
    }, 500)
  }

  $: if (code.length >= 3) {
    if (code === getProcessNumber($session.id)) {
      dispatch('close', true)
    } else {
      Toast.create({ message: $tC({ de: 'Der Code war leider falsch!', en: 'The code was incorrect!' }), type: 'is-danger', duration: 6000 })
      codeWasWrong()
      code = ''
    }
  }

  let closeInterval = null
  $: if (code !== null) { // It is never null, but I use this to bind the code into the condition
    if (closeInterval) clearTimeout(closeInterval)
    closeInterval = setTimeout(() => dispatch('close'), 30000)
  }
</script>

<style lang="scss">
  form {
    font-size: 1.5rem;

    :global(.control) {
      font-size: 2rem;
      width: 100%;
      position: relative;

      :global(input) {
        font-size: 1em;
        letter-spacing: 1em;
        font-weight: bold;
        text-align: center;

        &::placeholder {
          margin-right: -1em; // Compensate for imbalanced letter spacing
        }
      }
    }
  }
</style>

<ModalDialog --width="40rem" --min-width="40rem" class="dialog-top-half" cardClass={wrongCode ? 'shake' : ''} title={$tC({ de: 'SMS-Code eingeben', en: 'Enter SMS Code' })} closeable on:close on:introend={formEl.querySelector('input').focus()}>
  <form bind:this={formEl} class="mb-4">
    <h4 class="title is-4">{$tC({ de: `Bitte geben Sie den Code ein, den Sie in unserer letzten SMS auf Ihrem Handy mit Nr. **${$session.userData.phoneNumber.slice(-3)} erhalten haben!`, en: 'Please enter the code you received in our last text message on your phone with no. **' + $session.userData.phoneNumber.slice(-3) + '!' })}</h4>

    <Field>
      <Input expanded type="text" class={wrongCode ? 'shake' : ''} pattern="[0-9]*" maxlength={3} inputmode="numeric" bind:value={code} step="any" placeholder="•••" />
    </Field>
  </form>

  {#if $table.signalledSince}
    <p style:margin-top="-0.9em">{$tC({ de: 'Ihre Reservierung läuft ab in {t}', en: 'Your reservation will expire in {t}' }, { t: $second && formatMinutesSeconds(Math.max(0, (new Date($table.signalledSince).valueOf() + $bundle.settings.tableReservedDuration * 60000) - Date.now())) })}</p>
  {/if}

  <div class="has-text-right" style:clear="both">
    <BrandButton on:click={() => dispatch('close')}>{$tC({ de: 'Abbrechen', en: 'Cancel' })}</BrandButton>
  </div>
</ModalDialog>
