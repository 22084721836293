<script>
  import { tC } from '../stores/i18n'
  import BrandButton from './BrandButton.svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Field, Input } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import Check from 'svelte-feather/components/Check.svelte'

  const dispatch = createEventDispatcher()

  export let tip
  let formEl

  function submit () {
    dispatch('close', tip || 0)
  }
</script>

<style lang="scss">
  form {
    :global(.control) {
      font-size: 2rem;
      width: 100%;
      position: relative;

      :global(input) {
        text-align: center;
        font-size: 1em;
      }

      &::after {
        content: '€';
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: #cccccc;
      }
    }
  }
</style>

<ModalDialog --width="18rem" --min-width="18rem" class="dialog-top-half" title={$tC({ de: 'Trinkgeld eingeben', en: 'Enter Tip' })} closeable on:close on:introend={formEl.querySelector('input').focus()}>
  <form on:submit|preventDefault={submit} bind:this={formEl}>
    <Field>
      <Input expanded type="number" bind:value={tip} step="any" min="0" placeholder="0.00" />
    </Field>

    <BrandButton type="submit" class="is-fullwidth is-primary" disabled={tip < 0}><Check /> {$tC({ de: 'OK', en: 'OK' })}</BrandButton>
  </form>
</ModalDialog>
