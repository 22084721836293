<script>
  import { batteryStatus as kioskBatteryStatus, deviceId as kioskDeviceId } from '../stores/yaoKiosk'
  import BatteryStatus from './BatteryStatus.svelte'

  export let batteryStatus
  export let deviceId = kioskDeviceId

  $: usedBatteryStatus = batteryStatus ?? $kioskBatteryStatus
</script>
<style lang="scss">
  .battery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    span {
      font-size: 0.75em;
      line-height: 1;
    }
  }
</style>

<div class="battery-container {$$props.class ?? ''}">
  {#if usedBatteryStatus}
    <BatteryStatus level={usedBatteryStatus?.level} charging={usedBatteryStatus?.isPlugged} --size="1.5em" />
  {/if}
  <span>{deviceId ?? 'N/A'}</span>
</div>
