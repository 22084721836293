// Allows to debounce actions such as search-on-type, things that react on mousemove, etc.
// It will trigger the action only after a defined amount of calmness.
// There is also a throttle mode which will trigger immedately but will delay any further updates
// until a given amount of time and then only execute the last one triggered. In combination
// with the discardDuringThrottle option, it will discard any new actions triggered during the
// throttle period.
class Debouncer {
  // Delay: Amount of time in ms of calmness required before execution
  // Throttle mode: See above
  constructor (delay = 400, throttleMode = false, discardDuringThrottle = false) {
    this.delay = delay
    this.throttleMode = throttleMode
    this.discardDuringThrottle = discardDuringThrottle
    this.reset()
  }

  // Executes the scheduled action, if any, and resets the debouncer
  execute () {
    const onExecute = this.onExecute
    this.reset()
    if (onExecute) onExecute()
  }

  // Discards the scheduled action, if any, and resets the debouncer
  discard () {
    const onDiscard = this.onDiscard
    this.reset()
    if (onDiscard) onDiscard()
  }

  // Resets the debouncer without executing any callback function
  reset () {
    if (this.timeoutId) clearTimeout(this.timeoutId)
    this.timeoutId = null
    this.onExecute = null
    this.onDiscard = null
  }

  // Scheduled debounced execution of a callback
  // (with optional callback for the event the previous action was discarded)
  //
  // Note: It is assumed that the same kind of action is triggered in each instance of the
  // callback function passed. Previously passed callback functions are discarded, always
  // the last one is eventually executed.
  debounce (onExecute = null, onDiscard = null) {
    if (this.throttleMode && !this.timeoutId) {
      if (onExecute) onExecute()

      onExecute = null
      onDiscard = null
    }

    if (this.throttleMode && this.timeoutId) {
      if (this.discardDuringThrottle) {
        if (onDiscard) onDiscard()
        return
      }

      if (this.onDiscard) this.onDiscard()

      this.onExecute = onExecute
      this.onDiscard = onDiscard
    } else {
      this.discard()

      this.onExecute = onExecute
      this.onDiscard = onDiscard
      this.timeoutId = setTimeout(() => this.execute(), this.delay)
    }
  }
}

export default Debouncer
