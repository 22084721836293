<script>
  import { scale } from 'svelte/transition'
  import { tE } from '../stores/i18n'
  import { appendToBody } from '../lib/utils'

  export let tables = []
</script>

<style lang="scss">
  main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background: $danger;
    color: white;
    font-size: 3rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    z-index: 902;

    div {
      font-size: 4rem;
      padding: 4rem;

      i {
        font-size: 6rem;

        animation: blink 0.5s infinite;
      }
    }
  }

  @keyframes blink {
    0% { opacity: 1; }
    49% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
  }
</style>

<main transition:scale use:appendToBody>
  <div>
    <i class="fa fa-exclamation-triangle"></i>
  </div>

  <div>
    {#if tables.length === 1}
      {$tE({ de: 'Tisch {t} ist in der Kassa blockiert, bitte sofort freigeben!', en: 'Table {t} is blocked in the PoS, please release it immediately!' }, { t: tables[0] })}
    {:else}
      {$tE({ de: 'Tische {t} sind in der Kassa blockiert, bitte sofort freigeben!', en: 'Tables {t} are blocked in the PoS, please release them immediately!' }, { t: tables.join(', ') })}
    {/if}
  </div>

  <audio src="/misc/alarm.mp3" autoplay loop></audio>
</main>
