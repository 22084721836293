<script>
  import { fade, fly } from 'svelte/transition'
  import { getMediaUrl, isVideo } from '../lib/utils'
  import BrandButton from './BrandButton.svelte'
  import { tC } from '../stores/i18n'
  import ArrowLeft from 'svelte-feather/components/ArrowLeft.svelte'

  export let slides = []
  export let withButton = false

  let currentSlideIndex = 0

  let timeout = null

  let currentSlide
  $: {
    if (currentSlideIndex >= slides.length) {
      currentSlideIndex = 0
    }

    currentSlide = slides[currentSlideIndex]

    if (currentSlide && !timeout) {
      timeout = setTimeout(() => {
        currentSlideIndex += 1
        timeout = null
      }, (currentSlide.duration || 5) * 1000)
    }
  }
</script>

<style lang="scss">
  main {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 80px;

    .slide {
      grid-area: 1 / 1 / 3 / 2;

      width: 100%;
      height: 100%;
      min-width: 0;
      min-height: 0;

      img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.withButton .slide {
      grid-area: 1 / 1 / 2 / 2;
    }

    .button-area {
      grid-area: 2 / 1 / 3 / 2;
      display: flex;
      justify-content: center;
      align-items: center;

      :global(button) {
        font-size: 40px;
        width: 400px;
      }
    }
  }
</style>

<main class:withButton transition:fade on:click>
  {#key currentSlide?.image?.id}
    <div class="slide" in:fly={{ x: -50 }} out:fly={{ x: 50 }}>
      {#if currentSlide}
        {#if isVideo(currentSlide.image?.filename)}
          <video autoplay loop muted>
            <source src={getMediaUrl(currentSlide.image, 'large')} type="video/mp4" />
          </video>
        {:else}
          <img src={getMediaUrl(currentSlide.image, 'large')} alt="Slide" />
        {/if}
      {/if}
    </div>
  {/key}

  {#if withButton}
    <div class="button-area">
      <BrandButton class="is-primary" on:click>
        <ArrowLeft /> {$tC({ de: 'Zurück', en: 'Back' })}
      </BrandButton>
    </div>
  {/if}
</main>
