<script>
  import { tC } from '../stores/i18n'
  import ModalDialog from './ModalDialog.svelte'
  import BrandButton from './BrandButton.svelte'
  import { userData } from '../stores/session'
  import { createEventDispatcher } from 'svelte'
  import { diets } from '../../payload/i18nConstants'
  import DietIndicator from './DietIndicator.svelte'

  const dispatch = createEventDispatcher()

  function select (value) {
    $userData.dietFilter = value
    dispatch('close')
  }
</script>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
</style>

<ModalDialog title={$tC({ de: 'Ernährungsform', en: 'Diet' })} closeable on:close>
  <main>
    {#each Object.values(diets) as { value, label }}
      <BrandButton class="is-fullwidth" toggle selected={$userData.dietFilter === value} on:click={() => select(value)}>
        <DietIndicator diet={value} showOmnivoreIcon /> {$tC(label)}
      </BrandButton>
    {/each}
  </main>
</ModalDialog>
