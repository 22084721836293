<script>
  import { fly } from 'svelte/transition'
  import { formatCurrency, getMediaUrl, rippleOnTouch } from '../lib/utils'
  import { tC } from '../stores/i18n'
  import { createEventDispatcher } from 'svelte'
  import ChevronLeft from 'svelte-feather/components/ChevronLeft.svelte'
  import ChevronRight from 'svelte-feather/components/ChevronRight.svelte'

  const dispatch = createEventDispatcher()

  export let articles

  let canGoBack = false
  let canGoForward = false

  let sliderEl

  const gap = 15

  function updateButtons () {
    canGoBack = sliderEl.scrollLeft > gap
    canGoForward = sliderEl.scrollLeft < sliderEl.scrollWidth - sliderEl.offsetWidth - gap
  }

  function goBack () {
    sliderEl.scrollTo({ left: sliderEl.scrollLeft - sliderEl.querySelector('article')?.offsetWidth + gap, behavior: 'smooth' })
  }

  function goForward () {
    sliderEl.scrollTo({ left: sliderEl.scrollLeft + sliderEl.querySelector('article')?.offsetWidth + gap, behavior: 'smooth' })
  }

  function updateSliderWidth (element) {
    sliderEl = element

    const update = () => {
      element.style.setProperty('--slider-width', `${element.offsetWidth}px`)
      updateButtons()
    }

    update()

    const observer = new ResizeObserver(update)
    observer.observe(element)

    updateButtons(element)

    return {
      destroy: () => observer.disconnect()
    }
  }

  function onScroll () {
    updateButtons()

    sliderEl.querySelectorAll('.ripple-container').forEach(container => container.remove())
  }

  function onScrollEnd () {
    // For some reason the scroll snap is not honoered correctly for the very end
    const maxScroll = sliderEl.scrollWidth - sliderEl.offsetWidth - gap
    if (sliderEl.scrollLeft > maxScroll) {
      sliderEl.scrollTo({ left: maxScroll, behavior: 'smooth' })
    }
  }
</script>

<style lang="scss">
  main {
    margin-top: $default-margin;
  }

  .recommendations-label {
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 16px;
  }

  $gap: 15px;
  $height: 95px;
  $number-of-articles: 4;

  .slider-container {
    width: 100%;
    position: relative;
  }

  .slider {
    --slider-width: 0;
    width: 100%;
    height: $height;
    overflow-x: auto;
    overflow-y: hidden;

    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }

  .slider-content {
    display: flex;
    min-width: calc(100% + 2 * $gap);
    height: 100%;
    overscroll-behavior-x: none;
  }

  article {
    flex: 0 0 calc((var(--slider-width, 0) - ($number-of-articles - 1) * $gap) / $number-of-articles);
    min-width: 0;
    height: $height;
    margin-right: $gap;
    cursor: pointer;

    &:first-child {
      margin-left: $gap;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    scroll-snap-align: start;

    font-size: 0.75rem;
  }

  .small-product-image {
    width: 100%;
    height: 50px;
    margin-top: 7px;
    margin-bottom: 7px;
    background: $contrast-bg;
    border-radius: 6px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: scale(1.25);
    }

    :global(.ripple-container) {
      border-radius: 6px;
    }
  }

  p {
    margin: none;
    width: 100;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
  }

  .back-button, .forward-button {
    position: absolute;
    top: 20px;
    cursor: pointer;
    color: $grey;
    opacity: 0.8;
    z-index: 2;

    :global(svg) {
      width: 24px;
      height: 24px;

      stroke-width: 1px;
      transform: scale(1.5);
    }
  }

  .back-button {
    left: -24px;
  }

  .forward-button {
    right: -24px;
  }
</style>

{#if articles.length}
  <main class="recommendations">
    <div class="recommendations-label">{$tC({ de: 'Empfehlungen:', en: 'Recommendations:' })}</div>

    <div class="slider-container">
      {#if canGoBack}
        <div class="back-button" transition:fly={{ x: -10 }} on:click={goBack}><ChevronLeft /></div>
      {/if}
      {#if canGoForward}
        <div class="forward-button" transition:fly={{ x: 10 }} on:click={goForward}><ChevronRight /></div>
      {/if}

      <div class="slider" use:updateSliderWidth on:scrollend={onScrollEnd} on:scroll={onScroll}>
        <div class="slider-content">
          {#each articles as article}
            <article on:click={() => dispatch('itemClick', article)}>
              <div class="small-product-image" use:rippleOnTouch>
                <img src={getMediaUrl(article.image)} alt={$tC(article.name)} />
              </div>
              <p>{$tC(article.name)}</p>
              <p>{article.minPrice !== article.maxPriceFromRequiredGroups ? $tC({ de: 'ab ', en: 'from ' }) : ''}{$tC && formatCurrency(article.minPrice)}</p>
            </article>
          {/each}
        </div>
      </div>
    </div>
  </main>
{/if}
