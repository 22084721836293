import { derived, get, writable } from 'svelte/store'
import { propertyStore } from 'svelte-writable-derived'
import table, { currentTable, isTestTableMode, updateTable } from './table'
import bundle from './bundle'
import { generateRandomString, noopScroll, reloadPage, round2, withProcessingOverlay } from '../lib/utils'
import { apiCall, sendWebsocketMessage, wsConnected } from '../lib/api'
import { deviceId } from './yaoKiosk'
import Debouncer from '../lib/Debouncer'
import { executeWhenNotCritical } from './critical'
import { customerLanguage, translate } from './i18n'
import { Toast } from 'svelma-fixed'
import { getEmptySession } from '../../shared/lib/misc'

export const session = writable(null)
export default session

export let currentSession
session.subscribe($session => {
  if (!window.$yo) window.$yo = {}
  window.$yo.session = $session

  currentSession = $session
})

const resendDebouncer = new Debouncer(3000, true, true)
let stopSavingSession = false
window.addEventListener('backendWsMessage', event => {
  if (event.detail.type === 'sessionDate') {
    const oldTs = new Date(currentSession?.updatedAt ?? 0).valueOf()
    const newTs = new Date(event.detail.sessionDate ?? 0).valueOf()
    if (event.detail.lastChangedByDeviceId !== deviceId && get(table)?.status !== 'cleaning') { // Do not reload while table is cleaning, to allow immediately using the robot button
      // Not our change... we need to reload
      stopSavingSession = true
      console.log(`Session changed by other device ${event.detail.lastChangedByDeviceId} - reloading`)
      executeWhenNotCritical('reload', () => reloadPage())
    } else if (newTs < oldTs) {
      // Server didn't get last update...? Send it again!
      resendDebouncer.debounce(() => saveSession())
    } else if (newTs > oldTs) {
      // Just update the timestamp
      currentSession.updatedAt = new Date(event.detail.sessionDate).toISOString() // This does not trigger store subscriptions!
    }
  } else if (event.detail.type === 'ageVerificationUpdated') {
    if (event.detail.sessionId === currentSession?.id) {
      console.log('Age verified:', event.detail.ageVerified)
      if (!currentSession.userData.ageVerified && event.detail.ageVerified) Toast.create({ message: translate({ de: 'Ihr Alter wurde verifiziert!', en: 'Your age has been verified!' }), type: 'is-success', duration: 6000 })
      session.update($session => {
        $session.userData.ageVerified = event.detail.ageVerified
        return $session
      })
    }
  }
})

export async function updateSession () {
  if (get(isTestTableMode)) {
    resetSession()
    return
  }

  const newSession = await apiCall('GET', '/api/app/currentSession')
  console.log('Session', newSession)

  if (!newSession.id) {
    resetSession()
  } else {
    session.set(newSession)
    if (newSession.userData?.language) customerLanguage.set(newSession.userData?.language)
    if (currentTable?.type === 'kiosk' && newSession.status === 'queued') { // This should never happen
      console.error('Session is queued, but we are in kiosk mode. Closing session...')
      newCustomer()
    }
  }
}

export function resetSession () {
  const language = window.appVariables.configParams.localization.defaultLocale

  session.set(getEmptySession(language, get(table)?.id, deviceId))

  customerLanguage.set(language)
}

resetSession()

if (!window.$yo) window.$yo = {}
window.$yo.sessionStore = session
window.$yo.resetSession = resetSession

export const userData = propertyStore(session, 'userData')
export const uiState = propertyStore(session, 'uiState')

userData.subscribe($userData => {
  if (document.documentElement.getAttribute('data-font-size') === String($userData?.fontSize ?? 3)) return

  document.documentElement.setAttribute('data-font-size', $userData?.fontSize ?? 3)

  // Fix any scroll boxes
  setTimeout(() => {
    for (const element of document.querySelectorAll('.overscroll, .snappy, .rescroll')) {
      noopScroll(element)
    }
  }, 250)
})

export const currentView = propertyStore(uiState, 'currentView')
export const selectedCategoryId = propertyStore(uiState, 'selectedCategoryId')
export const selectedArticleId = propertyStore(uiState, 'selectedArticleId')
export const articleConfigurations = propertyStore(uiState, 'articleConfigurations')

export const welcomeSubView = propertyStore(uiState, 'welcomeSubView')
export const paymentState = propertyStore(uiState, 'paymentState')

export const selectedArticle = derived([selectedArticleId, bundle], ([$selectedArticleId, $bundle]) => $bundle?.articles[$selectedArticleId] ?? $bundle?.pages[$selectedArticleId])

export const cart = propertyStore(session, 'cart')
export const wishlist = propertyStore(session, 'wishlist')

export function getCartTotal ($cart, $bundle = get(bundle)) {
  return round2($cart.reduce((acc, item) => acc + (($bundle.articles[item.article]?.posArticle?.price ?? 0) + item.subArticles.reduce((acc, subArticleInfo) => acc + ($bundle.articles[subArticleInfo.article]?.posArticle?.price ?? 0) * subArticleInfo.quantity, 0)) * item.quantity, 0))
}

export const cartTotal = derived([cart, bundle], ([$cart, $bundle]) => getCartTotal($cart, $bundle))

export function saveArticleConfiguration (articleConfiguration) {
  articleConfigurations.update($articleConfigurations => {
    const index = $articleConfigurations.findIndex(a => a.article === articleConfiguration.article)
    if (index === -1) {
      $articleConfigurations.push(articleConfiguration)
    } else {
      $articleConfigurations[index] = articleConfiguration
    }
    return $articleConfigurations
  })
}

export const history = derived(uiState, $uiState => {
  const historyHandler = {
    history: $uiState.history,
    historyIndex: $uiState.historyIndex,
    current: $uiState.history[$uiState.historyIndex],
    canGoBack: $uiState.historyIndex > 0,
    canGoForward: $uiState.historyIndex < $uiState.history.length - 1,
    push: (articleId) => {
      uiState.update($uiState => {
        $uiState.history = $uiState.history.slice(0, $uiState.historyIndex + 1)
        $uiState.history.push(articleId)
        $uiState.historyIndex++
        return $uiState
      })
    },
    goBack: () => {
      uiState.update($uiState => {
        if (!historyHandler.canGoBack) return $uiState
        $uiState.historyIndex--
        $uiState.selectedArticleId = $uiState.history[$uiState.historyIndex]
        window.dispatchEvent(new CustomEvent('historyNavigation'))
        return $uiState
      })
    },
    goForward: () => {
      uiState.update($uiState => {
        if (!historyHandler.canGoForward) return $uiState
        $uiState.historyIndex++
        $uiState.selectedArticleId = $uiState.history[$uiState.historyIndex]
        window.dispatchEvent(new CustomEvent('historyNavigation'))
        return $uiState
      })
    }
  }

  return historyHandler
})

selectedArticleId.subscribe($selectedArticleId => {
  setTimeout(() => { // Without the timeout, this blocks the updates otherwise!
    const $history = get(history)
    if (get(selectedArticleId) !== $history.current) {
      $history.push($selectedArticleId)
    }
  }, 10)
})

const dietOrder = { na: 0, vegan: 1, vegetarian: 2, omnivore: 3 }
export const isMatchingFilterFn = ($userData, article, seen = new Set()) => {
  if (seen.has(article)) return false // Prevent infinite loops
  seen.add(article)

  if ($userData?.allergenFilter?.length && article.allergens?.some(allergen => $userData.allergenFilter.includes(allergen))) return false
  const dietFilterIndex = dietOrder[$userData?.dietFilter ?? 'omnivore']
  const articleDietIndex = dietOrder[article.diet ?? 'na']
  if (dietFilterIndex < articleDietIndex) return false

  return !article.mergedGroups?.some(group => group.effectiveSelectionMode === 'single' && group.articleGroup.articles.every(article => !isMatchingFilterFn($userData, article, seen)))
}
export const isMatchingFilter = derived(userData, $userData => article => isMatchingFilterFn($userData, article))

export function newOrder () {
  if (get(table)?.type === 'kiosk') {
    return newCustomer()
  }

  session.update($session => {
    if ($session.uiState.currentView === 'payment') $session.uiState.currentView = 'menu'
    $session.cart = []
    return $session
  })
}

let scheduledSessionSaveTimeout = null
export async function saveSession (useWebsocket = true) {
  if (get(isTestTableMode)) return

  const $session = get(session)
  if (!$session.id) {
    $session.id = generateRandomString(6)
  }
  if (!$session.startTime) {
    $session.startTime = new Date().toISOString()
  }

  $session.updatedAt = new Date().toISOString()
  if (!$session.lastActivityTime) $session.lastActivityTime = new Date().toISOString()

  if (stopSavingSession) {
    console.warn('Not saving session due to pending reload')
    return
  }

  table.update($table => Object.assign($table, { currentSession: $session.id, status: $table.status === 'free' ? 'occupied' : $table.status }))

  if (useWebsocket) {
    if (!scheduledSessionSaveTimeout) {
      // Aggregate all the changes and execute save only once, with the latest result
      scheduledSessionSaveTimeout = setTimeout(() => {
        scheduledSessionSaveTimeout = null

        if (wsConnected) {
          sendWebsocketMessage({ type: 'saveSession', session: get(session) })
        } else {
          apiCall('POST', '/api/app/saveSession', { session: $session })
        }
      }, 0)
    }
  } else {
    await apiCall('POST', '/api/app/saveSession', { session: $session })
  }
}

const debouncer = new Debouncer(10000, true)
export function reportSessionActivity () {
  lastActivityTimestamp.set(Date.now())

  try {
    debouncer.debounce(() => {
      const $session = get(session)
      if (!$session.id) return

      if (wsConnected) {
        sendWebsocketMessage({ type: 'sessionActivity', id: $session.id })
      } else {
        apiCall('POST', '/api/app/sessionActivity', { id: $session.id })
      }
    })
  } catch (e) {}
}

export async function closeSession (cleaning) {
  const $session = get(session)
  if (!$session.id) {
    resetSession()
  }

  table.update($table => Object.assign($table, { currentSession: null }))

  if (cleaning) table.update($table => Object.assign($table, { status: 'cleaning' })) // Avoid reloading at this point!

  try {
    if ($session.id) {
      await apiCall('POST', '/api/app/closeSession', { id: $session.id, cleaning })
    } else {
      await apiCall('POST', '/api/app/startCleaning', { tableId: $session.table })
    }
  } catch (e) {
    await updateTable().catch(() => {})
    throw e
  }
}

let autoSaveSessionDisabled = false
export function doNotSaveSessionThisTick () {
  autoSaveSessionDisabled = true
  setTimeout(() => {
    autoSaveSessionDisabled = false
  }, 0)
}

// This is used instead of the actual property of the session because we want to avoid invalidating the session store on every touch
export const lastActivityTimestamp = writable(Date.now())
export let lastActivityTimestampValue
lastActivityTimestamp.subscribe($lastActivityTimestamp => {
  lastActivityTimestampValue = $lastActivityTimestamp
})

session.subscribe($session => {
  if (autoSaveSessionDisabled) return

  const sessionLastActivityTimestamp = new Date($session.lastActivityTime ?? 0).valueOf()
  if (sessionLastActivityTimestamp > lastActivityTimestampValue) lastActivityTimestamp.set(sessionLastActivityTimestamp)

  if ($session.uiState.currentView !== 'welcome') saveSession()
})

uiState.subscribe(() => reportSessionActivity())

export async function newCustomer () {
  await withProcessingOverlay(async () => {
    try {
      await closeSession(false)
    } catch (e) {
      console.error('Cannot close session', e)
    }

    reloadPage()
  })
}

export async function updateAgeVerificationRequest (verificationRequired) {
  if (currentSession?.id) await apiCall('POST', '/api/app/updateAgeVerificationRequest', { sessionId: currentSession?.id, verificationRequired })
}
