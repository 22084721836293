import { writable } from 'svelte/store'

// Create a store that "ticks" every full second (if there are any listeners)
// Also one that "ticks" every full minute

export const second = writable(Date.now())
export const minute = writable(Date.now())

let lastSecond = new Date().getSeconds()
let lastMinute = new Date().getMinutes()

setTimeout(() => {
  setInterval(() => {
    const now = Date.now()

    const currentSecond = new Date().getSeconds()
    if (currentSecond !== lastSecond) {
      second.set(now)
      lastSecond = currentSecond
    }

    const currentMinute = new Date().getMinutes()
    if (currentMinute !== lastMinute) {
      minute.set(now)
      lastMinute = currentMinute
    }
  }, 1000)
}, 1000 - new Date().getMilliseconds())
