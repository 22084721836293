export const groups = {
  administration: {
    de: 'Administration',
    en: 'Administration'
  },

  posResources: {
    de: 'PoS-Ressourcen',
    en: 'PoS Resources'
  },

  resources: {
    de: 'Ressourcen',
    en: 'Resources'
  }
}

export const signalColors = [
  {
    value: 'purple',
    color: [103, 9, 255],
    label: {
      de: 'Lila',
      en: 'Purple'
    }
  },
  {
    value: 'cyan',
    color: [6, 255, 247],
    label: {
      de: 'Türkis',
      en: 'Cyan'
    }
  },
  {
    value: 'yellow',
    color: [255, 255, 0],
    label: {
      de: 'Gelb',
      en: 'Yellow'
    }
  },
  {
    value: 'blue',
    color: [0, 0, 255],
    label: {
      de: 'Blau',
      en: 'Blue'
    }
  },
  {
    value: 'pink',
    color: [255, 12, 235],
    label: {
      de: 'Pink',
      en: 'Magenta'
    }
  },
  {
    value: 'orange',
    color: [255, 130, 12],
    label: {
      de: 'Orange',
      en: 'Orange'
    }
  }
]

export const languages = {
  de: {
    de: 'Deutsch',
    en: 'German',
    _flag: '/images/flags/at.svg'
  },
  en: {
    de: 'Englisch',
    en: 'English',
    _flag: '/images/flags/gb.svg'
  }
}

export const allergens = [
  {
    value: 'A',
    label: {
      de: 'Gluten\u00a0(A)',
      en: 'Gluten\u00a0(A)'
    }
  },
  {
    value: 'B',
    label: {
      de: 'Krebstiere\u00a0(B)',
      en: 'Crustaceans\u00a0(B)'
    }
  },
  {
    value: 'C',
    label: {
      de: 'Eier\u00a0(C)',
      en: 'Eggs\u00a0(C)'
    }
  },
  {
    value: 'D',
    label: {
      de: 'Fisch\u00a0(D)',
      en: 'Fish\u00a0(D)'
    }
  },
  {
    value: 'E',
    label: {
      de: 'Erdnüsse\u00a0(E)',
      en: 'Peanuts\u00a0(E)'
    }
  },
  {
    value: 'F',
    label: {
      de: 'Soja\u00a0(F)',
      en: 'Soy\u00a0(F)'
    }
  },
  {
    value: 'G',
    label: {
      de: 'Milch\u00a0(G)',
      en: 'Milk\u00a0(G)'
    }
  },
  {
    value: 'H',
    label: {
      de: 'Schalenfrüchte\u00a0(H)',
      en: 'Nuts\u00a0(H)'
    }
  },
  {
    value: 'K',
    label: {
      de: 'Sellerie\u00a0(K)',
      en: 'Celery\u00a0(K)'
    }
  },
  {
    value: 'M',
    label: {
      de: 'Senf\u00a0(M)',
      en: 'Mustard\u00a0(M)'
    }
  },
  {
    value: 'N',
    label: {
      de: 'Sesamsamen\u00a0(N)',
      en: 'Sesame Seeds\u00a0(N)'
    }
  },
  {
    value: 'O',
    label: {
      de: 'Sulfite\u00a0(O)',
      en: 'Sulphites\u00a0(O)'
    }
  },
  {
    value: 'P',
    label: {
      de: 'Lupinen\u00a0(P)',
      en: 'Lupin\u00a0(P)'
    }
  },
  {
    value: 'R',
    label: {
      de: 'Weichtiere\u00a0(R)',
      en: 'Molluscs\u00a0(R)'
    }
  }
]

export const diets = [
  {
    value: 'omnivore',
    label: {
      de: 'Omnivor (Alles-Esser)',
      en: 'Omnivore'
    }
  },
  {
    value: 'vegetarian',
    label: {
      de: 'Vegetarisch',
      en: 'Vegetarian'
    }
  },
  {
    value: 'vegan',
    label: {
      de: 'Vegan',
      en: 'Vegan'
    }
  }
]

export const copy = {
  de: 'Kopie',
  en: 'Copy'
}

export const noneOption = {
  value: 'none',
  label: {
    de: 'Keine',
    en: 'None'
  }
}

export const tableStatuses = [
  {
    value: 'free',
    label: {
      de: 'Frei',
      en: 'Free'
    }
  },
  {
    value: 'occupied',
    label: {
      de: 'Besetzt',
      en: 'Occupied'
    }
  },
  {
    value: 'cleaning',
    label: {
      de: 'Reinigung',
      en: 'Cleaning'
    }
  },
  {
    value: 'disabled',
    label: {
      de: 'Deaktiviert',
      en: 'Disabled'
    }
  },
  {
    value: 'blocked',
    label: {
      de: 'Blockiert',
      en: 'Blocked'
    }
  },
  {
    value: 'reserved',
    label: {
      de: 'Reserviert',
      en: 'Reserved'
    }
  }
]

export function getOptionLabel (options, value) {
  const option = options.find(o => o.value === value)
  return option ? option.label : value
}
