<script>
  import { table, isTestTableMode, isOff, isEvent } from '../stores/table'
  import { bundle } from '../stores/bundle'
  import session, { currentView, lastActivityTimestamp, newCustomer, reportSessionActivity, saveSession, userData, welcomeSubView } from '../stores/session'
  import { languages } from '../../payload/i18nConstants'
  import { tC, customerLanguage } from '../stores/i18n'
  import MainOrderUi from '../components/MainOrderUi.svelte'
  import PaymentUi from '../components/PaymentUi.svelte'
  import { fly, scale } from 'svelte/transition'
  import { Motion } from 'svelte-motion'
  import { formatMinutesSeconds, reloadPage, rippleOnTouch, sendToServerLog } from '../lib/utils'
  import { isEmployeeMenuOpen, recordEmployeeActivity } from '../lib/employeeMenu'
  import TestTableModeOverlay from '../components/TestTableModeOverlay.svelte'
  import { onDestroy } from 'svelte'
  import dialogs from '../stores/dialogs'
  import AreYouStillThereDialog from '../components/AreYouStillThereDialog.svelte'
  import { second } from '../stores/timer'
  import { batteryStatus, brightness } from '../stores/yaoKiosk'
  import ModalDialog from '../components/ModalDialog.svelte'
  import BatteryStatusAndDeviceId from '../components/BatteryStatusAndDeviceId.svelte'
  import Slideshow from '../components/Slideshow.svelte'
  import critical from '../stores/critical'
  import BrandButton from '../components/BrandButton.svelte'
  import GoToTable from '../components/icons/GoToTable.svelte'
  import NoTable from '../components/icons/NoTable.svelte'
  import Bell from 'svelte-feather/components/Bell.svelte'
  import Info from 'svelte-feather/components/Info.svelte'
  import AlertCircle from 'svelte-feather/components/AlertCircle.svelte'
  import AlertTriangle from 'svelte-feather/components/AlertTriangle.svelte'
  import Check from 'svelte-feather/components/Check.svelte'
  import X from 'svelte-feather/components/X.svelte'
  import { createLoadingStore } from '../stores/loading'
  import PhoneNumberDialog from '../components/PhoneNumberDialog.svelte'
  import { apiCall } from '../lib/api'
  import MessageDialog from '../components/MessageDialog.svelte'
  import QueueSuccessDialog from '../components/QueueSuccessDialog.svelte'
  import CustomerLanguageSelector from '../components/CustomerLanguageSelector.svelte'
  import VerifySmsCodeDialog from '../components/VerifySmsCodeDialog.svelte'

  const queueRegistering = createLoadingStore()

  $: if ($userData.language && $userData.language !== $customerLanguage) $userData.language = $customerLanguage

  let animCounter = 0

  let showIdleSlideshow = false

  const interval = setInterval(async () => {
    showIdleSlideshow = false

    if ($table?.type === 'kiosk' && ($currentView !== 'welcome' || $session?.id) && $lastActivityTimestamp < Date.now() - 45000 && !$dialogs.some(d => d.Component === AreYouStillThereDialog) && !$critical) {
      if (await dialogs.open(AreYouStillThereDialog) === false) {
        newCustomer()
      } else {
        reportSessionActivity()
      }
    }

    if ($table?.type === 'kiosk' && ($currentView !== 'welcome' || $session?.id) && $lastActivityTimestamp < Date.now() - 90000 && !$critical) {
      newCustomer()
    }

    if ($table && !$isOff && !$isEvent && $table.type !== 'kiosk' && $currentView === 'menu' && $batteryStatus?.isPlugged && $lastActivityTimestamp < Date.now() - 30000 && !$critical) {
      showIdleSlideshow = true
    }
  }, 3000)

  onDestroy(() => {
    clearInterval(interval)
  })

  let prevIsPlugged = $batteryStatus?.isPlugged
  $: if ($batteryStatus && $batteryStatus.isPlugged !== prevIsPlugged) {
    reportSessionActivity()
    prevIsPlugged = $batteryStatus.isPlugged
  }

  $: {
    let newBrightness = 100
    if ($dialogs && !isEmployeeMenuOpen()) {
      if ($isOff && $table?.type !== 'kiosk') {
        newBrightness = 0
      } else if ($table?.type !== 'kiosk' && $batteryStatus) {
        if (!$batteryStatus.isPlugged) {
          if ($second && $lastActivityTimestamp < Date.now() - 60000) {
            newBrightness = 25
          } else if ($batteryStatus.level <= 3) {
            newBrightness = 10
          }
        }
      }
    }

    $brightness = newBrightness
  }

  $: if ($currentView === 'welcome' && !$welcomeSubView) {
    if ($table?.type === 'kiosk' && $table.kioskFeatures[0] === 'tableQueue') {
      $welcomeSubView = 'queue'
    } else {
      $welcomeSubView = 'main'
    }
  }

  function recordActivity () {
    reportSessionActivity()
    if (isEmployeeMenuOpen()) recordEmployeeActivity()
  }

  async function registerForQueue () {
    await queueRegistering(async () => {
      const data = await dialogs.open(PhoneNumberDialog, { forQueue: true })
      if (data) {
        try {
          await saveSession(false)
          await critical(async () => { // Critical to avoid reloading as soon as session is taken away from this table by the server
            const { ok, estimatedWaitingTime } = await apiCall('POST', '/api/app/registerForTableQueue', { sessionId: $session.id, userData: { ...$userData, ...data } })
            if (ok) {
              await dialogs.open(QueueSuccessDialog, { phoneNumber: data.phoneNumber, estimatedWaitingTime })
              reloadPage() // Not newCustomer because we don't want to call closeSession here
            } else {
              throw new Error('Non-OK response from server for table registration')
            }
          })
        } catch (e) {
          console.error('Queue error', e, data)
          if (e.code === 'already_queued') {
            await dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Sie sind bereits in der Warteschlange! Sie erhalten eine SMS, sobald ein Tisch für Sie frei wurde.', en: 'You are already in the table queue! You will receive an SMS as soon as a table is available for you.' }), iconClass: 'has-text-info', Icon: Info })
            newCustomer()
          } else {
            sendToServerLog('error', `Customer queue registration failed for ${data.phoneNumber ?? '???'}: ${e}`, 'queueError', 'queue', e, { data })
            await dialogs.open(MessageDialog, { title: $tC({ de: 'Fehler', en: 'Error' }), text: $tC({ de: 'Leider war es nicht möglich, Sie für die Warteschlange zu registrieren! Bitte überprüfen Sie die angegebene Telefonnummer. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an einen Mitarbeiter!', en: 'Unfortunately it was not possible to register you for the table queue! Please make sure the phone number you provided is valid. If the problem persists, please contact a member of staff!' }), iconClass: 'has-text-danger', Icon: AlertTriangle })
          }
        }
      }
    })
  }

  async function startQueuedSession () {
    if ($bundle.settings.requireSmsCode && $userData.phoneNumber) {
      if (!await dialogs.open(VerifySmsCodeDialog)) return
    }

    $session.status = 'active'
    $session.startTime = new Date().toISOString()
    $currentView = 'menu'
  }

  function showQueueMismatchError () {
    dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Dieser Tisch ist scheinbar für einen anderen Kunden reserviert! Bitte geben Sie den Tisch frei!', en: 'This table seems to be reserved for another customer! Please vacate the table!' }), iconClass: 'has-text-info', Icon: Info })
  }
</script>

<svelte:head>
  <title>YaoOrder</title>
</svelte:head>

<style lang="scss">
  main {
    background-color: #3d5e9b;
    color: white;
    width: 100%;
    height: 100%;
    display: grid;

    > * {
      width: 100%;
      height: 100%;
      min-height: 0;
      min-width: 0;
      grid-area: 1 / 1 / 2 / 2;
    }

    .splash {
      position: relative;
      background-color: #3d5e9b;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10vh;

      &.queue-screen {
        gap: 4vh;
      }

      .header {
        padding: 0 4rem;
        text-align: center;

        color: white;
        gap: 1vh;

        &:not(.queue-header) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        &.queue-header {
          display: flex;
          flex-direction: column;

          > :global(svg) {
            width: 60%;
            margin: auto;
            height: 12vh;
          }

          h2 {
            font-size: 3rem;

            :global(svg) {
              vertical-align: middle;
              transform: translateY(-0.1em);
            }
          }

          :global(.brand-button) {
            font-size: 3rem;
            height: auto;
            padding: 1.25rem;
          }
        }

        h1 {
          grid-area: 1 / 1 / span 1 / span 2;
          font-size: 6rem;
        }

        h2.de {
          grid-area: 2 / 1 / span 1 / span 1;
        }

        h2.en {
          grid-area: 2 / 2 / span 1 / span 1;
        }
      }

      .logo {
        width: 50vw;
        height: auto;
        max-height: 50vh;
        object-fit: contain;
      }

      &:has(.header) .logo {
        max-height: calc(50vh - 18rem);
      }

      div {
        display: flex;
        gap: 10vw;

        a {
          display: inline-block;
          position: relative;

          &:hover img {
            transition: transform 0.2s;
            transform: scale(1.25);
          }
        }

        img {
          width: 20vw;
          height: auto;
          max-height: 20vh;
          object-fit: contain;
        }
      }

      $bottom-button-area-height: 14rem;

      &:has(.welcome-bottom-button) {
        padding-bottom: $bottom-button-area-height;
      }

      .welcome-bottom-button {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $bottom-button-area-height;
        padding: 4rem;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;

        :global(.brand-button) {
          height: auto;
          font-size: 4rem;
        }
      }

      .queue-language-selector {
        :global(.brand-button) {
          height: auto;
          padding: 2rem;
          border-radius: 2rem;
        }
      }

      &.queue-check {
        padding: 4rem;
        text-align: center;

        .queue-buttons {
          display: flex;
          gap: 10vh;
          flex-direction: column;
          max-width: 60%;

          :global(.brand-button) {
            font-size: 2rem;
            height: auto;
            padding: 1rem 2rem;
          }
        }
      }

      :global(.shadow-ripples) {
        box-shadow: 0 0 rgba(255, 255, 255, 0.2), 0 0 0 16px rgba(255, 255, 255, 0.2),
    0 0 0 32px rgba(255, 255, 255, 0.2), 0 0 0 48px rgba(255, 255, 255, 0.2);
        animation: shadow-ripples 1s linear infinite;

        @keyframes shadow-ripples {
          to {
            box-shadow: 0 0 0 16px rgba(255, 255, 255, 0.2), 0 0 0 32px rgba(255, 255, 255, 0.2),
              0 0 0 48px rgba(255, 255, 255, 0.2), 0 0 0 64px rgba(255, 255, 255, 0);
          }
        }
      }
    }
  }
</style>

<svelte:body on:touchstart|passive={() => recordActivity()} on:click|passive={e => { if (!e.sourceCapabilities?.firesTouchEvents) recordActivity() }} on:keydown|passive={() => recordActivity()} />

<svelte:window on:employeeMenu={() => { reportSessionActivity(); showIdleSlideshow = false }} />

<main in:scale|global>
  {#if (!$table && !$isTestTableMode) || ($table && $table.status !== 'free' && $table.status !== 'occupied' && $table.status !== 'reserved') || $isOff || $isEvent}
    <div class="splash" in:fly={{ y: 50 }} out:fly={{ y: -50 }}>
      <img src="/images/logo.svg?_=2" alt="YaoOrder" class="logo" />
      {#if $isOff}
        {#if $table?.type !== 'kiosk'}
          <h2 class="title is-2 has-text-centered my-0">{$table ? $tC({ de: 'Tisch {n}', en: 'Table {n}' }, { n: $table.number }) : $tC({ de: 'Kein Tisch', en: 'No Table' })}</h2>
        {/if}
        {#if ($table?.type !== 'kiosk' || ($batteryStatus && $batteryStatus.level < 100 && !$batteryStatus.isPlugged))}
          <div class="has-text-centered" style:font-size="300%"><BatteryStatusAndDeviceId --border-color="white" --background-color="rgba(255, 255, 255, 0.15)" /></div>
        {/if}
      {:else if $isEvent}
        <!-- Nothing -->
      {:else if $table?.status === 'cleaning'}
        <div>
          <h2 class="title is-2 has-text-centered my-4">{$tC({ de: 'Tisch wird gereinigt', en: 'Table is being cleaned' })}</h2>
        </div>
      {:else}
        <div>
          <h2 class="title is-2 has-text-centered my-4">
            {#if $table?.type === 'kiosk'}
              {$tC({ de: 'Bitte an der Theke bestellen', en: 'Please order at the counter' })}
            {:else}
              {$tC({ de: 'Tisch nicht verfügbar', en: 'Table not available' })}
            {/if}
          </h2>
        </div>
      {/if}
    </div>
  {:else if $currentView === 'welcome' && $session.status === 'queued'}
    <CustomerLanguageSelector />

    <div class="splash queue-check" in:fly={{ y: 50 }} out:fly={{ y: -50 }} on:click={() => animCounter++}>
      <h1 class="title is-1">
        {#if $userData.phoneNumber}
          {$tC({ de: 'Endet Ihre Telefonnummer in den Ziffern **{n}?', en: 'Does your phone number end with **{n}?' }, { n: $userData.phoneNumber.slice(-3) })}
        {:else}
          {$tC({ de: 'Haben Sie die Wartenummer {n}?', en: 'Do you have queue number {n}?' }, { n: 'W' + $session.queueNo })}
        {/if}
      </h1>

      <div class="queue-buttons">
        <BrandButton --border-color="white" --bg-color="white" class="is-fullwidth shadow-ripples" on:click={() => startQueuedSession()}><Check /> {$tC({ de: 'Ja, das bin ich!', en: 'Yes, that\'s me!' })}</BrandButton>
        <BrandButton --border-color="white" --fg-color="white" class="is-fullwidth" on:click={() => showQueueMismatchError()}><X /> {$tC({ de: 'Nein, ich bin jemand anderer.', en: 'No, I\'m somebody else.' })}</BrandButton>
      </div>

      {#if $table.signalledSince}
        <h3 class="title is-3">{$tC({ de: 'Ihre Reservierung läuft ab in {t}', en: 'Your reservation will expire in {t}' }, { t: $second && formatMinutesSeconds(Math.max(0, (new Date($table.signalledSince).valueOf() + $bundle.settings.tableReservedDuration * 60000) - Date.now())) })}</h3>
      {/if}
    </div>
  {:else if $currentView === 'welcome' && $welcomeSubView === 'main'}
    <div class="splash" in:fly={{ y: 50 }} out:fly={{ y: -50 }} on:click={() => animCounter++}>
      {#if $table?.type === 'kiosk'}
        <div class="header">
          <h1 class="title is-1">Take-Away</h1>
          {#if !$table.kioskFeatures.includes('tableQueue') && $bundle.settings.powerSwitch.takeAwayEnabled}
            <h2 class="title is-2 de">Bestellen Sie hier zum Mitnehmen! Zum Hier-Essen bestellen Sie bitte am Tisch.</h2>
            <h2 class="title is-2 en">Order here for Take-Away! For Dine-In please order at the table.</h2>
          {/if}
        </div>
      {/if}
      <img src="/images/logo.svg?_=2" alt="YaoOrder" class="logo" />
      {#if $bundle.settings.powerSwitch.takeAwayEnabled}
        <div style:font-size="0"><!-- Prevent gap below the logo which is otherwise visible when ripple effect is shown -->
          {#each Object.entries(Object.entries(languages)) as [i, [lang, obj]]}
            {#key animCounter}
              <a href={undefined} on:click|stopPropagation={() => { $customerLanguage = $userData.language = lang; $currentView = 'menu' }} in:scale={{ x: 0.5, y: 0.5 }} >
                <Motion let:motion animate={{ scale: i > 0 ? [0.9, 1.1] : [1.1, 0.9] }} transition={{ duration: 1, ease: 'easeInOut', repeat: Infinity, repeatType: 'reverse' }}>
                  <div class="is-inline-block" use:rippleOnTouch use:motion><img src={obj._flag} alt={$tC(obj)} /></div>
                </Motion>
              </a>
            {/key}
          {/each}
        </div>
      {:else}
        <div class="header">
          <h2 class="title is-2 de">Für Take-Away-Bestellungen gehen Sie bitte zur Theke.</h2>
          <h2 class="title is-2 en">For take-away orders please go to the counter.</h2>
        </div>
      {/if}
      {#if $table?.type === 'kiosk' && $table.kioskFeatures.includes('tableQueue')}
        <div class="welcome-bottom-button">
          <BrandButton class="is-fullwidth" on:click={() => { $welcomeSubView = 'queue'; saveSession() }}>Hier-Essen / Dine-In</BrandButton>
        </div>
      {/if}
    </div>
  {:else if $currentView === 'welcome' && $welcomeSubView === 'queue'}
    <div class="splash queue-screen" in:fly={{ y: 50 }} out:fly={{ y: -50 }}>
      <img src="/images/logo.svg?_=2" alt="YaoOrder" class="logo" />
      <div class="queue-language-selector" style:font-size="0"> <!-- Prevent gap below the logo which is otherwise visible when ripple effect is shown -->
        {#each Object.entries(Object.entries(languages)) as [i, [lang, obj]]}
          <BrandButton --border-color="white" toggleable selected={$customerLanguage === lang} on:click={() => { $customerLanguage = $userData.language = lang; saveSession() }}>
            <img src={obj._flag} alt={$tC(obj)} />
          </BrandButton>
        {/each}
      </div>
      {#if $table.queueStats?.availableTableCount > 0}
        <div class="header queue-header" in:scale>
          <GoToTable />
          <h1 class="title is-1">{$tC($table.queueStats.availableTableCount === 1 ? { de: '{n} Tisch frei', en: '{n} table is free' } : { de: '{n} Tische frei', en: '{n} tables are free' }, { n: $table.queueStats.availableTableCount })}</h1>
          <h2 class="title is-2">{$tC({ de: 'Gehen Sie zur grünen Lampe!', en: 'Go to the green light!' })}</h2>
        </div>
        {#if $bundle.settings.powerSwitch.tableQueueEnabled}
          <div class="header queue-header" in:scale>
            <h2 class="title is-2" style:font-weight="normal">{$tC({ de: 'Doch nichts frei? Dann hier registrieren!', en: 'In fact nothing free? Then enqueue here!' })}</h2>
            <BrandButton --border-color="white" --fg-color="white" class="is-fullwidth" on:click={() => registerForQueue()} loading={$queueRegistering}><Bell /> {$tC({ de: 'In Warteschlange eintragen', en: 'Register for Table Queue' })}</BrandButton>
          </div>
        {/if}
      {:else}
        <div class="header queue-header" in:scale>
          <NoTable />
          <h1 class="title is-1">{$tC({ de: 'Kein Tisch frei', en: 'No free tables' })}</h1>
          {#if $bundle.settings.powerSwitch.tableQueueEnabled && $table.queueStats?.totalWaitingDuration}
            <h2 class="title is-2">{$tC({ de: 'Wartezeit ca. {n} Minuten', en: 'Waiting time approx. {n} minutes' }, { n: Math.round($table.queueStats?.totalWaitingDuration) })}</h2>
          {/if}
        </div>
        {#if $bundle.settings.powerSwitch.tableQueueEnabled}
          <div class="header queue-header" in:scale>
            <h2 class="title is-2 mb-6"><AlertCircle /> {$tC({ de: 'Bitte hier mit Handynr. registrieren um einen Tisch zu bekommen!', en: 'Please register here with your phone number in order to get a table!' })}</h2>
            <BrandButton --border-color="white" --bg-color="white" class="is-fullwidth shadow-ripples" on:click={() => registerForQueue()} loading={$queueRegistering}><Bell /> {$tC({ de: 'In Warteschlange eintragen', en: 'Register for Table Queue' })}</BrandButton>
          </div>
        {/if}
      {/if}
      {#if $table?.type === 'kiosk' && $table.kioskFeatures.includes('takeAway')}
        <div class="welcome-bottom-button">
          <BrandButton class="is-fullwidth" disabled={$queueRegistering} on:click={() => { $welcomeSubView = 'main'; saveSession() }}>Take-Away Menu</BrandButton>
        </div>
      {/if}
    </div>
  {:else if $currentView === 'menu'}
    <div in:fly={{ y: 50 }} out:fly={{ y: -50 }}>
      <MainOrderUi />
    </div>
  {:else if $currentView === 'payment'}
    <div in:fly={{ y: 50 }} out:fly={{ y: -50 }}>
      <PaymentUi />
    </div>
  {:else}
    <div class="splash" in:fly={{ y: 50 }} out:fly={{ y: -50 }}>
      <img src="/images/logo.svg?_=2" alt="YaoOrder" class="logo" />
      <div>
        <h2 class="title is-2 has-text-centered my-4">{$tC({ de: 'Interner Fehler, bitte wenden Sie sich an einen Mitarbeiter', en: 'Internal error, please contact a member of staff' })}</h2>
      </div>
    </div>
  {/if}
</main>

{#if $isTestTableMode}
  <TestTableModeOverlay />
{/if}

{#if showIdleSlideshow && $bundle.settings.tableSlideshow?.length}
  <Slideshow slides={$bundle.settings.tableSlideshow} withButton on:click={() => { reportSessionActivity(); showIdleSlideshow = false }} />
{/if}

{#if $isEvent && $table?.type === 'kiosk' && $bundle.settings.eventSettings?.kioskSlideshow?.length}
  <Slideshow slides={$bundle.settings.eventSettings.kioskSlideshow} />
{:else if $isEvent && $table?.type !== 'kiosk' && $bundle.settings.eventSettings?.tableSlideshow?.length}
  <Slideshow slides={$bundle.settings.eventSettings.tableSlideshow} />
{:else if ($isEvent || $isOff) && $table?.type === 'kiosk' && ($table.kioskSlideshow?.length || $bundle.settings.kioskSlideshow?.length)}
  <Slideshow slides={$table.kioskSlideshow?.length ? $table.kioskSlideshow : $bundle.settings.kioskSlideshow} />
{/if}

{#if $batteryStatus && $batteryStatus.level <= 5}
  <ModalDialog>
    <h2 class="title is-2 has-text-centered my-4">{$tC({ de: 'Akku fast leer!', en: 'Battery almost empty!' })}</h2>
    <p class="has-text-centered">{$tC({ de: 'Bitte wenden Sie sich an einen Mitarbeiter.', en: 'Please contact a member of staff.' })}</p>
  </ModalDialog>
{/if}
