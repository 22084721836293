<script>
  import { Button, Icon } from 'svelma-fixed'
  import { devOptions } from '../stores/devOptions'

  function loadDebugger () {
    const script = document.createElement('script')
    script.src = 'https://debugjs.net/debug.js'
    script.addEventListener('load', () => {
      window.dbg.init({
        visible: true,
        zoom: 1.3
      })
      window.dbg.setWindowSize(600, 250)
      window.dbg.setWindowPos(50, 50)
    })
    document.body.appendChild(script)
  }
</script>

<svelte:head>
  <title>Doc Brown's Garage - YaoOrder</title>
</svelte:head>

<style lang="scss">
  .container {
    max-height: 100%;
    overflow: auto;
  }
</style>

<div class="container">
  <div class="box my-4">
    <div class="is-pulled-right">
      <img style:width={'300px'} style:max-width={'33vw'} src="https://i.imgur.com/buBxOGs.png" alt="Doc Brown wearing goggles" />
    </div>
    <h4 class="title is-4">
      Doc Brown's Garage
    </h4>

    <p>
      Welcome to Doc Brown's Garage, the place where we test new features and debug issues. Please note that this page is not meant for public consumption and may contain broken features and other weird stuff. Also, changes of flags or settings on this page may require a page reload to take effect!
    </p>

    <Button type="is-light" on:click={() => location.reload()}>Reload Page</Button>

    <hr style:clear="both" />

    <h5 class="title is-5">Feature Flags</h5>

    <div>There are no feature flags at the moment.</div>

    <hr />

    <h5 class="title is-5">Debug Options</h5>

    <div>
      <label class="checkbox">
        <input type="checkbox" bind:checked={$devOptions.debugOptions.allowSelection}>
        Allow Text Selection in YaoKiosk
      </label>
    </div>

    <div>
      <Button type="is-light" on:click={loadDebugger}>Load Debugger</Button>
    </div>

    <hr />

    <h5 class="title is-5">Test Pages</h5>

    <ul>
      <li>
        <a href="/component-test">Component Test Page</a>
      </li>
    </ul>

    <hr />

    <a href="/"><Icon icon="home" /> Navigate to home</a>

  </div>
</div>
