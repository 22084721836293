<script>
  import { swipe } from 'svelte-gestures'
  import { AnimateSharedLayout, Motion } from 'svelte-motion'
  import { rippleOnTouch } from '../lib/utils'

  export let tabs = [] // ids
  export let currentTab

  $: if (!currentTab && tabs[0]) currentTab = tabs[0]

  function onSwipe (e) {
    if (e.detail.direction === 'right') {
      const currentIndex = tabs.indexOf(currentTab)
      if (currentIndex < tabs.length - 1) currentTab = tabs[currentIndex + 1]
    } else if (e.detail.direction === 'left') {
      const currentIndex = tabs.indexOf(currentTab)
      if (currentIndex > 0) currentTab = tabs[currentIndex - 1]
    }
  }
</script>

<style lang="scss">
  .pill-tabs {
    display: flex;
    background-color: $field-bg;
    border-radius: 9999px;
    overflow: hidden;
    position: relative;
    border: 2px solid $brand-color;
    width: 100%;

    :global(.ripple-container) {
      border-radius: 9999px;
      z-index: 0;
    }

    .pill-tab {
      flex: 1;
      position: relative;
      padding: 0.5rem;
      font-size: 0.75rem;
      transition: color 0.2s;
      color: $grey;
      border-radius: 9999px;

      &.is-selected {
        color: $text;
      }

      &:not(.is-selected) {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .selected-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 9999px;
        box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
        z-index: 1;
      }

      .tab-content {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        :global(svg) {
          vertical-align: middle;
          transform: translateY(-0.1em);
        }
      }
    }
  }
</style>

<AnimateSharedLayout>
  <nav class="pill-tabs" use:swipe={{ timeframe: 2000, minSwipeDistance: 10 }} on:swipe={onSwipe} use:rippleOnTouch>
    {#each tabs as tab}
      {@const active = tab === currentTab}
      <div class="pill-tab" class:is-selected={active} on:click={() => (currentTab = tab)}>
        {#if active}
          <Motion let:motion layoutId="selected-bg" initial={false}>
            <div use:motion class="selected-bg"></div>
          </Motion>
        {/if}

        <div class="tab-content"><slot {tab}></slot></div>
      </div>
    {/each}
  </nav>
</AnimateSharedLayout>
