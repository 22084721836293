// View components
import View404 from './views/View404.svelte'
import ViewMain from './views/ViewMain.svelte'
import ViewCounter from './views/ViewCounter.svelte'

import ViewComponentTest from './views/ViewComponentTest.svelte'
import ViewDocBrown from './views/ViewDocBrown.svelte'

export default [
  {
    path: '/',
    name: 'HOME',
    component: ViewMain
  },
  {
    path: '/counter',
    name: 'counter',
    component: ViewCounter
  },
  {
    path: '/component-test',
    name: 'componentTest',
    component: ViewComponentTest
  },
  {
    path: '/doc-brown',
    name: 'docBrown',
    component: ViewDocBrown
  },
  {
    path: '*',
    component: View404
  }
]
