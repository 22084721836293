<style lange="scss">
  svg {
    width: var(--width, auto);
    height: var(--height, 0.75em);
    color: var(--color, currentColor);
  }
</style>

<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="324.000000pt" height="441.000000pt" viewBox="0 0 324.000000 441.000000" preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,441.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
    <path d="M2571 4173 c-130 -267 -328 -491 -520 -588 -29 -14 -97 -42 -150 -60 -53 -19 -172 -70 -263 -114 l-168 -80 32 -53 c18 -29 51 -83 75 -119 23 -36 58 -92 77 -125 20 -32 48 -80 63 -105 l27 -46 101 75 c180 136 312 246 493 410 56 51 102 89 102 84 0 -18 -84 -139 -153 -220 -103 -122 -301 -302 -459 -417 l-28 -20 48 -72 c26 -39 65 -101 87 -138 22 -36 54 -87 71 -113 16 -26 48 -76 69 -112 21 -36 53 -87 70 -115 18 -27 41 -64 51 -82 69 -117 177 -283 184 -283 13 0 108 108 152 172 52 77 58 92 178 423 118 326 136 396 151 602 20 259 -18 617 -91 863 -35 118 -115 313 -134 329 -10 9 -25 -13 -65 -96z"/>
    <path d="M513 4231 c-120 -30 -204 -150 -187 -269 6 -40 65 -146 178 -322 23 -36 66 -105 96 -155 30 -49 79 -129 109 -178 31 -48 74 -117 96 -153 22 -36 58 -94 81 -127 22 -34 71 -114 109 -177 38 -63 94 -154 124 -202 31 -47 83 -130 116 -183 33 -53 75 -120 94 -148 19 -29 69 -110 112 -182 44 -71 102 -166 130 -210 83 -130 171 -271 214 -341 22 -36 59 -95 82 -130 24 -35 57 -90 75 -121 18 -32 55 -93 83 -135 49 -75 130 -205 210 -334 22 -36 59 -94 82 -129 23 -35 59 -94 81 -132 42 -74 51 -87 152 -243 57 -89 76 -110 116 -132 91 -48 160 -46 246 6 71 43 112 116 112 200 -1 77 -20 117 -161 331 -23 35 -59 94 -81 132 -21 37 -49 84 -61 103 -12 19 -50 79 -84 132 -34 53 -89 140 -122 193 -33 54 -79 125 -102 160 -23 35 -59 94 -81 132 -21 37 -49 84 -61 103 -12 19 -50 79 -84 132 -34 53 -89 140 -122 193 -33 53 -75 120 -94 148 -18 29 -41 68 -51 87 -10 19 -43 72 -72 118 -29 45 -71 112 -93 148 -22 36 -76 121 -120 190 -86 133 -117 183 -193 309 -27 44 -60 97 -73 117 -13 21 -42 67 -64 103 -22 36 -67 108 -100 160 -114 178 -338 540 -392 630 -17 30 -43 69 -57 88 -50 65 -165 107 -243 88z"/>
    <path d="M713 2827 c-206 -216 -340 -467 -396 -747 -27 -134 -41 -330 -41 -585 0 -228 10 -433 20 -422 2 2 24 78 49 168 106 395 221 649 405 894 63 84 186 211 255 264 24 18 27 25 18 39 -7 9 -33 51 -59 92 -26 41 -70 111 -99 155 -29 44 -65 104 -82 132 l-29 53 -41 -43z"/>
    <path d="M998 2218 c-192 -210 -332 -427 -439 -678 -129 -304 -212 -671 -226 -1004 -6 -148 -6 -149 18 -167 39 -30 48 -24 51 33 l3 53 65 33 c117 58 252 75 383 47 91 -19 158 -51 241 -115 92 -70 307 -181 428 -219 129 -41 223 -54 339 -49 93 5 103 8 288 84 105 43 191 81 191 85 0 3 -24 44 -54 90 -75 116 -117 183 -160 259 l-37 65 -146 29 c-81 15 -207 46 -282 69 l-136 41 -170 1 c-187 0 -265 -12 -428 -67 -149 -49 -405 -187 -484 -261 -29 -26 -29 -14 2 79 103 307 353 566 635 657 41 14 125 32 185 42 61 9 172 37 248 62 137 44 138 45 123 66 -9 12 -28 42 -43 67 -14 25 -42 71 -62 102 -20 31 -63 100 -96 153 -33 53 -85 136 -116 183 -30 48 -91 147 -135 219 -43 73 -84 133 -90 133 -6 0 -50 -42 -96 -92z"/>
    <path d="M1270 1217 c-174 -48 -325 -148 -544 -357 -64 -62 -116 -115 -116 -119 0 -3 60 23 133 59 331 164 574 197 849 115 106 -32 249 -69 361 -94 l87 -19 -22 38 c-52 87 -84 139 -133 215 -28 44 -60 94 -70 112 -27 47 -109 66 -305 70 -138 3 -164 0 -240 -20z"/>
  </g>
</svg>
