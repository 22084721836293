<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import counterBundle from '../stores/counterBundle'

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('close')
  }
</script>

<style lang="scss">
</style>

<ModalDialog --width="95vw" class="employee-ui">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Tisch zuweisen', en: 'Assign Tableustomer' })}</h1>
        </div>
      </div>
      <div class="level-right"></div>
    </div>

    <div class="big-buttons">
      <Button class="big-button" type="is-dark" on:click={close} iconLeft="arrow-left">{$tE({ de: 'Zurück', en: 'Back' })}</Button>

      {#each Object.values($counterBundle.tables).filter(t => t.type !== 'kiosk' && (t.status === 'free' || t.status === 'blocked')) as table}
        <Button class="big-button" type={table.status === 'free' ? 'has-text-success' : 'has-text-danger'} on:click={() => dispatch('close', table.id)}>{table.number}</Button>
      {:else}
        <h3 class="title is-3 is-inline-block">
          {$tE({ de: 'Keine freien oder blockierten Tische verfügbar', en: 'No free or blocked tables available' })}
        </h3>
      {/each}
    </div>
  </main>
</ModalDialog>
