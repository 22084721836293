<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Dialog, Button, Input } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import { bundle } from '../stores/bundle'
  import { escape, escapeRegexp, formatCurrency, timeRestrictionMatches } from '../lib/utils'
  import MobileTooltip from './MobileTooltip.svelte'
  import uri from 'uri-tag'
  import dialogs from '../stores/dialogs'
  import AddArticleToGroupDialog from './AddArticleToGroupDialog.svelte'

  const dispatch = createEventDispatcher()
  const loading = createLoadingStore()

  function close () {
    dispatch('close')
  }

  $: unavailable = [
    ...Object.values($bundle.articleGroups).filter(group => !group.available),
    ...Object.values($bundle.articles).filter(article => !article.available)
  ]

  $: menuGroups = $bundle.categories.filter(category => !category.id.startsWith('_'))
  $: subGroups = Object.values($bundle.articleGroups).filter(group => group.type !== 'main' && !$bundle.categories.includes(group))

  $: editableGroups = Object.values($bundle.articleGroups).filter(group => group.changeableByCounterStaff)

  let tab = 'unavailable'
  let searchValue = ''

  async function setAvailability (type, id, available) {
    await loading(async () => {
      try {
        await apiCall('POST', '/api/app/setAvailability', { type, id, available })

        if (type === 'article') {
          if ($bundle.articles[id]) $bundle.articles[id].available = available
        } else if (type === 'articleGroup') {
          if ($bundle.articleGroups[id]) $bundle.articleGroups[id].available = available
        }
      } catch (e) {
        console.error('Error updating availability:', e)
        throw e
      }
    })
  }

  function isMatching (needle, haystack) {
    return new RegExp('\\b' + escapeRegexp(needle.trim()), 'i').test(haystack)
  }

  async function addItem (group) {
    await loading(async () => {
      const article = await dialogs.open(AddArticleToGroupDialog, { group })
      if (!article) return

      try {
        await apiCall('POST', uri`/api/app/editGroup/${group.id}/addArticle`, { articleId: article.id })

        $bundle.articleGroups[group.id].articles = [...$bundle.articleGroups[group.id].articles, article]
      } catch (e) {
        console.error('Error updating group:', e)
        throw e
      }
    })
  }

  async function removeItem (group, article) {
    if (!await Dialog.confirm({
      message: $tE({ de: 'Artikel "{n}" aus der Gruppe entfernen?', en: 'Remove article "{n}" from group?' }, { n: escape($tE(article.adminName)) }),
      type: 'is-info',
      icon: 'question-circle',
      confirmText: $tE({ de: 'Ja', en: 'Yes' }),
      cancelText: $tE({ de: 'Nein', en: 'No' }),
      size: 'is-large'
    })) return

    await loading(async () => {
      try {
        await apiCall('POST', uri`/api/app/editGroup/${group.id}/removeArticle`, { articleId: article.id })

        $bundle.articleGroups[group.id].articles = $bundle.articleGroups[group.id].articles.filter(a => a.id !== article.id)
      } catch (e) {
        console.error('Error updating group:', e)
        throw e
      }
    })
  }
</script>

<style lang="scss">
  h4 {
    margin: $thin-margin 0 !important;
  }

  .group-level {
    margin-top: $thin-margin;
    margin-bottom: 0 !important;
    min-height: 3.5rem;
  }

  :global(.article-management-dialog):has(input.search-input:focus) {
    padding-bottom: 50vh;
  }

  :global(.article-management-dialog .unavailable-item) {
    background-image: linear-gradient(to bottom right, transparent, transparent 49%, rgba($danger, 0.15) 49%, rgba($danger, 0.15) 51%, transparent 51%, transparent);
  }
</style>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui no-auto-top-half article-management-dialog">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Artikel', en: 'Articles' })}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <Button type={tab === 'unavailable' ? 'is-dark' : 'is-light'} disabled={tab === 'unavailable'} on:click={() => (tab = 'unavailable')}>{$tE({ de: 'Nicht verfügbar', en: 'Unavailable' })} ({unavailable.length})</Button>
          <Button type={tab === 'menu' ? 'is-dark' : 'is-light'} disabled={tab === 'menu'} on:click={() => (tab = 'menu')}>{$tE({ de: 'Menükarte', en: 'Menu' })}</Button>
          <Button type={tab === 'sub' ? 'is-dark' : 'is-light'} disabled={tab === 'sub'} on:click={() => (tab = 'sub')}>{$tE({ de: 'Unterartikel', en: 'Sub-Articles' })}</Button>
          <Button type={tab === 'editable' ? 'is-dark' : 'is-light'} disabled={tab === 'editable'} on:click={() => (tab = 'editable')}>{$tE({ de: 'Gruppen-Editor', en: 'Group Editor' })}</Button>
        </div>
        <div class="level-item">
          <Button class="big-button semi-big tight" type="is-dark" on:click={close} iconLeft="times">{$tE({ de: 'Schließen', en: 'Close' })}</Button>
        </div>
      </div>
    </div>

    {#if tab === 'unavailable'}
      <div class="level group-level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">{$tE({ de: 'Nicht verfügbare Artikelgruppen', en: 'Unavailable Article Groups' })}</h4>
          </div>
        </div>
        <div class="level-right"></div>
      </div>
      <div class="big-buttons">
        {#each unavailable.filter(group => group.articles) as group}
          {#if group.hasProblems}
            <Button class="big-button semi-big" type="is-danger" disabled>{$tE(group.adminName)}<br />{$tE({ de: 'UNGÜLTIG!', en: 'INVALID!' })}</Button>
          {:else}
            <Button class="big-button semi-big unavailable-item" type="is-secondary" on:click={() => setAvailability('articleGroup', group.id, true)} disabled={$loading}>{$tE(group.adminName)}<br />{group.articles.length} {$tE({ de: 'Artikel', en: 'article' + (group.articles.length === 1 ? '' : 's') })} [<strong class="has-text-danger">OFF</strong>]</Button>
          {/if}
        {:else}
          <Button class="big-button semi-big" type="is-light" disabled>{$tE({ de: 'Keine', en: 'None' })}</Button>
        {/each}
      </div>

      <div class="level group-level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">{$tE({ de: 'Nicht verfügbare Artikel', en: 'Unavailable Articles' })}</h4>
          </div>
        </div>
        <div class="level-right"></div>
      </div>
      <div class="big-buttons">
        {#each unavailable.filter(article => !article.articles) as article}
          {#if article.hasProblems}
            <Button class="big-button semi-big" type="is-danger" disabled>{$tE(article.adminName)}<br />{$tE({ de: 'UNGÜLTIG!', en: 'INVALID!' })}</Button>
          {:else}
            <Button class="big-button semi-big unavailable-item" type="is-secondary" on:click={() => setAvailability('article', article.id, true)} disabled={$loading}>{$tE(article.adminName)}<br />{$tE && formatCurrency(article.posArticle?.price, true)} [<strong class="has-text-danger">OFF</strong>]</Button>
          {/if}
        {:else}
          <Button class="big-button semi-big" type="is-light" disabled>{$tE({ de: 'Keine', en: 'None' })}</Button>
        {/each}
      </div>
    {:else if tab === 'menu'}
      <Input expanded class="search-input" type="search" bind:value={searchValue} placeholder={$tE({ de: 'Suchen...', en: 'Search...' })} icon="search" />

      {#each menuGroups.filter(group => !searchValue.trim() || isMatching(searchValue, $tE(group.adminName)) || group.articles?.some(article => isMatching(searchValue, $tE(article.adminName)))) as group}
        <div class="level group-level">
          <div class="level-left">
            <div class="level-item">
              <h4 class="title is-4">
                {$tE(group.adminName)}
                {#if group.timeRestriction}
                  <MobileTooltip label="{$tE({ de: 'Zeitbeschränkung', en: 'Time restriction' })} ({$tE(timeRestrictionMatches(group) ? { de: 'momentan sichtbar', en: 'currently visible' } : { de: 'momentan unsichtbar', en: 'currently invisible' })})"><i class="fa fa-clock {timeRestrictionMatches(group) ? 'has-text-success' : 'has-text-danger'}"></i></MobileTooltip>
                {/if}
              </h4>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <Button class="big-button semi-big tight {!group.available ? 'unavailable-item' : ''}" type="is-secondary" iconLeft="power-off" on:click={() => setAvailability('articleGroup', group.id, !group.available)} disabled={$loading}>{group.articles.length} {$tE({ de: 'Artikel', en: 'article' + (group.articles.length === 1 ? '' : 's') })} [{#if group.available}<strong class="has-text-success">ON</strong>{:else}<strong class="has-text-danger">OFF</strong>{/if}]</Button>
            </div>
          </div>
        </div>
        <div class="big-buttons" style:opacity={group.available ? 1 : 0.5}>
          {#each group.articles.filter(article => !searchValue.trim() || isMatching(searchValue, $tE(article.adminName))) as article}
            {#if article.hasProblems}
              <Button class="big-button semi-big" type="is-danger" disabled>{$tE(article.adminName)}<br />{$tE({ de: 'UNGÜLTIG!', en: 'INVALID!' })}</Button>
            {:else}
              <Button class="big-button semi-big {(!group.available || !article.available) ? 'unavailable-item' : ''}" type="is-secondary" on:click={() => setAvailability('article', article.id, !article.available)} disabled={$loading}>{$tE(article.adminName)}<br />{$tE && formatCurrency(article.posArticle?.price, true)} [{#if article.available && group.available}<strong class="has-text-success">ON</strong>{:else if article.available && !group.available}<strong class="has-text-warning">GRP OFF</strong>{:else}<strong class="has-text-danger">OFF</strong>{/if}]</Button>
            {/if}
          {/each}
        </div>
      {:else}
        <p>{$tE({ de: 'Keine passenden Gruppen gefunden!', en: 'No matching groups found!' })}</p>
      {/each}
    {:else if tab === 'sub'}
      {#each subGroups.filter(group => !searchValue.trim() || isMatching(searchValue, $tE(group.adminName)) || group.articles?.some(article => isMatching(searchValue, $tE(article.adminName)))) as group}
        <div class="level group-level">
          <div class="level-left">
            <div class="level-item">
              <h4 class="title is-4">{$tE(group.adminName)}</h4>
            </div>
          </div>
          <div class="level-right"></div>
        </div>
      <div class="big-buttons" style:opacity={group.available ? 1 : 0.5}>
          {#each group.articles.filter(article => !searchValue.trim() || isMatching(searchValue, article.adminName)) as article}
            {#if article.hasProblems}
              <Button class="big-button semi-big" type="is-danger" disabled>{$tE(article.adminName)}<br />{$tE({ de: 'UNGÜLTIG!', en: 'INVALID!' })}</Button>
            {:else}
              <Button class="big-button semi-big {(!group.available || !article.available) ? 'unavailable-item' : ''}" type="is-secondary" on:click={() => setAvailability('article', article.id, !article.available)} disabled={$loading}>{$tE(article.adminName)}<br />{$tE && formatCurrency(article.posArticle?.price, true)} [{#if article.available}<strong class="has-text-success">ON</strong>{:else}<strong class="has-text-danger">OFF</strong>{/if}]</Button>
            {/if}
          {/each}
        </div>
      {:else}
        <p>{$tE({ de: 'Keine passenden Gruppen gefunden!', en: 'No matching groups found!' })}</p>
      {/each}
    {:else if tab === 'editable'}
      {#each editableGroups as group}
        <div class="level group-level">
          <div class="level-left">
            <div class="level-item">
              <h4 class="title is-4">{$tE(group.adminName)}</h4>
            </div>
            <div class="level-right"></div>
          </div>
        </div>
        <div class="big-buttons">
          {#each group.articles as article}
            <Button class="big-button semi-big" type="is-secondary" on:click={() => removeItem(group, article)} disabled={$loading} iconLeft="trash">{$tE(article.adminName)}<br />{$tE && formatCurrency(article.posArticle?.price, true)}</Button>
          {/each}
          <Button class="big-button semi-big" type="is-secondary" on:click={() => addItem(group)} disabled={$loading} iconLeft="plus">{$tE({ de: 'Artikel hinzufügen', en: 'Add article' })}</Button>
        </div>
      {:else}
        <p>{$tE({ de: 'Keine editierbaren Gruppen gefunden!', en: 'No editable groups found!' })}</p>
      {/each}
    {/if}
  </main>
</ModalDialog>
