<script>
  import { tE } from '../stores/i18n'

  export let text
  export let inline = false

  $: dispText = text ?? $tE({ de: 'Wird geladen...', en: 'Loading...' })

  import MiddleBox from './MiddleBox.svelte'
  import { Icon } from 'svelma-fixed'
</script>

{#if inline}
  <div class="has-text-centered">
    <p class="m-3"><Icon icon="spinner" size="is-large" customClass="fa-pulse" /></p>
    <h2 class="subtitle">{dispText}</h2>
  </div>
{:else}
  <MiddleBox class="has-text-centered">
    <p class="m-3"><Icon icon="spinner" size="is-large" customClass="fa-pulse" /></p>
    <h2 class="subtitle">{dispText}</h2>
  </MiddleBox>
{/if}
