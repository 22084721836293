<script>
  import { userData } from '../stores/session'
  import ZoomIn from 'svelte-feather/components/ZoomIn.svelte'
  import ZoomOut from 'svelte-feather/components/ZoomOut.svelte'
  import { rippleOnTouch } from '../lib/utils'
  import TinyGesture from 'tinygesture'
  import { onDestroy, onMount } from 'svelte'

  let gesture
  onMount(() => {
    gesture = new TinyGesture(document.body)
    let originalFontSize = null
    gesture.on('pinch', () => {
      if (originalFontSize === null) originalFontSize = $userData.fontSize

      const change = Math.log(gesture.scale) / Math.log(1.5)
      $userData.fontSize = Math.round(Math.max(1, Math.min(5, originalFontSize + change)))

      document.querySelectorAll('.ripple-container').forEach(el => el.remove())
    })

    gesture.on('pinchend', () => {
      originalFontSize = null
    })
  })

  onDestroy(() => {
    gesture.destroy()
  })
</script>

<style lang="scss">
  main {
    color: $text;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 43px;
    z-index: 5;
    padding-left: 7px;
    font-size: 12px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 7px;
      padding: 7px;
      padding-top: 2px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 9999px;
    }

    a {
      color: currentColor !important;
      transform: scale(1.5);
      margin: 0 -8px;
      display: block;
      width: 28px;
      text-align: center;
      line-height: 17px;

      :global(.ripple-container) {
        left: 6px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }

      &:first-of-type {
        :global(.ripple-container) {
          top: 3.5px;
        }

        :global(svg) {
          transform: translateY(5px);
        }
      }

      &:last-of-type {
        :global(.ripple-container) {
          top: -1.5px;
        }
      }
    }

    a.is-disabled {
      opacity: 0.5;
    }

    $width: 14px;
    $height: 43px;

    span {
      display: inline-block;
      width: $width;
      height: $height;
    }

    input {
      color: currentColor;
      appearance: none;
      -webkit-appearance: none;
      width: $height;
      height: $width;
      display: block;
      background: transparent;

      transform: rotate(270deg) translateX(-100%);
      transform-origin: 0 0;

      &::-webkit-slider-runnable-track {
        background: currentColor;
        height: 1px;
      }

      &::-moz-range-track { // Cannot be combined with comma!
        background: currentColor;
        height: 1px;
      }

      &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: currentColor;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        margin-top: -4px;
      }

      &::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: currentColor;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        border: none;
      }
    }
  }
</style>

{#if $userData}
  <main tabindex="0">
    <div class="has-text-link">
      <a href={undefined} on:click={() => { if ($userData.fontSize < 5) $userData.fontSize++ }} class:is-disabled={$userData.fontSize >= 5} use:rippleOnTouch><ZoomIn size="tiny" width="1px" /></a>
      <span><input type="range" min="1" max="5" step="1" bind:value={$userData.fontSize} /></span>
      <a href={undefined} on:click={() => { if ($userData.fontSize > 1) $userData.fontSize-- }} class:is-disabled={$userData.fontSize <= 1} use:rippleOnTouch><ZoomOut size="tiny" width="1px" /></a>
    </div>
  </main>
{/if}
