<script>
  import { tC } from '../stores/i18n'
  import ModalDialog from './ModalDialog.svelte'
  import { doZoomImage, formatCurrency, getMediaUrl, handleOverscroll } from '../lib/utils'
  import ArticleDetails from './ArticleDetails.svelte'
  import BrandButton from './BrandButton.svelte'
  import BrandButtonRow from './BrandButtonRow.svelte'
  import Plus from 'svelte-feather/components/Plus.svelte'
  import Minus from 'svelte-feather/components/Minus.svelte'
  import Check from 'svelte-feather/components/Check.svelte'
  import NewBadge from './NewBadge.svelte'
  import { isMatchingFilter } from '../stores/session'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  // Note: This dialog is designed to be used directly within a component and not via the dialogs store so that live update still works.

  export let group
  export let articleConfiguration
  export let showPricePlus = false
  export let updateSubArticleSelection

  $: selectedSubArticles = articleConfiguration.subArticles.filter(subArticle => subArticle.subArticleGroupId === group.id)
</script>

<style lang="scss">
  main {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin; // To allow scrolling during preview

    .article-column {
      display: grid;

      &:not(:last-child) {
        border-right: 1px solid $brand-color;
      }

      grid-template-columns: calc((95vw - 16px) / 3.3);
      grid-template-rows: 1fr auto;
      grid-template-areas:
        "details"
        "button";

      .product-image-container {
        background: white;
        width: calc(100% + 4rem);
        box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
        cursor: zoom-in;
        margin-top: calc(-150px - $default-margin); // 150px is the overscroller!
        padding-top: 150px;
        margin-left: -$default-margin;
        margin-right: -$default-margin;
        margin-bottom: $default-margin;

        .product-image {
          background-size: contain;
          background-position: center;
          width: 100%;
          aspect-ratio: 1.75/1;
          pointer-events: none;

          background: var(--bg) no-repeat center center;
          background-size: contain;

          z-index: 1;

          $zoom: 1; // No zoom this time

          transform: scale($zoom);

          &[data-unavailable]::after {
            transform: rotate(-10deg) scale(calc(1 / $zoom));
          }
        }
      }

      .article-container {
        grid-area: details;
        max-height: calc(95vh - 220px);
        width: 100%;
        min-width: 0;
        min-height: 0;
        background: $field-bg;

        overflow: hidden;
        hyphens: auto;

        position: relative;

        $fade-out-height: 4rem;

        &::after {
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          left: 0;
          width: 100%;
          height: $fade-out-height;
          background: linear-gradient(to bottom,
            rgba($field-bg, 0) 0%,
            rgba($field-bg, 0.005) 2%,
            rgba($field-bg, 0.01) 4%,
            rgba($field-bg, 0.02) 6%,
            rgba($field-bg, 0.035) 8%,
            rgba($field-bg, 0.05) 10%,
            rgba($field-bg, 0.125) 12%,
            rgba($field-bg, 0.25) 14%,
            rgba($field-bg, 0.4) 16%,
            rgba($field-bg, 0.6) 20%,
            rgba($field-bg, 0.75) 30%,
            rgba($field-bg, 0.9) 40%,
            rgba($field-bg, 0.95) 50%,
            rgba($field-bg, 1) 75%,
            rgba($field-bg, 1) 100%
          );

          background-position-y: calc($fade-out-height * 0.25);
          background-repeat: no-repeat;

          pointer-events: none;
        }

        article {
          width: 100%;
          height: 100%;
          padding: $default-margin $default-margin calc($fade-out-height * 0.75);
          overflow-x: hidden;
          overflow-y: auto;

          span {
            position: relative;

            :global(.new-badge) {
              left: -0.4rem;
              top: -0.4rem;
            }
          }
        }
      }

      .not-available-placeholder {
        width: 100%;
        aspect-ratio: 5/1;
      }

      .button-container {
        background: $field-bg;
        grid-area: button;
        padding: 0 $default-margin $default-margin;
      }
    }
  }

  .ok-button-container {
    border-top: 1px solid $brand-color;
    padding: $default-margin
  }
</style>

<ModalDialog noPadding title={$tC(group.articleGroup.name)} closeable on:close>
  <main>
    {#each group.articleGroup.articles as article}
      {@const selectionInfo = selectedSubArticles.find(selectionInfo => selectionInfo.article === article.id) ?? { article: article.id, quantity: 0, subArticleGroupId: group.id }}
      {#if selectionInfo.quantity > 0 || $isMatchingFilter(article)}
        <div class="article-column">
          <div class="article-container">
            <article use:handleOverscroll on:scroll={e => e.target.closest('article').querySelectorAll('.ripple-container').forEach(container => container.remove())}>
              {#if article?.image}
                <div class="product-image-container" on:click={e => doZoomImage(e, getMediaUrl(article.image, 'large')) }>
                  <div class="product-image" style:--bg={article.image ? `url("${getMediaUrl(article.image, 'large')}")` : 'transparent'} {...!article.available ? { 'data-unavailable': $tC({ de: 'Nicht verfügbar', en: 'Not Available' }) } : {}} />
                </div>
              {:else if article && !article.available}
                <div class="not-available-placeholder" data-unavailable={$tC({ de: 'Nicht verfügbar', en: 'Not Available' })}></div>
              {/if}
              <span class:is-unavailable={article && !article.available}>
                {#if article?.newBadge}
                  <NewBadge />
                {/if}

                <ArticleDetails articleId={article?.id} mode="subArticle" narrow />
              </span>
            </article>
          </div>
          <div class="button-container">
            {#if article}
              <BrandButtonRow>
                {#if group.effectiveSelectionMode === 'single'}
                  {#if selectionInfo.quantity > 0}
                    <BrandButton class="is-fullwidth" on:click={() => updateSubArticleSelection(article.id, 1, group.id, true)} toggle selected>
                      {$tC({ de: 'Ausgewählt', en: 'Selected' })} {#if article.minPrice}({(showPricePlus && article.minPrice > 0) ? '+ ' : ''}{$tC && formatCurrency(article.minPrice * selectionInfo.quantity)}){/if}
                    </BrandButton>
                  {:else}
                    <BrandButton class="is-fullwidth" disabled={!article.available} on:click={() => updateSubArticleSelection(article.id, 1, group.id, true)}>
                      <Check /> {$tC({ de: 'Auswählen', en: 'Select' })} {#if article.minPrice}({(showPricePlus && article.minPrice > 0) ? '+ ' : ''}{$tC && formatCurrency(article.minPrice)}){/if}
                    </BrandButton>
                  {/if}
                {:else}
                  {#if selectionInfo.quantity > 0}
                    {#if article.allowOnlySingleQuantity}
                      <BrandButton class="is-fullwidth" on:click={() => updateSubArticleSelection(article.id, 0, group.id)} toggle selected>
                        <Check /> {#if selectionInfo.quantity > 1}<strong>{selectionInfo.quantity}&times;</strong>{:else}{$tC({ de: 'Ausgewählt', en: 'Selected' })}{/if} {#if article.minPrice}({(showPricePlus && article.minPrice > 0) ? '+ ' : ''}{$tC && formatCurrency(article.minPrice * selectionInfo.quantity)}){/if}
                      </BrandButton>
                    {:else}
                      <BrandButton class="is-fullwidth" on:click={() => updateSubArticleSelection(article.id, 0, group.id)} toggle selected>
                        <strong>{selectionInfo.quantity}&times;</strong> {#if article.minPrice}({(showPricePlus && article.minPrice > 0) ? '+ ' : ''}{$tC && formatCurrency(article.minPrice * selectionInfo.quantity)}){/if}
                      </BrandButton>
                      <BrandButton icon on:click={() => updateSubArticleSelection(article.id, selectionInfo.quantity + 1, group.id)}><Plus /></BrandButton>
                      <BrandButton icon on:click={() => updateSubArticleSelection(article.id, selectionInfo.quantity - 1, group.id)}><Minus /></BrandButton>
                    {/if}
                  {:else}
                    <BrandButton class="is-fullwidth" disabled={!article.available} on:click={() => updateSubArticleSelection(article.id, 1, group.id)} toggle>
                      <Plus /> {$tC({ de: 'Hinzufügen', en: 'Add' })} {#if article.minPrice}({(showPricePlus && article.minPrice > 0) ? '+ ' : ''}{$tC && formatCurrency(article.minPrice)}){/if}
                    </BrandButton>
                  {/if}
                {/if}
              </BrandButtonRow>
            {/if}
          </div>
        </div>
      {/if}
    {/each}
  </main>

  {#if group.effectiveSelectionMode !== 'single'}
    <div class="ok-button-container">
      <BrandButton class="is-fullwidth is-primary" on:click={() => dispatch('close')}><Check /> {$tC({ de: 'Fertig', en: 'Done' })}</BrandButton>
    </div>
  {/if}
</ModalDialog>
