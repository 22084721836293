<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Dialog, Toast } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { apiCall } from '../lib/api'
  import counterBundle, { updateCounterBundle } from '../stores/counterBundle'
  import uri from 'uri-tag'
  import dialogs from '../stores/dialogs'
  import { SUSPENDED_QUEUE_INDEX } from '../../shared/lib/misc'
  import { withProcessingOverlay } from '../lib/utils'
  import QueueItemTableSelectionDialog from './QueueItemTableSelectionDialog.svelte'

  export let queueItemId

  $: queueItem = $counterBundle.queuedSessions.find(s => s.id === queueItemId)

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('close')
  }

  $: if (!queueItem) close()

  async function queueItemAction (action) {
    if (action === 'cancel') {
      if (!await Dialog.confirm({
        message: $tE({ de: 'Wollen Sie diese Reservierung wirklich stornieren?', en: 'Do you really want to cancel this reservation?' }),
        type: 'is-warning',
        icon: 'exclamation-triangle',
        confirmText: $tE({ de: 'Ja', en: 'Yes' }),
        cancelText: $tE({ de: 'Nein', en: 'No' }),
        size: 'is-large'
      })) return
    }

    await withProcessingOverlay(async () => {
      await apiCall('POST', uri`/api/app/queueItemAction/${queueItem.id}/${action}`)
      await updateCounterBundle()

      Toast.create({ message: $tE({ de: 'Aktion erfolgreich!', en: 'Operation successful!' }), type: 'is-success' })
      close()
    })
  }

  async function assignTable () {
    const tableId = await dialogs.open(QueueItemTableSelectionDialog)
    if (tableId && tableId !== queueItem.table?.id) {
      await withProcessingOverlay(async () => {
        if (queueItem.table) await apiCall('POST', uri`/api/app/tableAction/${queueItem.table.id}/release`)
        await apiCall('POST', uri`/api/app/queueItemAction/${queueItem.id}/prioritize`)
        await apiCall('POST', uri`/api/app/tableAction/${tableId}/release`)
        await updateCounterBundle()

        Toast.create({ message: $tE({ de: 'Aktion erfolgreich!', en: 'Operation successful!' }), type: 'is-success' })
        close()
      })
    }
  }
</script>

<style lang="scss">
</style>

<ModalDialog --width="95vw" class="employee-ui">
  {#if queueItem}
    <main>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title is-1">{$tE({ de: 'Wartender Kunde', en: 'Waiting Customer' })} {queueItem ? (queueItem.userData.phoneNumber ? `**${queueItem.userData.phoneNumber.slice(-3)}` : `W${queueItem.queueNo}`) : '---'}</h1>
          </div>
        </div>
        <div class="level-right"></div>
      </div>

      <div class="big-buttons">
        <Button class="big-button" type="is-dark" on:click={close} iconLeft="arrow-left">{$tE({ de: 'Zurück', en: 'Back' })}</Button>

        {#if queueItem.queueIndex !== $counterBundle.queuedSessions.find(s => s.queueIndex !== SUSPENDED_QUEUE_INDEX)?.queueIndex}
          <Button class="big-button" type="is-secondary" on:click={() => queueItemAction('prioritize')} iconLeft="fast-forward">{$tE({ de: 'Vorreihen', en: 'Prioritize' })}</Button>
        {/if}

        {#if queueItem.table}
          <Button class="big-button" type="is-secondary" on:click={() => queueItemAction('extend')} iconLeft="clock">{$tE({ de: 'Zeit verlängern', en: 'Extend Time' })}</Button>
        {/if}

        {#if queueItem.table || queueItem.queueIndex !== SUSPENDED_QUEUE_INDEX}
          <Button class="big-button" type="is-secondary" on:click={() => queueItemAction('sendToCounter')} iconLeft="cash-register">{$tE({ de: 'Zur Theke schicken', en: 'Send to Counter' })}</Button>
        {/if}

        <Button class="big-button" type="is-secondary" on:click={() => assignTable()} iconLeft="user-check">{$tE({ de: 'Tisch zuweisen', en: 'Assign Table' })}</Button>

        <Button class="big-button" type="is-danger" on:click={() => queueItemAction('cancel')} iconLeft="trash">{$tE({ de: 'Stornieren', en: 'Cancel' })}</Button>
      </div>
    </main>
  {/if}
</ModalDialog>
