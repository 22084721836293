<script>
  import { tC } from '../stores/i18n'
  import ModalDialog from './ModalDialog.svelte'
  import { createEventDispatcher } from 'svelte'
  import { doZoomImage, formatCurrency, getMediaUrl, handleOverscroll } from '../lib/utils'
  import ArticleDetails from './ArticleDetails.svelte'
  import BrandButton from './BrandButton.svelte'
  import Plus from 'svelte-feather/components/Plus.svelte'
  import Check from 'svelte-feather/components/Check.svelte'

  // Note: This dialog is designed to be used directly within a component and not via the dialogs store so that live update still works.

  export let article
  export let button
  export let showPricePlus = false

  const dispatch = createEventDispatcher()
</script>

<style lang="scss">
  main {
    display: grid;

    grid-template-columns: calc(55vw - 16px);
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "details"
      "button";

    &.has-image {
      grid-template-columns: calc(40vw - 16px) calc(55vw - 16px);
      grid-template-rows: 1fr auto;
      grid-template-areas:
        "image details"
        "image button";
    }

    .product-image {
      grid-area: image;
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 100%;
      cursor: zoom-in;

      background: var(--bg) no-repeat center center;
      background-size: contain;

      box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

      z-index: 1;
    }

    .article-container {
      grid-area: details;
      max-height: calc(95vh - 180px);
      width: 100%;
      min-width: 0;
      min-height: 0;
      background: $field-bg;

      overflow: hidden;
      hyphens: auto;

      position: relative;

      $fade-out-height: 4rem;

      &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $fade-out-height;
        background: linear-gradient(to bottom,
          rgba($field-bg, 0) 0%,
          rgba($field-bg, 0.005) 2%,
          rgba($field-bg, 0.01) 4%,
          rgba($field-bg, 0.02) 6%,
          rgba($field-bg, 0.035) 8%,
          rgba($field-bg, 0.05) 10%,
          rgba($field-bg, 0.125) 12%,
          rgba($field-bg, 0.25) 14%,
          rgba($field-bg, 0.4) 16%,
          rgba($field-bg, 0.6) 20%,
          rgba($field-bg, 0.75) 30%,
          rgba($field-bg, 0.9) 40%,
          rgba($field-bg, 0.95) 50%,
          rgba($field-bg, 1) 75%,
          rgba($field-bg, 1) 100%
        );

        background-position-y: calc($fade-out-height * 0.25);
        background-repeat: no-repeat;

        pointer-events: none;
      }

      article {
        width: 100%;
        height: 100%;
        padding: $default-margin $default-margin calc($fade-out-height * 0.75);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    .button-container {
      background: $field-bg;
      grid-area: button;
      padding: 0 $default-margin $default-margin;
    }

    &:not(:has(.button-container)) article {
      padding-bottom: $default-margin;
    }
  }
</style>

<ModalDialog noPadding title={$tC({ de: 'Details', en: 'Details' })} closeable on:close>
  <main class:has-image={!!article?.image}>
    {#if article.image}
      <div class="product-image" style:--bg={article.image ? `url("${getMediaUrl(article.image, 'large')}")` : 'transparent'} {...!article.available ? { 'data-unavailable': $tC({ de: 'Nicht verfügbar', en: 'Not Available' }) } : {}} on:click={e => doZoomImage(e, getMediaUrl(article.image, 'large')) } />
    {/if}
    <div class="article-container">
      <article use:handleOverscroll class:is-unavailable={article && !article?.available} on:scroll={e => e.target.closest('article').querySelectorAll('.ripple-container').forEach(container => container.remove())}>
        <ArticleDetails articleId={article?.id} mode="subArticle" />
      </article>
    </div>
    {#if button && article}
      <div class="button-container">
        <BrandButton class="is-fullwidth" on:click={() => { dispatch('select'); dispatch('close', true) }} disabled={!article.available}>
          {#if button === 'add'}
            <Plus /> {$tC({ de: 'Hinzufügen', en: 'Add' })} {#if article.minPrice}({(showPricePlus && article.minPrice > 0) ? '+ ' : ''}{$tC && formatCurrency(article.minPrice)}){/if}
          {:else}
            <Check /> {$tC({ de: 'Auswählen', en: 'Select' })} {#if article.minPrice}({(showPricePlus && article.minPrice > 0) ? '+ ' : ''}{$tC && formatCurrency(article.minPrice)}){/if}
          {/if}
        </BrandButton>
      </div>
    {/if}
  </main>
</ModalDialog>
