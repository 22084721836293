<script>
  import { onDestroy, onMount } from 'svelte'
  import ErrorBox from '../components/ErrorBox.svelte'
  import { apiCall, selfUserInfo } from '../lib/api'
  import { tE } from '../stores/i18n'
  import { table } from '../stores/table'
  import { bundle } from '../stores/bundle'
  import EmployeeLanguageSelector from '../components/EmployeeLanguageSelector.svelte'
  import BatteryStatusAndDeviceId from '../components/BatteryStatusAndDeviceId.svelte'
  import { Button, Icon, Dialog, Toast } from 'svelma-fixed'
  import { recordEmployeeActivity } from '../lib/employeeMenu'
  import dialogs from '../stores/dialogs'
  import EmployeeLockScreen from '../components/EmployeeLockScreen.svelte'
  import { ageVerificationRequests, counterBundle, tablesBlockedInPos, updateCounterBundle } from '../stores/counterBundle'
  import TableActionDialog from '../components/TableActionDialog.svelte'
  import PayAtCounterDialog from '../components/PayAtCounterDialog.svelte'
  import { getLedTargetSetting, getSignalColor } from '../../shared/lib/ledColors'
  import { getCartTotal } from '../stores/session'
  import { formatCurrency } from '../lib/utils'
  import { brightness } from '../stores/yaoKiosk'
  import { createLoadingStore } from '../stores/loading'
  import html from 'html-template-tag'
  import RemoveReadyReceiptDialog from '../components/RemoveReadyReceiptDialog.svelte'
  import SystemStatusDialog from '../components/SystemStatusDialog.svelte'
  import TablesBlockedInPosWarning from '../components/TablesBlockedInPosWarning.svelte'
  import ArticleManagementDialog from '../components/ArticleManagementDialog.svelte'
  import OrderManagementDialog from '../components/OrderManagementDialog.svelte'
  import QueueManagementDialog from '../components/QueueManagementDialog.svelte'
  import AgeVerificationRequest from '../components/AgeVerificationRequest.svelte'
  import { SUSPENDED_QUEUE_INDEX, getProcessNumber } from '../../shared/lib/misc'

  const submittingReadyReceipt = createLoadingStore()
  const removingReadyReceipt = createLoadingStore()

  onMount(async () => {
    document.documentElement.classList.add('employee-font-size-override')
  })

  onDestroy(() => {
    document.documentElement.classList.remove('employee-font-size-override')
  })

  const noAutoLock = new URLSearchParams(location.search).has('noAutoLock')

  function lock () {
    if (!$bundle.settings.employeeAccessCode) return
    if (!$dialogs.some(dialog => dialog.Component === EmployeeLockScreen)) dialogs.open(EmployeeLockScreen)
  }

  if (!noAutoLock) lock()

  let tableType = 'regular'

  const statusColors = {
    free: 'hsl(153, 53%,  53%)', // success
    occupied: 'hsl(48, 100%, 49%)', // warning
    cleaning: '#27509b', // primary
    disabled: 'black',
    blocked: 'hsl(348, 86%, 61%)', // danger
    reserved: 'hsl(0, 0%, 48%)' // grey
  }

  $brightness = 100

  async function submitReadyReceipt () {
    await submittingReadyReceipt(async () => {
      const receiptNumber = await Dialog.prompt({
        title: $tE({ de: 'Bestellung fertig', en: 'Order Ready' }),
        message: $tE({ de: 'Bestellnummer eingeben:', en: 'Enter order number:' }),
        icon: 'check-square',
        type: 'is-success',
        confirmText: $tE({ de: 'OK', en: 'OK' }),
        cancelText: $tE({ de: 'Abbrechen', en: 'Cancel' }),
        inputType: 'number',
        size: 'is-large'
      })

      if (receiptNumber) {
        try {
          const { smsError } = await apiCall('POST', '/api/app/submitReadyReceipt', { receiptNumber })

          await updateCounterBundle().catch(e => {})

          Toast.create({ message: $tE({ de: 'Bestellung als fertig markiert.', en: 'Order marked as ready.' }), type: 'is-success' })
          if (smsError) {
            Toast.create({ message: $tE({ de: 'SMS konnte nicht gesendet werden!', en: 'SMS could not be sent!' }), type: 'is-danger' })
          }
        } catch (e) {
          Dialog.alert({
            message: html`
              <p class="mb-3">
                ${$tE({ de: 'Fehler beim Ausführen der Aktion:', en: 'Error executing action:' })}
              </p>
              <p>
                ${e.serverErrorMessage ?? e.message}
              </p>
            `,
            type: 'is-danger',
            icon: 'exclamation-circle'
          })
        }
      }
    })
  }

  async function removeReadyReceipt () {
    await removingReadyReceipt(async () => {
      const receiptNumber = await dialogs.open(RemoveReadyReceiptDialog)

      if (receiptNumber) {
        try {
          await apiCall('POST', '/api/app/removeReadyReceipt', { receiptNumber })

          await updateCounterBundle().catch(e => {})

          Toast.create({ message: $tE({ de: 'Bestellung als abgeholt markiert.', en: 'Order marked as collected.' }), type: 'is-success' })
        } catch (e) {
          Dialog.alert({
            message: html`
              <p class="mb-3">
                ${$tE({ de: 'Fehler beim Ausführen der Aktion:', en: 'Error executing action:' })}
              </p>
              <p>
                ${e.serverErrorMessage ?? e.message}
              </p>
            `,
            type: 'is-danger',
            icon: 'exclamation-circle'
          })
        }
      }
    })
  }

  async function systemStatus () {
    await dialogs.open(SystemStatusDialog)
  }

  async function manageArticles () {
    await dialogs.open(ArticleManagementDialog)
  }

  async function manageOrders () {
    await dialogs.open(OrderManagementDialog)
  }

  async function manageQueue () {
    await dialogs.open(QueueManagementDialog)
  }

  $: unavailableArticlesCount = [
    ...Object.values($bundle.articleGroups).filter(group => !group.available),
    ...Object.values($bundle.articles).filter(article => !article.available)
  ].length
</script>

<style lang="scss">
  main {
    display: grid;
    width: 100%;
    height: 100%;

    grid-template-columns: 45fr 27.5fr 27.5fr;
    grid-template-rows: 3.25rem 1fr;
    justify-content: center;
    align-items: center;

    column-gap: $thin-margin;

    grid-template-areas:
      "tables-header pac-header options-header"
      "tables pac options";

    > * {
      min-width: 0;
      min-height: 0;
    }

    :global(.is-off) {
      opacity: 0.25;
    }

    .big-buttons {
      justify-content: center;
      max-width: 100%;

      :global(.big-button) {
        width: 100%;
      }
    }

    .tables-header {
      grid-area: tables-header;

      padding: $thin-margin;
    }

    .pac-header {
      grid-area: pac-header;

      padding: $thin-margin;
    }

    .options-header {
      grid-area: options-header;

      padding: 7px;
    }

    .tables {
      grid-area: tables;

      padding: $thin-margin;
      width: 100%;
      height: 100%;
      overflow-y: auto;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: min-content;
      gap: 0.5rem;

      button {
        width: 100%;
        height: 5rem;

        padding: 0.5rem 1rem;
        position: relative;

        display: grid;
        grid-template-columns: 1fr 1fr 1.5fr;
        grid-template-rows: 4fr 1fr;
        grid-template-areas:
          "table-number warning battery-icon"
          "status status status";
        justify-content: center;
        align-items: center;

        font-size: 2rem;

        :global(.battery-container) {
          font-size: 1rem;
        }

        > * {
          min-width: 0;
          min-height: 0;
        }

        &.red-bg {
          background-color: mix($danger, white, 25%);
        }

        .table-number {
          grid-area: table-number;
        }

        .warning {
          grid-area: warning;
        }

        .battery-icon {
          grid-area: battery-icon;
        }

        .status {
          grid-area: status;
          font-size: 0.75rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:not(:has(:first-child))::after { // :empty doesn't work because text nodes with spaces get created
            content: 'OK';
          }

          > *:not(:last-child)::after {
            content: ' • ';
          }
        }

        .lamp {
          position: absolute;
          right: 0.5rem;
          bottom: 0.5rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          border: 1px solid $grey;
          background: var(--color, transparent);

          @keyframes blink {
            0% {
              background-color: grey;
            }
            50% {
              background-color: var(--color, grey);
            }
            100% {
              background-color: grey
            }
          }

          &.blink {
            animation: blink 1s infinite;
          }
        }

        .bar {
          position: absolute;
          left: 0;
          top: 0;
          width: 0.5rem;
          height: 100%;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;

          background: var(--color, transparent);

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 50%;
            border-bottom-left-radius: 4px;

            background: var(--second-color, transparent);
          }
        }
      }
    }

    .pac {
      grid-area: pac;

      padding: $thin-margin;
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.5rem;

      .pac-items {
        overflow-y: auto;

        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        gap: 0.5rem;
      }

      button {
        width: 100%;
        height: 5rem;

        padding: 0.5rem 1rem;
        position: relative;

        display: grid;
        grid-template-columns: 1fr 1.5fr 1fr;
        grid-template-areas:
          "table-number amount process-number";
        justify-content: center;
        align-items: center;

        font-size: 2rem;

        > * {
          min-width: 0;
          min-height: 0;
        }

        .table-number {
          grid-area: table-number;
        }

        .amount {
          grid-area: amount;
          font-size: 1rem;
        }

        .process-number {
          grid-area: process-number;
        }
      }
    }

    .options {
      grid-area: options;

      padding: $thin-margin;

      display: flex;
      justify-content: end;
      align-items: end;
      width: 100%;
      height: 100%;

      h4 {
        width: 100%;
        height: 1.5em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 2rem;
      }
    }
  }
</style>

<svelte:body on:touchstart|passive={() => recordEmployeeActivity()} on:click|passive={e => { if (!e.sourceCapabilities?.firesTouchEvents) recordEmployeeActivity() }} />

<svelte:window on:employeeMenuTimeout={() => { if (!noAutoLock) lock() }} />

{#if selfUserInfo.role !== 'employee'}
  <ErrorBox text="{$tE({ de: 'Sie haben keinen Zugriff auf diese Seite.', en: 'You do not have access to this page.' })}" />
{:else if $table}
  <ErrorBox text="{$tE({ de: 'Dieses Gerät ist aktuell einem Tisch zugewiesen. Um das Theken-Portal zu verwenden, löschen Sie bitte die Zuordnung zuerst.', en: 'This device is currently assigned to a table. To use the counter portal, please delete the assignment first.' })}" />
{:else}
  <main class="employee-ui">
    <div class="tables-header">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">{$tE({ de: 'Tische', en: 'Tables' })}</h4>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <Button type={tableType === 'regular' ? 'is-dark' : (Object.values($counterBundle.tables).some(table => table.type === 'regular' && table.status !== 'disabled' && (!table.deviceId || new Date(table.deviceLastSeen).valueOf() < Date.now() - window.appVariables.configParams.deviceLastSeenTimeout)) ? 'is-danger' : 'is-light')} disabled={tableType === 'regular'} on:click={() => (tableType = 'regular')}>{$tE({ de: 'Tisch', en: 'Table' })}</Button>
            <Button type={tableType === 'kiosk' ? 'is-dark' : (Object.values($counterBundle.tables).some(table => table.type === 'kiosk' && table.status !== 'disabled' && (!table.deviceId || new Date(table.deviceLastSeen).valueOf() < Date.now() - window.appVariables.configParams.deviceLastSeenTimeout)) ? 'is-danger' : 'is-light')} disabled={tableType === 'kiosk'} on:click={() => (tableType = 'kiosk')}>{$tE({ de: 'Kiosk', en: 'Kiosk' })}</Button>
          </div>
        </div>
      </div>
    </div>

    <div class="pac-header">
      <h4 class="title is-4">{$tE({ de: 'An Theke bezahlen', en: 'Pay At Counter' })}</h4>
    </div>

    <div class="options-header">
      <div class="level m-0">
        <div class="level-left">
          <Button type="is-secondary" disabled={!$bundle.settings.employeeAccessCode} on:click={lock}><Icon icon="lock" /></Button>
        </div>
        <div class="level-right">
          <BatteryStatusAndDeviceId class="level-item" />
          <div class="level-item">
            <EmployeeLanguageSelector />
          </div>
        </div>
      </div>
    </div>

    <div class="tables">
      {#each Object.values($counterBundle.tables).filter(t => t.type === tableType) as table (table.id)}
        <button class="button is-secondary" class:red-bg={table.status !== 'disabled' && (!table.deviceId || new Date(table.deviceLastSeen).valueOf() < Date.now() - window.appVariables.configParams.deviceLastSeenTimeout)} class:is-off={['free', 'blocked', 'disabled'].includes(table.status) && $bundle.settings.powerSwitch.mode === 'off'} on:click={() => dialogs.open(TableActionDialog, { tableId: table.id })}>
          <div class="table-number">
            {table.number}
          </div>
          <div class="battery-icon">
            {#if table.deviceId}
              <BatteryStatusAndDeviceId batteryStatus={{ level: table.batteryLevel, isPlugged: table.batteryCharging }} deviceId={table.deviceId} />
            {:else}
              <i class="fa fa-ban has-text-danger"></i>
            {/if}
          </div>
          <div class="warning">
            {#if table.status === 'occupied' && new Date(table.currentSession?.lastActivityTime).valueOf() < Date.now() - (table.currentSession?.orders?.length ? 1800000 : 120000)}
              <i class="fa fa-clock {table.currentSession?.orders?.length ? 'has-text-warning' : 'has-text-danger'}"></i>
            {:else if table.status === 'occupied' && !table.currentSession}
              <i class="fa fa-question-circle has-text-info"></i>
            {/if}
          </div>
          <div class="status">
            {#if !table.deviceId}
              <span class="has-text-danger">{$tE({ de: 'KEIN GERÄT', en: 'NO DEVICE' })}</span>
            {/if}
            {#if table.batteryLevel != null && table.batteryLevel < 10}
              <span class="has-text-danger">{$tE({ de: 'AKKU', en: 'BATTERY' })}</span>
            {/if}
            {#if table.deviceId && new Date(table.deviceLastSeen).valueOf() < Date.now() - window.appVariables.configParams.deviceLastSeenTimeout}
              <span class="has-text-danger">{$tE({ de: 'OFFLINE', en: 'OFFLINE' })}</span>
            {/if}
            {#if table.type !== 'kiosk' && table.ledLampStatus !== 'OK'}
              <span class="has-text-danger">{$tE({ de: 'LED', en: 'LED' })}</span>
            {/if}
            {#if table.currentSession?.uiState?.currentView === 'payment' && table.currentSession?.uiState?.paymentState === 'fatalError'}
              <span class="has-text-danger">{$tE({ de: 'ZAHL-FEHLER', en: 'PAY-ERR' })}</span>
            {/if}
          </div>
          <div class="bar" style:--color={statusColors[table.status] ?? 'transparent'} style:--second-color={table.blockQueued ? statusColors.blocked : 'transparent'}></div>
          {#if table.type !== 'kiosk'}
            <div class="lamp" style:--color={getSignalColor(getLedTargetSetting(table, $bundle.settings))} class:blink={getLedTargetSetting(table).startsWith('!') && window.appVariables.configParams.enableBlinkingLed}></div>
          {/if}
        </button>
      {:else}
        {$tE({ de: 'Keine Tische.', en: 'No tables.' })}
      {/each}
    </div>

    <div class="pac">
      <div class="pac-items">
        {#each $counterBundle.payAtCounterSessions as session (session.id)}
          <button class="button is-success" on:click={() => dialogs.open(PayAtCounterDialog, { sessionId: session.id })}>
            <div class="table-number">
              {session.table.number}
            </div>
            <div class="amount">
              {$tE && formatCurrency(getCartTotal(session.cart, $bundle), true)}
            </div>
            <div class="process-number" class:has-text-light-grey={session.table.type !== 'kiosk'}>
              {getProcessNumber(session.id)}
            </div>
          </button>
        {:else}
          {$tE({ de: 'Keine wartenden Bestellungen.', en: 'No pending orders.' })}
        {/each}
      </div>

      <div class="big-buttons">
        {#if $bundle.settings.powerSwitch.tableQueueEnabled || $counterBundle.queuedSessions.length}
          <Button class="big-button semi-big {($counterBundle.queuedSessions.length === 0 && $bundle.settings.powerSwitch.mode === 'off') ? 'is-off' : ''}" type={$counterBundle.queuedSessions.some(s => !s.table) && $counterBundle.queueStats.availableTableCount > 0 ? 'is-danger' : ($counterBundle.queuedSessions.some(s => s.queueIndex === SUSPENDED_QUEUE_INDEX && !s.table) ? 'is-warning' : 'is-secondary')} on:click={manageQueue} iconLeft="users">
            {$tE({ de: 'Warteschlange', en: 'Table Queue' })} (<strong class:has-text-info={$counterBundle.queuedSessions.length > 0} class:has-text-grey={$counterBundle.queuedSessions.length === 0}>{$counterBundle.queuedSessions.length}</strong>)
            {#if $counterBundle.queueStats.availableTableCount === 0 && $counterBundle.queueStats.totalWaitingDuration > 0}
              <br />{$tE({ de: 'ca.', en: 'approx.' })} <strong>{$tE({ de: '{n} Min.', en: '{n} min.' }, { n: Math.round($counterBundle.queueStats.totalWaitingDuration) })}</strong>
            {/if}
          </Button>
        {/if}
        <Button class="big-button semi-big" type="is-secondary" on:click={manageOrders} iconLeft="shopping-cart">{$tE({ de: 'Bestellungen', en: 'Orders' })}</Button>
        <Button class="big-button semi-big" type="is-secondary" on:click={manageArticles} iconLeft="utensils">
          {$tE({ de: 'Artikel', en: 'Articles' })}
          {#if unavailableArticlesCount > 0}
            <br />[<strong class="has-text-danger">{unavailableArticlesCount} OFF</strong>]
          {/if}
        </Button>
      </div>
    </div>

    <div class="options">
      <div class="big-buttons">
        <h4>{$counterBundle.readyReceiptNumbers.map(r => r.receiptNumber).join(', ')}</h4>
        <Button class="big-button" type="is-success" on:click={submitReadyReceipt} iconLeft="check-square" disabled={$submittingReadyReceipt}>{$tE({ de: 'Bestellung fertig', en: 'Order Ready' })}</Button>
        <Button class="big-button" type="is-secondary" disabled={!$counterBundle.readyReceiptNumbers?.length || $removingReadyReceipt} on:click={removeReadyReceipt} iconLeft="shopping-bag">{$tE({ de: 'Bestellung abgeholt', en: 'Order Collected' })}</Button>
        <Button class="big-button semi-big" type="is-secondary" on:click={systemStatus} iconLeft="power-off">{$tE({ de: 'System-Status', en: 'System Status' })}<br />[<strong class="{{ on: 'has-text-success', off: 'has-text-danger', event: 'has-text-info' }[$bundle.settings.powerSwitch.mode]}">{$bundle.settings.powerSwitch.mode.toUpperCase()}</strong>]</Button>
      </div>
    </div>
  </main>
{/if}

{#if $ageVerificationRequests.length}
  <AgeVerificationRequest sessionId={$ageVerificationRequests[0]} />
{/if}

{#if $tablesBlockedInPos.length}
  <TablesBlockedInPosWarning tables={$tablesBlockedInPos} />
{/if}
