<script>
  import MobileTooltip from './MobileTooltip.svelte'

  export let level = null
  export let charging = false

  $: barColor = (level === 100 && charging) ? 'blue' : level > 35 ? 'green' : level > 15 ? 'orange' : 'red'
  $: value = level == null ? 0 : (level + '%')
  $: text = level == null ? '?' : level <= 5 ? '×' : ''
</script>

<style lang="scss">
  .battery-icon {
    font-size: var(--size, inherit);
    box-sizing: border-box;
    display: inline-block;
    height: 1em;
    width: 2em;
    margin: 0 0.2em;
    border: 0.1em solid var(--border-color, black);
    background: var(--background-color, white);
    border-radius: 0.2em;
    position: relative;
    padding: 0.1em;
    padding-right: 0.1em;

    &::before {
      content: '';
      display: block;
      background: var(--bar-color, var(--border-color, black));
      width: var(--value, 100%);
      height: 100%;
      border-radius: 0.1em;
    }

    &::after {
      content: '';
      background: var(--border-color, black);
      position: absolute;
      left: 100%;
      width: 0.2em;
      top: 20%;
      height: 60%;
      border-top-right-radius: 0.1em;
      border-bottom-right-radius: 0.1em;
    }

    span, i {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      font-weight: bold;
      color: var(--border-color, black);
    }

    span {
      font-size: 0.75em;
      line-height: 1.2;
    }

    i {
      font-size: 0.95em;
      line-height: 0.95;
    }
  }
</style>

<MobileTooltip label={(level ?? '?') + '%'} position="is-left">
  <div class="battery-icon" style:--bar-color={barColor} style:--value={value}>
    {#if charging}
      <i class="fas fa-bolt"></i>
    {:else}
      <span>{text}</span>
    {/if}
  </div>
</MobileTooltip>
