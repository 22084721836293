<script>
  /* eslint-disable svelte/no-at-html-tags */

  import html from 'html-template-tag'
  import { Text } from 'slate'
  import { doZoomImage, getMediaUrl, isRichTextNodeEmpty, isVideo } from '../lib/utils'
  import { tC } from '../stores/i18n'
  import BrandButton from './BrandButton.svelte'
  import Image from 'svelte-feather/components/Image.svelte'
  import Video from 'svelte-feather/components/Video.svelte'
  import dialogs from '../stores/dialogs'

  export let node
  export let isChild = false
  export let trimStart = false
  export let trimEnd = false
  export let narrow = false

  function renderTextNode (node) {
    let text = node.text
    if (trimStart) text = text.trimStart()
    if (trimEnd) text = text.trimEnd()
    text = html`<span class="text">${text}</span>`
    if (node.bold) text = `<strong>${text}</strong>`
    if (node.code) text = `<code>${text}</code>`
    if (node.italic) text = `<em>${text}</em>`
    if (node.underline) text = `<u>${text}</u>`
    if (node.strikethrough) text = `<s>${text}</s>`
    if (!node.text) text = '<br />'
    return text
  }

  function handleVideo (element) {
    let initialPlayTriggered = false

    // Pause video when it gets covered by another element or is scrolled out of the view
    const observer = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting && initialPlayTriggered) {
        element.pause()
      }
    }, { threshold: 0.5 })

    observer.observe(element)

    // Pause it immediately after autoplay started, to load initial frame thumbnail
    element.addEventListener('play', () => {
      initialPlayTriggered = true
      element.pause()
    }, { once: true })

    dialogs.subscribe(() => {
      if (initialPlayTriggered) element.pause()
    })
  }

  function trimEmptyChildren (children) {
    children = [...children ?? []]

    // Trim leading empty children
    while (children.length && isRichTextNodeEmpty(children[0])) {
      children.shift()
    }

    // Trim trailing empty children
    while (children.length && isRichTextNodeEmpty(children[children.length - 1])) {
      children.pop()
    }

    return children
  }
</script>

<style lang="scss">
  main {
    hyphens: auto;
    display: block;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    :global(u) {
      text-decoration: underline;
    }

    :global(span.text) {
      white-space: pre-wrap;
    }
  }

  p.image-block {
    text-align: center;
    line-height: 1;
    clear: both;

    img, video {
      width: 100%;
      height: auto;
      max-height: 50vh;
      object-fit: contain;
    }

    img {
      transform: scale(1.15);
      cursor: pointer;
    }

    video::-webkit-media-controls-fullscreen-button {
      display: none;
    }

    label {
      font-size: 0.75em;
    }
  }

  :global(.rich-text-node:not(.is-narrow) p.image-block.float) {
    float: right;
    margin: 1em;
    margin-top: 0;
    margin-right: 0;
    width: 40%;
  }
</style>

{#if node}
  {#if !isChild}
    <main class="content rich-text-node" class:is-narrow={narrow}>{#each Object.entries(trimEmptyChildren(node.children)) as [i, child]}<svelte:self node={child} isChild trimStart={+i === 0} trimEnd={+i === node.children.length - 1} />{/each}</main>
  {:else if Text.isText(node)}
    {@html renderTextNode(node)}
  {:else if ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'ul', 'ol', 'li'].includes(node.type)}
    <svelte:element this={node.type} style:text-align={node.textAlign}>{#each node.children ?? [] as child}<svelte:self node={child} isChild />{/each}</svelte:element>
  {:else if node.type === 'link'}
    <a style:text-align={node.textAlign} href={node.url} target="_blank" rel="nofollow noopener">{#each node.children ?? [] as child}<svelte:self node={child} isChild />{/each}</a>
  {:else if node.type === 'upload' && node.value?.filename}
    {#if (node.fields?.display ?? 'overlay') !== 'overlay'}
      <p class="image-block" class:float={node.fields?.display === 'float'}>
        {#if isVideo(node.value.filename)}
          <video controls controlsList="nofullscreen nodownload noremoteplayback" disablepictureinpicture preload="metadata" autoplay use:handleVideo>
            <source src={getMediaUrl(node.value, 'large')} type="video/mp4">
            <label>{$tC({ de: 'Ihr Browser unterstützt keine Videos', en: 'Your browser does not support videos' })}</label>
          </video>
        {:else}
          <img src={getMediaUrl(node.value, 'large')} alt={node.fields?.label ?? $tC({ de: 'Bild', en: 'Image' })} on:click={e => doZoomImage(e, getMediaUrl(node.value, 'large'))} />
        {/if}
        {#if node.fields?.label}
          <label>{node.fields.label}</label>
        {/if}
      </p>
    {:else}
      <p>
        <BrandButton class="is-fullwidth" on:click={e => doZoomImage(e, getMediaUrl(node.value, 'large'))}>
          {#if isVideo(node.value.filename)}
            <Video />
            {node.fields?.label ?? $tC({ de: 'Video zeigen', en: 'Show Video' })}
          {:else}
            <Image />
            {node.fields?.label ?? $tC({ de: 'Bild zeigen', en: 'Show Image' })}
          {/if}
        </BrandButton>
      </p>
    {/if}
  {:else}
    <p style:text-align={node.textAlign}>{#each Object.entries(node.children) ?? [] as [i, child]}<svelte:self node={child} isChild trimStart={trimStart && +i === 0} trimEnd={trimEnd && +i === node.children.length - 1} />{/each}</p>
  {/if}
{/if}
