<script>
  import { tC } from '../stores/i18n'
  import ModalDialog from './ModalDialog.svelte'
  import BrandButton from './BrandButton.svelte'
  import { createEventDispatcher } from 'svelte'
  import HelpCircle from 'svelte-feather/components/HelpCircle.svelte'
  import { lastActivityTimestamp } from '../stores/session'
  import { second } from '../stores/timer'

  const dispatch = createEventDispatcher()

  $: timeRemaining = Math.max(0, $second && Math.round(($lastActivityTimestamp + 90000 - Date.now()) / 1000))
</script>

<style lang="scss">
  main {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    :global(svg) {
      width: 3rem;
      height: 3rem;
      stroke-width: 1;
    }

    span {
      white-space: pre-wrap;
    }
  }
</style>

<ModalDialog title={$tC({ de: 'Sind Sie noch da?', en: 'Are you still there?' })} closeable on:close>
  <main class="mb-4">
    <span class="has-text-primary"><HelpCircle /></span>

    <span>{$tC({ de: 'Brauchen Sie noch etwas Zeit für Ihre Bestellung?', en: 'Do you need more time for your order?' })}</span>
  </main>

  <div class="has-text-right">
    <BrandButton class="is-primary" on:click={() => dispatch('close', true)}>{$tC({ de: 'Ja, ich brauche noch mehr Zeit', en: 'Yes, I need more time' })}</BrandButton>
    <BrandButton on:click={() => dispatch('close', false)}>{$tC({ de: 'Abbruch / Neuer Kunde', en: 'Cancel / New Customer' })} ({timeRemaining})</BrandButton>
  </div>
</ModalDialog>
