<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import counterBundle, { updateCounterBundle } from '../stores/counterBundle'
  import { bundle } from '../stores/bundle'

  const dispatch = createEventDispatcher()
  const loading = createLoadingStore()

  function close () {
    dispatch('close')
  }

  async function setPowerSwitch (powerSwitch) {
    await loading(async () => {
      try {
        await apiCall('POST', '/api/app/setPowerSwitch', powerSwitch)

        await updateCounterBundle()
      } catch (e) {
        console.error('Error updating system status:', e)
        throw e
      }
    })
  }
</script>

<style lang="scss">
  hr {
    display: block;
    width: 100%;
    height: 1px;
  }
</style>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'System-Status', en: 'System Status' })}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <h1 class="title is-1">[<strong class="{{ on: 'has-text-success', off: 'has-text-danger', event: 'has-text-info' }[$bundle.settings.powerSwitch.mode]}">{$bundle.settings.powerSwitch.mode.toUpperCase()}</strong>]</h1>
        </div>
      </div>
    </div>

    <div class="big-buttons">
      <Button class="big-button" type="is-dark" on:click={close} iconLeft="arrow-left" disabled={$loading}>{$tE({ de: 'Zurück', en: 'Back' })}</Button>

      <Button class="big-button" type="is-success" on:click={() => setPowerSwitch({ mode: 'on' })} iconLeft="play" disabled={$loading || $bundle.settings.powerSwitch.mode === 'on'}>{$tE({ de: 'ON', en: 'ON' })}</Button>
      <Button class="big-button" type="is-danger" on:click={() => setPowerSwitch({ mode: 'off' })} iconLeft="pause" disabled={$loading || $bundle.settings.powerSwitch.mode === 'off'}>{$tE({ de: 'OFF', en: 'OFF' })}</Button>
      <Button class="big-button" type="is-info" on:click={() => setPowerSwitch({ mode: 'event' })} iconLeft="glass-cheers" disabled={$loading || $bundle.settings.powerSwitch.mode === 'event'}>{$tE({ de: 'EVENT', en: 'EVENT' })}</Button>

      {#if $bundle.settings.powerSwitch.mode === 'on'}
        <hr />
        <Button class="big-button" type="is-secondary" on:click={() => setPowerSwitch({ paymentsEnabled: !$bundle.settings.powerSwitch.paymentsEnabled })} iconLeft="credit-card" disabled={$loading}>{$tE({ de: 'Zahlungen', en: 'Payments' })} [{#if $bundle.settings.powerSwitch.paymentsEnabled}<strong class="has-text-success">ON</strong>{:else}<strong class="has-text-danger">OFF</strong>{/if}]</Button>
        <Button class="big-button" type="is-secondary" on:click={() => setPowerSwitch({ takeAwayEnabled: !$bundle.settings.powerSwitch.takeAwayEnabled })} iconLeft="shopping-bag" disabled={$loading}>{$tE({ de: 'Take-Away', en: 'Take-Away' })} [{#if $bundle.settings.powerSwitch.takeAwayEnabled}<strong class="has-text-success">ON</strong>{:else}<strong class="has-text-danger">OFF</strong>{/if}]</Button>
        <Button class="big-button" type="is-secondary" on:click={() => setPowerSwitch({ tableQueueEnabled: !$bundle.settings.powerSwitch.tableQueueEnabled })} iconLeft="users" disabled={$loading}>{$tE({ de: 'Warteschlange', en: 'Warteschlange' })} [{#if $bundle.settings.powerSwitch.tableQueueEnabled}<strong class="has-text-success">ON</strong>{:else}<strong class="has-text-danger">OFF</strong>{/if}]</Button>
        {#if Object.values($counterBundle.tables).some(table => table.robotPointId)}
          <Button class="big-button" type="is-secondary" on:click={() => setPowerSwitch({ robotsEnabled: !$bundle.settings.powerSwitch.robotsEnabled })} iconLeft="robot" disabled={$loading}>{$tE({ de: 'Robotersteuerung', en: 'Robot Control' })} [{#if $bundle.settings.powerSwitch.robotsEnabled}<strong class="has-text-success">ON</strong>{:else}<strong class="has-text-danger">OFF</strong>{/if}]</Button>
        {/if}
      {:else if $bundle.settings.powerSwitch.mode === 'event'}
        <hr />
        <h4 class="title is-4">{$tE({ de: 'EVENT-Modus muss im Admin-Panel konfiguriert werden.', en: 'EVENT mode must be configured in the admin panel.' })}</h4>
      {/if}
    </div>
  </main>
</ModalDialog>
