<script>
  import { Dialog, Field, Input, Button } from 'svelma-fixed'
  import { isYaoKiosk, deviceId, batteryStatus, executePayment, brightness } from '../stores/yaoKiosk'
  import html from 'html-template-tag'
  import { createLoadingStore } from '../stores/loading'
  import { reloadPage } from '../lib/utils'

  let paymentAmount = '11.21'

  async function onNfcTag (event) {
    await Dialog.alert({
      title: 'NFC Tag Detected',
      message: html`NFC Tag ID: <strong>${event.detail.id}</strong> (raw: <code>${event.detail.rawId}</code>)`,
      type: 'is-info'
    })
  }

  const paymentLoading = createLoadingStore(doPayment)

  async function doPayment () {
    await paymentLoading(async () => {
      try {
        const result = await executePayment(paymentAmount)
        console.log('Payment result:', result)

        if (result.error) {
          await Dialog.alert({
            title: 'Payment Error',
            message: html`<div style="white-space: pre-wrap; word-break: break-word;">${JSON.stringify(result, null, 2)}</pre>`,
            type: 'is-warning'
          })
        } else {
          await Dialog.alert({
            title: 'Payment Success',
            message: html`<div style="white-space: pre-wrap; word-break: break-word;">${JSON.stringify(result, null, 2)}</pre>`,
            type: 'is-success'
          })
        }
      } catch (e) {
        console.error('Payment error:', e)
        await Dialog.alert({
          title: 'Exception',
          message: html`${e}`,
          type: 'is-danger'
        })
      }
    })
  }

  async function onEmployeeMenu (event) {
    await Dialog.alert({
      title: 'Employee Menu',
      message: 'Employee menu is triggered',
      type: 'is-info'
    })
  }
</script>

<style lang="scss">
  .container {
    max-height: 100%;
    overflow: auto;
  }
</style>

<svelte:window on:nfcTag={onNfcTag} on:employeeMenu={onEmployeeMenu} />

<svelte:head>
  <title>Component Test - YaoOrder</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      Component Test Page
    </h4>

    <Button type="is-secondary" on:click={() => reloadPage(true)}>Reload</Button>

    <p>
      Is YaoKiosk: {isYaoKiosk}<br />
      Device ID: {deviceId}
    </p>

    <p>
      Battery Status: {JSON.stringify($batteryStatus)}
    </p>

    <hr />

    <div class="columns">
      <div class="column is-half">
        <h5 class="title is-5">Payment Test</h5>
        <form on:submit|preventDefault={doPayment}>
          <fieldset disabled={$paymentLoading}>
            <Field label="Payment Amount">
              <Input type="number" step="any" bind:value={paymentAmount} />
            </Field>
            <Button nativeType="submit" type="is-primary" loading={$paymentLoading}>Pay</Button>
          </fieldset>
        </form>
      </div>
      <div class="column is-half">
        <h5 class="title is-5">Brightness Control</h5>
        <Field label="Brightness">
          <Input type="range" min="0" max="100" bind:value={$brightness} />
        </Field>
      </div>
    </div>
  </div>
</div>
