<style lang="scss">
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    padding: $default-margin;
    z-index: 0;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .checkmark {
    opacity: 1;
    stroke: $success;
    stroke-dashoffset: -375;
    stroke-dasharray: 750;
    animation: dash 1.5s ease-out forwards 0.3s;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: -375;
      opacity: 1;
    }
    40% {
      stroke-dashoffset: -274;
      opacity: 1;
    }
    100% {
      stroke-dashoffset: 0;
      opacity: 0.15;
    }
  }
</style>

<div class="wrapper">
  <svg version="1.1" viewBox="0 0 98.5 98.5">
    <path
      class="checkmark"
      fill="none"
      stroke-width="8"
      stroke-miterlimit="10"
      d="M81.7,17.8C73.5,9.3,62,4,49.2,4C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"
    />
  </svg>
</div>
