<script>
  import { languages } from '../../payload/i18nConstants'
  import { customerLanguage, tC } from '../stores/i18n'
  import dialogs from '../stores/dialogs'
  import CustomerLanguageSelectorDialog from './CustomerLanguageSelectorDialog.svelte'
  import { rippleOnTouch } from '../lib/utils'

  $: currentLanguageObj = languages[$customerLanguage] ?? {}

  function onClick () {
    dialogs.open(CustomerLanguageSelectorDialog)
  }
</script>

<style lang="scss">
  main {
    color: var(--color, #{$text});
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 7px;
    z-index: 6;
    padding-left: 7px;
    cursor: pointer;

    :global(.ripple-container) {
      left: 7px;
      top: 7px;
      width: calc(100% - 7px);
      height: calc(100% - 7px);
      border-radius: 9999px;
    }

    div {
      display: block;
      padding: 0.5rem;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 9999px;
    }

    span {
      text-decoration: underline;
    }

    img {
      margin-left: 0.25em;
      width: 1.4em;
      height: 1em;
      vertical-align: middle;
      transform: translateY(-1px);
      object-fit: cover;
    }
  }
</style>

<main on:click={onClick} tabindex="0" use:rippleOnTouch>
  <div>
    <span>{$customerLanguage?.toUpperCase()}</span> <img src={currentLanguageObj._flag} alt={$tC(currentLanguageObj)} />
  </div>
</main>
