<script>
  import { reloadPage } from '../lib/utils'
  import BrandButton from './BrandButton.svelte'
  import RefreshCw from 'svelte-feather/components/RefreshCw.svelte'
</script>

<style lang="scss">
  main {
    position: fixed;
    top: -1px;
    right: -1px;
    border-bottom-left-radius: 16px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 1;
    background: white;
    padding: 4px;

    display: flex;
    flex-direction: row;
    gap: 2px;

    span {
      color: $danger;

      font-size: 24px;
    }

    :global(.brand-button) {
      font-size: 10px;
    }

    :global(svg) {
      transform: scale(1.5) translateY(-1px) !important;
    }
  }
</style>

<main>
  <BrandButton icon on:click={() => reloadPage(true)}><RefreshCw /></BrandButton>
  <span>TEST</span>
</main>
