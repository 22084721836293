<script>
  import { tC } from '../stores/i18n'
  import BrandButton from './BrandButton.svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Field, Input, Switch } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import Send from 'svelte-feather/components/Send.svelte'

  const dispatch = createEventDispatcher()

  export let email
  let formEl

  export let marketingConsentEmail

  $: normalizedEmail = (email ?? '').toLowerCase().trim()

  function submit () {
    dispatch('close', { email: normalizedEmail, marketingConsentEmail })
  }

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
</script>

<style lang="scss">
  form {
    font-size: 1.5rem;

    :global(.control) {
      font-size: 2rem;
      width: 100%;
      position: relative;

      :global(input) {
        font-size: 1em;
      }
    }
  }
</style>

<ModalDialog --width="40rem" --min-width="40rem" class="dialog-top-half" title={$tC({ de: 'E-Mail-Adresse eingeben', en: 'Enter Email Address' })} closeable on:close on:introend={formEl.querySelector('input').focus()}>
  <form on:submit|preventDefault={submit} bind:this={formEl}>
    <Field>
      <Input expanded type="email" bind:value={email} step="any" />
    </Field>

    <Field>
      <Switch bind:checked={marketingConsentEmail}>{$tC({ de: 'Ja, schicken Sie mir bitte hin und wieder auch Neuigkeiten und interessante Angebote per E-Mail.', en: 'Yes, please also send me news and interesting offers via email once in a while.' })}</Switch>
    </Field>

    <BrandButton type="submit" class="is-fullwidth is-primary" disabled={!normalizedEmail?.match(emailRegex)}><Send /> {$tC({ de: 'Abschicken', en: 'Send' })}</BrandButton>
  </form>
</ModalDialog>
