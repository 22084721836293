import { writable } from 'svelte/store'
import AsyncLock from 'async-lock'
import { apiCall, selfUserInfo } from '../lib/api'

const lock = new AsyncLock()

export const counterBundle = writable(null)
export default counterBundle

export let currentCounterBundle
counterBundle.subscribe($counterBundle => {
  if (!window.$yo) window.$yo = {}
  window.$yo.counterBundle = $counterBundle

  currentCounterBundle = $counterBundle
})

window.addEventListener('backendWsMessage', event => {
  if (event.detail.type === 'updateCounterBundle' && selfUserInfo?.role === 'employee' && !lock.isBusy('updateCounterBundle')) {
    updateCounterBundle()
  } else if (event.detail.type === 'updateTableBlockedInPos') {
    tablesBlockedInPos.updateTable(event.detail.tableNumber, event.detail.blocked)
  } else if (event.detail.type === 'updateAgeVerificationRequest') {
    ageVerificationRequests.updateRequest(event.detail.sessionId, event.detail.verificationRequired)
  }
})

let autoUpdateInterval

export async function updateCounterBundle () {
  await lock.acquire('updateCounterBundle', async () => {
    console.log('Updating counter bundle')
    const newBundle = await apiCall('GET', '/api/app/counterBundle')
    if (typeof newBundle === 'string') {
      throw new Error('Bad bundle')
    }
    counterBundle.set(newBundle)
  }, { timeout: 5000 })

  if (!autoUpdateInterval) {
    autoUpdateInterval = setInterval(updateCounterBundle, 10000)
  }
}

export const tablesBlockedInPos = writable([])

const tablesBlockedInPosTimeouts = {}
tablesBlockedInPos.updateTable = (tableNumber, blocked) => {
  if (blocked) {
    tablesBlockedInPos.update(tables => [...new Set([...tables, tableNumber])])
  } else {
    tablesBlockedInPos.update(tables => tables.filter(t => t !== tableNumber))
  }

  if (tablesBlockedInPosTimeouts[tableNumber]) clearTimeout(tablesBlockedInPosTimeouts[tableNumber])
  if (blocked) {
    tablesBlockedInPosTimeouts[tableNumber] = setTimeout(() => {
      tablesBlockedInPos.update(tables => tables.filter(t => t !== tableNumber))
    }, 15000)
  }
}

export const ageVerificationRequests = writable([])
ageVerificationRequests.updateRequest = (sessionId, verificationRequired) => {
  const table = Object.values(currentCounterBundle.tables).find(table => table.currentSession?.id === sessionId)
  if (verificationRequired && table && table?.type !== 'kiosk') {
    ageVerificationRequests.update(sessions => [...new Set([...sessions, sessionId])])
  } else {
    ageVerificationRequests.update(sessions => sessions.filter(t => t !== sessionId))
  }
}

counterBundle.subscribe($counterBundle => {
  if (!$counterBundle) return
  const activeSessionIds = Object.values($counterBundle.tables).map(table => table.currentSession?.id).filter(Boolean)
  ageVerificationRequests.update(sessions => sessions.filter(sessionId => activeSessionIds.includes(sessionId)))
})
