<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Input } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { bundle } from '../stores/bundle'
  import { escapeRegexp, formatCurrency } from '../lib/utils'

  export let group

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('close')
  }

  $: menuGroups = $bundle.categories.filter(category => !category.id.startsWith('_'))

  let searchValue = ''

  function isMatching (needle, haystack) {
    return new RegExp('\\b' + escapeRegexp(needle.trim()), 'i').test(haystack)
  }
</script>

<style lang="scss">
  h4 {
    margin: $thin-margin 0 !important;
  }

  .group-level {
    margin-top: $thin-margin;
    margin-bottom: 0 !important;
    min-height: 3.5rem;
  }

  :global(.article-selection-dialog):has(input.search-input:focus) {
    padding-bottom: 50vh;
  }
</style>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui no-auto-top-half article-selection-dialog">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Hinzufügen zu {n}', en: 'Add to {n}' }, { n: $tE(group.adminName) })}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <Button class="big-button semi-big tight" type="is-dark" on:click={close} iconLeft="arrow-left">{$tE({ de: 'Zurück', en: 'Back' })}</Button>
        </div>
      </div>
    </div>

    <Input expanded class="search-input" type="search" bind:value={searchValue} placeholder={$tE({ de: 'Suchen...', en: 'Search...' })} icon="search" />

    {#each menuGroups.filter(menuGroup => menuGroup !== group && (!searchValue.trim() || isMatching(searchValue, $tE(menuGroup.adminName)) || menuGroup.articles?.some(article => isMatching(searchValue, $tE(article.adminName))))) as menuGroup}
      <div class="level group-level">
        <div class="level-left">
          <div class="level-item">
            <h4 class="title is-4">
              {$tE(menuGroup.adminName)}
            </h4>
          </div>
        </div>
        <div class="level-right"></div>
      </div>
      <div class="big-buttons">
        {#each menuGroup.articles.filter(article => !searchValue.trim() || isMatching(searchValue, $tE(article.adminName))) as article}
          {#if article.hasProblems}
            <Button class="big-button semi-big" type="is-danger" disabled>{$tE(article.adminName)}<br />{$tE({ de: 'UNGÜLTIG!', en: 'INVALID!' })}</Button>
          {:else}
            <Button class="big-button semi-big" type="is-secondary" disabled={group.articles.includes(article)} on:click={() => dispatch('close', article)}>{$tE(article.adminName)}<br />{$tE && formatCurrency(article.posArticle?.price, true)}</Button>
          {/if}
        {/each}
      </div>
    {:else}
      <p>{$tE({ de: 'Keine passenden Gruppen gefunden!', en: 'No matching groups found!' })}</p>
    {/each}
  </main>
</ModalDialog>
