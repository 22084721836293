<script>
  import { tC } from '../stores/i18n'
  import BrandButton from './BrandButton.svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Field, Input, Switch } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import Send from 'svelte-feather/components/Send.svelte'
  import ArrowRight from 'svelte-feather/components/ArrowRight.svelte'
  import Check from 'svelte-feather/components/Check.svelte'
  import dialogs from '../stores/dialogs'
  import MessageDialog from './MessageDialog.svelte'
  import HelpCircle from 'svelte-feather/components/HelpCircle.svelte'
  import table from '../stores/table'

  const dispatch = createEventDispatcher()

  export let phoneNumber
  let formEl

  export let marketingConsentSms

  export let forPickup = false
  export let forQueue = false

  let confirmedNoTable = false

  $: normalizedPhoneNumber = (phoneNumber ?? '').replace(/[^\d+]/g, '').replace(/^00/, '+').replace(/^(?=6)/, '0').replace(/^0(?=[1-9])/, window.appVariables.configParams.localization.phonePrefix).replace(/^(?=\d)/, '+')

  let prevAvailableTableCount = forQueue ? $table.queueStats.availableTableCount : null
  $: if (forQueue && prevAvailableTableCount !== $table.queueStats.availableTableCount) {
    if (prevAvailableTableCount === 0 && $table.queueStats.availableTableCount > 0) dispatch('close')
    prevAvailableTableCount = $table.queueStats.availableTableCount
  }

  let closeInterval = null
  $: if (forQueue && phoneNumber !== null) { // It is never null, but I use this to bind the code into the condition
    if (closeInterval) clearTimeout(closeInterval)
    closeInterval = setTimeout(() => dispatch('close'), 30000)
  }

  async function submit () {
    // Some people enter a German mobile phone number with prefix 01 even though the system may be in Austria. Since 01 in Austria is always a Viennese landline number, we ask the user if they meant to enter a German mobile phone number instead.
    if (normalizedPhoneNumber.startsWith('+431')) {
      const result = await dialogs.open(MessageDialog, { title: $tC({ de: 'Frage', en: 'Question' }), text: $tC({ de: 'Ist das eine deutsche Handynummer?', en: 'Is this a German cell phone number?' }), iconClass: 'has-text-primary', Icon: HelpCircle, yesNo: true })
      if (result === true) {
        normalizedPhoneNumber = phoneNumber = normalizedPhoneNumber.replace(/^\+43/, '+49')
      } else if (result == null) {
        return
      }
    }

    if ((normalizedPhoneNumber.startsWith('+43') && (!normalizedPhoneNumber.startsWith('+436') || normalizedPhoneNumber.match(/^\+43(662|646|6[123])/))) || (normalizedPhoneNumber.startsWith('+49') && !normalizedPhoneNumber.startsWith('+491')) || (normalizedPhoneNumber.startsWith('+1') && normalizedPhoneNumber.length !== 12)) {
      const result = await dialogs.open(MessageDialog, { title: $tC({ de: 'Frage', en: 'Question' }), text: $tC({ de: 'Die angegebene Nummer {n} scheint keine gültige Handynummer zu sein. Sind Sie sicher, dass diese Nummer korrekt ist?', en: 'The number {n} you entered does not appear to be a valid cell phone number. Are you sure this number is correct?' }).replace('{n}', normalizedPhoneNumber), iconClass: 'has-text-primary', Icon: HelpCircle, yesNo: true })
      if (!result) return
    }

    dispatch('close', { phoneNumber: normalizedPhoneNumber, marketingConsentSms })
  }
</script>

<style lang="scss">
  form {
    font-size: 1.5rem;

    :global(.control) {
      font-size: 2rem;
      width: 100%;
      position: relative;

      :global(input) {
        font-size: 1em;
      }
    }
  }
</style>

<ModalDialog --width="40rem" --min-width="40rem" class="dialog-top-half" title={$tC({ de: 'Telefonnummer eingeben', en: 'Enter Phone Number' })} closeable on:close on:introend={formEl.querySelector('input')?.focus()}>
  <form on:submit|preventDefault={submit} bind:this={formEl}>
    {#if forQueue && $table.queueStats.availableTableCount > 0 && !confirmedNoTable}
      <h4 class="title is-4">{$tC({ de: 'Sind Sie sicher, dass momentan keiner der grün leuchtenden Tische frei ist?', en: 'Are you sure that none of the green-lit tables are free right now?' })}</h4>

      <BrandButton type="submit" class="is-fullwidth" on:click={() => { confirmedNoTable = true; setTimeout(() => formEl.querySelector('input')?.focus(), 150) }}>{$tC({ de: 'Kein Tisch frei, jetzt reservieren', en: 'No table available, reserve now' })}</BrandButton>
      <BrandButton type="submit" class="is-fullwidth mt-3" on:click={() => dispatch('close')}>{$tC({ de: 'Ich habe doch einen freien Tisch gefunden', en: 'I have found a free table after all' })}</BrandButton>
    {:else}
      {#if forPickup}
        <h4 class="title is-4">{$tC({ de: 'Wenn Sie Ihre Handynummer angeben, erhalten Sie eine SMS-Benachrichtigung, sobald Ihre Bestellung fertig ist!', en: 'If you provide your mobile number, you will receive a text message notification as soon as your order is ready!' })}</h4>
      {:else if forQueue}
        <h4 class="title is-4">{$tC({ de: 'Sobald ein Tisch frei wird, bekommen Sie ihn zugewiesen und erhalten dafür eine SMS-Benachrichtigung!', en: 'As soon as a table becomes available, you will be assigned to it and receive a text message notification!' })}</h4>
        <h4 class="title is-4">{$tC({ de: 'Handynummer:', en: 'Phone Number:' })}</h4>
      {/if}

      <Field>
        <Input expanded type="tel" bind:value={phoneNumber} step="any" placeholder="{window.appVariables.configParams.localization.phonePrefix} ..." />
      </Field>

      <Field>
        <Switch bind:checked={marketingConsentSms}>{$tC({ de: 'Ja, schicken Sie mir bitte hin und wieder auch Neuigkeiten und interessante Angebote per SMS.', en: 'Yes, please also send me news and interesting offers via SMS once in a while.' })}</Switch>
      </Field>

      {#if forPickup}
        <BrandButton type="submit" class="is-fullwidth is-primary" disabled={normalizedPhoneNumber.length < 10}><ArrowRight /> {$tC({ de: 'Speichern & Weiter', en: 'Save & Continue' })}</BrandButton>
        <hr />
        <BrandButton type="button" class="is-fullwidth" on:click={() => dispatch('close', false)}>{$tC({ de: 'Ohne SMS-Benachrichtigung fortfahren', en: 'Continue without text message notification' })}</BrandButton>
      {:else if forQueue}
        <BrandButton type="submit" class="is-fullwidth is-primary" disabled={normalizedPhoneNumber.length < 10}><Check /> {$tC({ de: 'Eintragen', en: 'Register' })}</BrandButton>
        <hr />
        <p>
          {$tC({ de: 'Wenn Sie keine Handynummer angeben möchten, wenden Sie sich bitte an einen Mitarbeiter bei der Theke, um sich für die Tisch-Warteschlange registrieren zu lassen.', en: 'If you do not want to provide a mobile number, please contact a staff member at the counter to register for the table queue.' })}
        </p>
      {:else}
        <BrandButton type="submit" class="is-fullwidth is-primary" disabled={normalizedPhoneNumber.length < 10}><Send /> {$tC({ de: 'Abschicken', en: 'Send' })}</BrandButton>
      {/if}
    {/if}
  </form>
</ModalDialog>
