<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('close')
  }

  let id
</script>

<!-- Note: This intentionally doesn't close on employeeMenuTimeout as it doesn't do anything bad and may be useful to be open longer -->
<svelte:window on:nfcTag={e => { id = e.detail.id }} />

<ModalDialog --width="95vw" class="employee-ui">
  <main>
    <h1 class="title is-1">{$tE({ de: 'MA-Karten-Test', en: 'Employee Card Test' })}</h1>

    <Button class="big-button" type="is-dark" on:click={close} iconLeft="arrow-left">{$tE({ de: 'Zurück', en: 'Back' })}</Button>

    <h3 class="title is-3 mx-3 is-inline-block">
      {#if !id}
        {$tE({ de: 'Karte hinhalten um ID zu testen...', en: 'Tap card to test ID...' })}
      {:else}
        {$tE({ de: 'Karten-ID:', en: 'Card ID:' })} <strong class="has-text-success">{id}</strong>
      {/if}
    </h3>
  </main>
</ModalDialog>
