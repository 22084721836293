<script>
  export let icon = 'frown'
  export let text = 'An error occured'
  export let error = null

  import MiddleBox from './MiddleBox.svelte'
  import { Icon } from 'svelma-fixed'
</script>

<MiddleBox class="has-text-centered notification is-danger">
  <p class="m-3"><Icon {icon} size="is-large" /></p>
  <h1 class="title">{text}</h1>
  <h2 class="subtitle">
    <slot />
    {#if error}
      {error.serverErrorMessage ?? error.message}
    {/if}
  </h2>
  {#if error}
    <a class="button is-danger is-inverted is-outlined" href="/">Back to Homepage</a>
  {/if}
</MiddleBox>
