<script>
  import MobileTooltip from './MobileTooltip.svelte'
  import { tC } from '../stores/i18n'
  import Chili from './icons/Chili.svelte'

  export let spiciness = 0
  export let withTooltip = false

  const spicinessLabels = {
    1: { de: 'Leicht scharf', en: 'Mildly spicy' },
    2: { de: 'Mittelscharf', en: 'Medium spicy' },
    3: { de: 'Sehr scharf', en: 'Very spicy' },
    4: { de: 'Extrem scharf', en: 'Extremely spicy' }
  }
</script>

<style lang="scss">
  .spiciness {
    color: $danger;
    display: inline-block;
    margin: 0 0.075em;

    :global(svg) {
      margin: 0 -0.075em;
    }
  }
</style>

{#if spiciness >= 1}
  {#if withTooltip}
    <MobileTooltip label={$tC(spicinessLabels[spiciness])}><span class="spiciness">{#each Array(Number(spiciness)) as _}<Chili />{/each}</span></MobileTooltip>
  {:else}
    <span class="spiciness">{#each Array(Number(spiciness)) as _}<Chili />{/each}</span>
  {/if}
{/if}
