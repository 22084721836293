<svg
  xmlns="http://www.w3.org/2000/svg"
  version="1.0"
  viewBox="0 0 524.000000 400.000000"
  preserveAspectRatio="xMidYMid meet"
>
  <g
    transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
    fill="currentColor"
    stroke="none"
  >
    <path
      d="M2569 3930 c-102 -17 -207 -99 -251 -198 -16 -34 -22 -68 -22 -127 -1 -72 3 -88 32 -147 34 -69 89 -126 150 -156 l32 -16 0 -213 0 -213 -577 0 c-329 0 -617 -5 -667 -10 -170 -20 -294 -79 -411 -195 -123 -123 -178 -245 -197 -437 l-11 -106 -181 -4 c-174 -3 -183 -4 -238 -31 -64 -32 -111 -80 -142 -146 -20 -45 -21 -59 -21 -506 0 -498 -1 -486 56 -571 29 -43 82 -82 139 -104 21 -8 106 -15 215 -19 l180 -6 7 -65 c15 -149 80 -282 193 -395 117 -116 248 -178 414 -196 113 -11 2600 -11 2711 1 47 5 114 18 150 29 227 71 417 289 452 521 19 124 -1 110 157 110 206 0 280 19 351 90 20 20 47 58 60 85 l25 50 0 465 0 465 -28 57 c-32 64 -80 111 -146 142 -40 18 -70 21 -222 24 l-175 4 -11 106 c-20 192 -75 314 -198 437 -117 116 -241 175 -411 195 -50 5 -338 10 -666 10 l-578 0 0 213 0 212 45 24 c58 31 110 89 144 160 34 74 36 191 4 263 -62 137 -217 222 -364 198z m1503 -1260 c160 -49 289 -180 341 -345 22 -70 22 -73 22 -865 0 -793 0 -795 -22 -865 -52 -165 -181 -296 -342 -345 -61 -19 -100 -20 -1446 -20 -1346 0 -1385 1 -1446 20 -158 48 -291 180 -339 338 -19 62 -20 94 -20 872 0 778 1 810 20 872 48 157 179 288 338 339 54 17 130 18 1443 18 1356 1 1388 1 1451 -19z m-3422 -1251 l0 -531 -162 4 c-178 3 -208 11 -244 65 -18 26 -19 52 -19 465 0 426 1 437 21 465 40 55 73 63 247 63 l157 0 0 -531z m4289 513 c19 -9 44 -30 55 -45 20 -28 21 -39 21 -465 0 -413 -1 -439 -19 -465 -36 -53 -66 -61 -238 -65 l-158 -4 0 531 0 531 153 0 c125 0 158 -3 186 -18z"
    />
    <path
      d="M1641 2095 c-55 -18 -142 -77 -174 -118 -117 -154 -111 -355 15 -492 212 -233 597 -117 647 194 19 118 -15 226 -98 314 -75 79 -144 110 -256 114 -59 2 -105 -2 -134 -12z m233 -175 c62 -42 90 -93 94 -170 5 -80 -17 -131 -75 -179 -89 -74 -200 -67 -284 18 -79 78 -88 175 -26 268 46 68 103 96 188 91 50 -3 75 -10 103 -28z"
    />
    <path
      d="M3390 2101 c-226 -74 -334 -312 -237 -524 111 -242 434 -290 616 -92 68 75 94 145 95 255 1 75 -3 93 -31 152 -38 81 -102 146 -180 185 -47 23 -72 28 -147 30 -50 1 -102 -1 -116 -6z m222 -187 c114 -80 128 -227 29 -325 -84 -85 -195 -92 -284 -18 -52 43 -77 96 -77 164 0 67 17 110 58 151 50 51 89 65 164 62 56 -3 75 -9 110 -34z"
    />
    <path
      d="M2045 1001 c-55 -23 -76 -101 -39 -142 58 -64 270 -175 401 -211 110 -29 316 -29 425 0 110 30 215 76 315 138 102 63 123 85 123 126 0 40 -15 65 -50 83 -49 25 -91 19 -159 -25 -311 -201 -546 -207 -832 -22 -97 63 -133 73 -184 53z"
    />
  </g>
</svg>
