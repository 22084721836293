<script>
  import { languages } from '../../payload/i18nConstants'
  import { employeeLanguage, tE } from '../stores/i18n'
  import { Button } from 'svelma-fixed'
</script>

<style lang="scss">
  main {
    display: inline-flex;
    justify-content: center;

    img {
      width: 2em;
      height: 1.5em;
      object-fit: contain;
    }
  }
</style>

<main>
  {#each Object.entries(languages) as [lang, obj]}
    <Button type={$employeeLanguage === lang ? 'is-dark' : 'is-light'} disabled={$employeeLanguage === lang} on:click={() => ($employeeLanguage = lang)}>
      <img src={obj._flag} alt={$tE(obj)} />
    </Button>
  {/each}
</main>
