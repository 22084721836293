<script>
  import { fly } from 'svelte/transition'
  import { createEventDispatcher, onDestroy, onMount, tick } from 'svelte'
  import { tE } from '../stores/i18n'
  import { bundle } from '../stores/bundle'
  import { Button, Input } from 'svelma-fixed'
  import BatteryStatusAndDeviceId from './BatteryStatusAndDeviceId.svelte'
  import { closeEmployeeMenu } from '../lib/employeeMenu'
  import { brightness } from '../stores/yaoKiosk'
  import { autoFocusInput, appendToBody } from '../lib/utils'

  const dispatch = createEventDispatcher()

  let password = ''

  $: isAlphanumeric = !!$bundle.settings.employeeAccessCode.match(/\D/)
  $: if (password === $bundle.settings.employeeAccessCode) dispatch('close')

  let mainEl

  function onEmployeeMenu () {
    tick().then(closeEmployeeMenu)
    dispatch('close')
  }

  function onKeyPress (e) {
    if (!isAlphanumeric && document.elementFromPoint(0, 0) === mainEl && !document.elementsFromPoint(0, 0).some(el => el !== mainEl && el.style.zIndex > 9999)) {
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
        password += e.key
        e.preventDefault()
      } else if (e.key === 'Backspace') {
        password = password.slice(0, -1)
        e.preventDefault()
      } else if (e.key === 'Escape') {
        password = ''
        e.preventDefault()
      }
    }
  }

  let oldBrightness
  onMount(() => {
    oldBrightness = $brightness
    $brightness = 25
  })

  onDestroy(() => {
    $brightness = oldBrightness
  })
</script>

<style lang="scss">
  main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background: black;
    color: white;
    font-size: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 900;

    :global(input) {
      font-size: 2rem;
      margin: 2rem;
      text-align: center;

      background: black;
      color: white;
      border-color: white;
      width: auto;
    }

    .input-container {
      display: flex;
      justify-content: center;
      align-items: start;
      height: 55vh;
    }

    .pin-display {
      height: 2em;
      margin: 1em;
    }

    .pin-entry {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5em;

      :global(button.is-inverted.is-outlined.is-dark) {
        font-size: 2rem;
        border-radius: 50%;
        width: 5rem;
        height: 5rem;

        &:active, &:focus {
          background-color: black;
          color: white;
        }

        &:active {
          animation: fade 0.5s;
        }

        @keyframes fade {
          0% {
            background-color: white;
            color: black;
          }
          100% {
            background-color: black;
            color: white;
          }

        }
      }
    }

    .ui-overlay {
      position: absolute;
      top: 7px;
      right: 7px;
      color: white;
      font-size: 1rem;
    }

  }
</style>

<svelte:window on:employeeMenu={onEmployeeMenu} on:keypress={onKeyPress} />

<main transition:fly={{ y: 30 }} use:appendToBody bind:this={mainEl}>
  <div class="ui-overlay">
    <BatteryStatusAndDeviceId --border-color="white" --background-color="black" />
  </div>

  <div>{$tE({ de: 'Gerät gesperrt', en: 'Device locked' })}</div>
  {#if isAlphanumeric}
    <div class="input-container" use:autoFocusInput={{ delay: 500 }}><Input bind:value={password} type="password" /></div>
  {:else}
    <div class="pin-display">{'• '.repeat(password.length).trim()}</div>
    <div class="pin-entry">
      {#each Array.from({ length: 9 }, (_, i) => i + 1) as number}
        <Button type="is-dark" inverted outlined on:click={() => (password += String(number))}>{number}</Button>
      {/each}
      <Button type="is-dark" inverted outlined on:click={() => (password = password.slice(0, -1))}>←</Button>
      <Button type="is-dark" inverted outlined on:click={() => (password += '0')}>0</Button>
      <Button type="is-dark" inverted outlined on:click={() => (password = '')}>C</Button>
    </div>
  {/if}
</main>
