<svg xmlns='http://www.w3.org/2000/svg' version='1.0' width='100%' viewBox='0 0 4000.000000 400.000000' preserveAspectRatio='xMidYMid meet'>
  <g transform='translate(0.000000,400.000000) scale(0.100000,-0.100000)' fill='currentColor' stroke='none'>
    <path d='M19790 2984 c-174 -16 -441 -75 -586 -129 -237 -89 -404 -270 -464 -503 -19 -75 -21 -103 -18 -468 3 -382 3 -390 27 -448 49 -121 175 -215 403 -300 117 -43 128 -44 128 -10 0 16 13 39 35 61 38 38 41 43 25 43 -20 0 -204 71 -286 110 -99 47 -147 97 -169 174 -14 48 -16 108 -13 418 3 361 4 363 29 429 71 185 174 286 363 356 396 148 900 162 1314 37 243 -74 357 -153 436 -304 67 -128 69 -140 74 -512 2 -228 0 -354 -8 -391 -14 -70 -58 -140 -107 -170 -53 -32 -182 -89 -278 -123 -44 -15 -82 -29 -84 -31 -2 -1 8 -12 22 -23 15 -12 32 -39 38 -61 7 -21 18 -39 26 -39 21 0 178 63 275 110 143 69 210 142 249 271 19 63 20 88 17 445 -4 364 -5 382 -27 449 -56 171 -178 328 -319 412 -109 65 -371 144 -592 178 -101 16 -416 27 -510 19z' />
    <path d='M32718 2959 c-204 -17 -411 -107 -572 -250 -113 -100 -219 -277 -272 -454 -24 -80 -27 -107 -28 -240 -1 -165 15 -248 75 -389 115 -271 377 -487 672 -553 109 -25 318 -23 428 5 336 83 591 329 696 669 24 80 27 103 27 253 1 186 -15 269 -78 406 -69 149 -200 303 -336 394 -174 118 -401 176 -612 159z m199 -305 c101 -21 164 -46 245 -100 194 -129 298 -341 285 -583 -9 -173 -65 -299 -188 -422 -62 -63 -93 -85 -170 -122 -111 -54 -183 -71 -299 -70 -370 1 -669 319 -647 688 19 305 225 543 532 610 82 18 152 18 242 -1z' />
    <path d='M36732 2960 c-263 -37 -493 -171 -645 -375 -133 -179 -191 -357 -190 -580 3 -635 630 -1090 1237 -898 254 80 458 259 576 504 67 141 83 219 84 404 1 139 -2 170 -22 240 -57 195 -177 380 -322 496 -94 76 -268 162 -379 188 -100 24 -251 33 -339 21z m314 -324 c246 -79 419 -287 454 -547 26 -192 -49 -402 -193 -541 -220 -214 -537 -253 -797 -98 -138 81 -255 239 -297 400 -23 87 -22 244 1 330 98 363 475 569 832 456z' />
    <path d='M28856 2940 c-40 -12 -63 -36 -81 -84 -25 -69 -225 -1142 -225 -1209 1 -233 149 -457 382 -576 l82 -42 128 128 128 128 -67 6 c-148 15 -263 91 -324 215 -56 113 -55 125 65 744 58 300 106 553 106 564 0 37 -43 96 -85 116 -44 21 -64 23 -109 10z' />
    <path d='M29515 2932 c-44 -21 -72 -56 -81 -100 -4 -22 60 -379 191 -1057 20 -106 19 -154 -5 -217 -11 -29 -20 -54 -20 -56 0 -12 109 78 185 153 124 121 128 128 115 196 -16 83 -157 845 -170 919 -13 73 -43 133 -76 155 -38 25 -95 28 -139 7z' />
    <path d='M34866 2941 c-3 -5 3 -19 13 -32 40 -52 83 -141 115 -242 41 -125 66 -167 136 -230 98 -88 257 -147 456 -169 138 -15 138 -15 129 75 -16 158 -75 287 -180 392 -112 113 -273 181 -480 205 -111 12 -182 13 -189 1z' />
    <path d='M30995 2925 c-374 -71 -625 -332 -734 -762 -52 -205 -75 -527 -47 -646 22 -95 55 -154 120 -215 108 -102 231 -137 451 -128 131 5 277 25 290 38 4 3 -3 36 -14 73 -13 40 -21 95 -21 135 0 38 -4 71 -8 74 -5 3 -46 -2 -93 -10 -105 -19 -271 -21 -327 -5 -23 6 -57 25 -75 41 l-32 28 1 144 c2 377 109 679 291 823 109 86 224 125 370 125 78 0 83 -1 87 -22 3 -13 7 -187 11 -388 l5 -365 38 -34 c20 -18 71 -55 112 -82 41 -26 89 -59 106 -73 18 -14 35 -26 38 -26 4 0 5 262 4 583 l-3 582 -22 31 c-46 64 -165 95 -365 93 -62 0 -144 -7 -183 -14z' />
    <path d='M34725 2923 c-58 -13 -159 -50 -226 -84 -304 -152 -500 -450 -516 -790 -9 -190 19 -324 105 -494 138 -276 420 -467 745 -505 283 -33 596 86 792 302 94 104 114 165 77 238 -24 49 -75 80 -129 80 -60 0 -91 -18 -158 -95 -60 -68 -158 -140 -238 -174 -63 -27 -183 -51 -254 -51 -233 1 -471 151 -571 360 -42 88 -72 206 -72 285 0 81 29 197 72 285 58 120 168 230 288 288 47 23 114 46 150 53 36 6 75 13 88 15 13 3 22 12 22 22 0 29 -64 175 -98 225 -33 46 -38 49 -77 40z' />
    <path d='M2026 2753 c-3 -15 -19 -107 -36 -203 -17 -96 -34 -185 -37 -197 l-5 -23 346 0 347 0 -6 -32 c-4 -18 -48 -267 -99 -553 l-92 -520 264 -3 c146 -1 267 0 269 2 3 3 47 242 99 533 51 290 96 538 99 551 l5 22 900 0 900 0 16 -25 c14 -21 14 -30 5 -54 -7 -16 -24 -33 -37 -40 -18 -8 -190 -11 -584 -11 l-560 0 -5 -22 c-3 -13 -43 -233 -88 -490 l-83 -468 267 0 c246 0 268 1 273 18 2 9 24 128 47 265 l43 247 146 0 146 0 116 -262 116 -263 579 -3 578 -2 340 502 c187 276 342 505 345 510 3 4 80 -222 170 -502 l164 -510 278 0 c153 0 278 3 278 8 0 4 -112 353 -249 777 l-249 770 -324 0 -323 0 -515 -762 c-283 -419 -517 -762 -520 -763 -4 0 -196 424 -232 511 -6 14 5 21 61 38 127 39 235 131 298 255 67 132 96 372 59 483 -36 108 -134 183 -286 219 -70 17 -178 18 -1647 21 l-1571 4 -6 -28z' />
    <path d='M7825 2758 c-8 -35 -75 -411 -75 -420 0 -4 252 -8 561 -8 367 0 567 -4 580 -10 30 -16 26 -73 -7 -99 -26 -21 -35 -21 -593 -21 l-566 0 -86 -483 c-47 -265 -84 -485 -82 -490 4 -6 533 -11 533 -4 0 1 20 118 45 260 25 142 45 260 45 262 0 1 168 5 373 8 414 5 465 12 594 72 165 77 268 229 303 447 37 229 -19 373 -172 443 -124 57 -139 58 -820 62 l-627 4 -6 -23z' />
    <path d='M9596 2768 c-6 -18 -76 -415 -76 -427 0 -8 176 -11 561 -11 367 0 567 -4 580 -10 30 -16 26 -73 -7 -99 -26 -21 -35 -21 -593 -21 l-567 0 -85 -482 c-47 -266 -84 -486 -82 -491 6 -11 521 -9 528 3 5 8 95 496 95 514 0 2 165 6 368 9 321 4 379 7 459 25 225 50 359 179 425 409 18 63 23 104 23 208 0 122 -2 133 -27 181 -61 115 -170 171 -380 194 -147 15 -1218 14 -1222 -2z' />
    <path d='M25285 2605 c-5 -2 -43 -14 -82 -25 l-73 -22 0 -119 0 -118 -72 -3 -73 -3 0 -65 0 -65 72 -3 72 -3 3 -327 c3 -320 4 -328 27 -380 31 -69 98 -128 170 -149 64 -18 188 -13 245 11 36 15 36 15 36 77 0 59 -1 61 -22 55 -59 -17 -144 -16 -192 1 -85 31 -86 35 -86 400 l0 312 128 3 127 3 6 60 c4 33 5 63 2 68 -2 4 -62 7 -134 7 l-129 0 0 145 c0 80 -3 145 -7 144 -5 0 -12 -2 -18 -4z' />
    <path d='M19710 2450 c-254 -27 -549 -99 -648 -160 -59 -36 -62 -55 -62 -406 0 -283 2 -319 18 -346 13 -23 48 -45 147 -93 502 -244 1121 -244 1622 1 164 80 153 48 153 452 l0 331 -30 35 c-51 58 -282 128 -560 171 -123 19 -513 28 -640 15z m-232 -312 c9 -9 12 -81 12 -263 0 -263 -2 -275 -44 -275 -50 1 -51 2 -54 268 -3 219 -1 252 13 266 19 19 56 21 73 4z m1100 0 c30 -30 13 -62 -88 -163 l-100 -99 100 -102 c103 -104 115 -127 81 -158 -32 -29 -63 -10 -185 113 -93 94 -116 123 -116 146 0 23 24 53 122 152 124 125 154 143 186 111z' />
    <path d='M14279 2322 c-205 -55 -311 -222 -311 -492 0 -297 123 -459 384 -507 131 -23 309 -9 421 36 26 10 27 14 27 77 0 59 -2 65 -17 59 -92 -35 -269 -52 -378 -35 -98 14 -156 39 -195 82 -30 33 -70 137 -70 184 l0 24 361 0 361 0 -4 153 c-3 143 -5 156 -32 215 -37 80 -122 161 -203 193 -80 31 -250 37 -344 11z m316 -152 c72 -37 105 -102 113 -216 l5 -84 -288 0 -287 0 4 68 c5 79 47 183 87 211 49 35 120 51 221 48 80 -2 104 -6 145 -27z' />
    <path d='M15315 2333 c-16 -2 -65 -13 -107 -25 l-78 -20 0 -69 c0 -38 4 -69 8 -69 5 0 43 9 84 19 104 27 269 37 351 21 119 -22 151 -59 163 -192 l7 -76 -239 -4 c-218 -3 -244 -6 -294 -26 -77 -30 -124 -72 -159 -144 -77 -154 -13 -328 147 -400 57 -26 239 -36 324 -19 63 13 167 60 187 84 21 26 28 21 35 -28 l7 -45 75 0 76 0 -4 373 c-3 356 -5 374 -26 427 -23 59 -84 126 -139 154 -61 30 -155 46 -273 44 -63 0 -128 -3 -145 -5z m425 -637 l0 -94 -50 -46 c-78 -69 -142 -91 -275 -90 -131 0 -179 17 -207 74 -33 64 -18 161 31 204 38 35 106 45 309 45 l192 1 0 -94z' />
    <path d='M16329 2325 c-86 -17 -139 -45 -181 -95 -41 -47 -58 -98 -58 -171 0 -68 26 -124 77 -171 49 -43 95 -65 265 -123 157 -54 184 -68 203 -105 19 -37 19 -103 0 -140 -22 -43 -75 -55 -230 -54 -117 1 -212 14 -277 39 -16 6 -18 -1 -18 -69 0 -74 1 -77 28 -87 86 -34 323 -49 433 -29 87 16 174 64 209 115 73 107 61 265 -27 346 -42 40 -138 85 -280 130 -71 23 -140 48 -155 55 -69 35 -78 160 -14 193 80 41 278 39 424 -5 21 -6 22 -4 22 69 l0 76 -37 10 c-117 32 -274 38 -384 16z' />
    <path d='M22285 2326 c-167 -41 -244 -136 -253 -307 -7 -136 28 -226 114 -291 l35 -26 -40 -22 c-69 -39 -106 -110 -99 -189 6 -66 39 -108 107 -139 54 -25 65 -26 291 -32 267 -6 283 -10 296 -76 8 -46 -7 -79 -48 -101 -29 -16 -59 -18 -243 -17 -201 0 -257 6 -372 40 -22 6 -23 4 -23 -64 l0 -71 43 -12 c163 -44 448 -53 583 -19 166 42 225 99 232 219 6 105 -38 175 -143 223 -46 21 -68 23 -285 29 -129 3 -236 6 -237 7 -38 35 -44 72 -17 110 8 12 34 32 57 44 37 19 60 21 197 22 137 2 162 4 214 25 73 29 130 79 164 145 38 74 44 210 12 283 -12 29 -30 59 -41 69 -10 9 -19 21 -19 26 0 4 38 8 85 8 74 0 85 2 85 18 0 9 3 34 6 54 l6 36 -193 6 c-107 3 -255 8 -329 10 -90 4 -152 1 -185 -8z m280 -121 c76 -20 104 -40 133 -95 17 -34 22 -61 22 -117 0 -96 -21 -148 -69 -172 -45 -23 -128 -41 -189 -41 -56 0 -157 25 -190 46 -78 51 -94 216 -29 311 48 71 192 101 322 68z' />
    <path d='M23370 2329 c-36 -5 -92 -17 -125 -27 l-60 -18 0 -69 c0 -55 3 -69 14 -67 8 2 40 10 70 18 79 21 264 33 341 23 129 -18 167 -59 177 -193 l6 -74 -239 -4 c-221 -4 -243 -6 -298 -27 -107 -43 -174 -135 -184 -257 -9 -123 53 -226 170 -280 60 -27 70 -29 198 -29 153 0 210 14 295 70 27 19 51 32 53 31 1 -2 6 -23 10 -47 l8 -44 69 0 70 0 0 370 c0 418 -2 435 -75 518 -86 97 -269 136 -500 106z m420 -638 l0 -100 -43 -35 c-24 -20 -70 -48 -103 -63 -53 -25 -70 -28 -169 -28 -171 0 -227 38 -226 153 0 144 53 170 349 171 l192 1 0 -99z' />
    <path d='M24405 2329 c-162 -27 -250 -106 -262 -236 -7 -78 9 -131 56 -185 46 -52 99 -78 304 -149 114 -39 147 -54 168 -79 57 -67 42 -166 -28 -198 -58 -26 -312 -23 -406 5 -80 24 -79 24 -75 -68 l3 -64 70 -19 c54 -14 107 -19 235 -20 146 -1 172 2 223 21 117 44 175 118 184 236 7 83 -9 135 -58 190 -49 56 -118 91 -294 147 -153 50 -189 70 -204 112 -30 85 5 137 105 158 71 15 234 7 314 -15 30 -8 56 -15 58 -15 1 0 2 34 2 75 l0 74 -37 10 c-108 28 -260 37 -358 20z' />
    <path d='M16953 2308 c3 -7 100 -226 216 -487 l211 -473 -36 -72 c-67 -134 -116 -163 -261 -153 l-83 6 0 -65 c0 -61 1 -65 28 -76 15 -6 72 -11 127 -12 86 -1 107 2 148 23 67 33 136 105 189 198 38 65 498 1098 498 1118 0 3 -39 4 -87 3 l-86 -3 -173 -389 c-96 -214 -175 -390 -177 -392 -1 -2 -79 174 -172 391 l-170 394 -89 1 c-67 0 -87 -3 -83 -12z' />
    <path d='M18536 2296 c-47 -10 -67 -21 -93 -49 l-33 -37 0 -225 0 -225 33 -35 c27 -29 44 -37 107 -50 41 -9 80 -13 87 -11 11 4 13 69 11 323 l-3 318 -25 2 c-14 1 -52 -4 -84 -11z' />
    <path d='M21301 2292 c-17 -32 -15 -593 2 -616 13 -18 17 -18 92 -3 63 13 86 23 110 47 l30 30 3 229 3 229 -31 36 c-32 36 -63 49 -152 61 -40 6 -48 4 -57 -13z' />
    <path d='M31166 1840 c-9 -27 -35 -173 -46 -259 -19 -146 3 -251 80 -370 65 -100 224 -202 368 -235 56 -13 64 -13 69 1 10 27 -6 346 -20 398 -34 123 -96 199 -237 290 -59 38 -118 87 -152 126 -39 43 -59 59 -62 49z' />
    <path d='M29908 1632 c-26 -37 -146 -136 -423 -351 -162 -127 -309 -249 -347 -290 -156 -169 -296 -427 -353 -653 -9 -35 -19 -71 -22 -82 -5 -17 -1 -18 31 -12 92 17 351 147 516 257 122 83 306 242 379 328 130 156 201 376 246 769 6 54 -4 67 -27 34z' />
    <path d='M19436 1179 c-53 -8 -91 -37 -99 -74 -2 -14 -1 -62 3 -107 5 -46 4 -122 -2 -170 -11 -104 -1 -134 52 -160 43 -22 153 -24 225 -4 53 14 122 46 167 78 27 18 27 20 13 48 -22 42 -21 217 1 248 15 21 15 25 -1 42 -9 10 -42 31 -73 48 -88 47 -195 66 -286 51z' />
    <path d='M20337 1169 c-49 -12 -134 -52 -171 -82 -28 -23 -28 -24 -12 -55 23 -44 23 -218 0 -251 -16 -23 -16 -25 7 -43 77 -60 188 -98 294 -98 136 0 170 40 155 189 -5 48 -5 118 1 166 14 127 -7 160 -112 175 -76 11 -111 11 -162 -1z' />
    <path d='M19911 1023 c-95 -48 -93 -181 4 -227 64 -31 136 -7 175 57 24 38 25 68 6 105 -38 72 -117 100 -185 65z' />
  </g>
</svg>
