import { derived, writable } from 'svelte/store'

export const customerLanguage = writable(window.appVariables.configParams.localization.defaultLocale)
export const employeeLanguage = writable(window.appVariables.configParams.localization.defaultLocale)

// This means these will "change" when language changes too, so we can use $tC(...) in components easily
export const tC = derived(customerLanguage, $customerLanguage => (object, params) => translate(object, params, false))
export const tE = derived(employeeLanguage, $employeeLanguage => (object, params) => translate(object, params, true))

export let currentCustomerLanguage
export let currentEmployeeLanguage

customerLanguage.subscribe($currentCustomerLanguage => {
  currentCustomerLanguage = $currentCustomerLanguage
  document.documentElement.lang = currentCustomerLanguage
})

employeeLanguage.subscribe($currentEmployeeLanguage => {
  currentEmployeeLanguage = $currentEmployeeLanguage
})

export function translate (object, params, isEmployee) {
  if ((!params || typeof params !== 'object') && isEmployee === undefined) {
    isEmployee = params
  }
  const language = isEmployee ? currentEmployeeLanguage : currentCustomerLanguage
  const localeObject = window.appVariables.configParams.localization.locales.find(locale => locale.code === language) ?? {}
  const fallbackLocale = localeObject.fallbackLocale ?? window.appVariables.configParams.localization.defaultLocale
  const result = (typeof object !== 'object' || !object) ? object : (object[language] ?? object[fallbackLocale])
  if (params && typeof result === 'string') {
    return result.replace(/\{([^}]+)\}/g, (match, key) => params[key] ?? match)
  } else {
    return result
  }
}
