<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Dialog } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { isTestTableMode, table } from '../stores/table'
  import Loader from './Loader.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import { batteryStatus } from '../stores/yaoKiosk'
  import { localState } from '../stores/localState'
  import { reloadPage } from '../lib/utils'

  const dispatch = createEventDispatcher()
  const loading = createLoadingStore()

  let now = Date.now()

  function close () {
    dispatch('close')
  }

  async function fetchTables () {
    try {
      now = Date.now()
      return await apiCall('GET', '/api/app/allTables')
    } catch (e) {
      console.error('Error fetching tables:', e)
      throw e
    }
  }

  async function assignTable (tableOption) {
    if (tableOption?.deviceLastSeen) {
      const message = (new Date(tableOption.deviceLastSeen).valueOf() >= now - window.appVariables.configParams.deviceLastSeenTimeout)
        ? $tE({ de: 'Dieser Tisch ist bereits einem anderen <strong>aktiven</strong> Gerät zugewiesen. Wenn Sie dieses Gerät dem gleichen Tisch zuweisen, wird die Verbindung zum vorigen Gerät getrennt. Sind Sie sicher?', en: 'This table is already assigned to another active device. If you assign this device to the same table, the connection to the previous device will be terminated. Are you sure?' })
        : $tE({ de: 'Dieser Tisch ist bereits einem anderen Gerät zugewiesen, das aber inzwischen inaktiv ist. Wenn Sie dieses Gerät dem gleichen Tisch zuweisen, wird die Verbindung zum vorigen Gerät getrennt. Sind Sie sicher?', en: 'This table is already assigned to another device, which is inactive by now. If you assign this device to the same table, the connection to the previous device will be terminated. Are you sure?' })

      if (!await Dialog.confirm({
        message,
        type: 'is-warning',
        icon: 'exclamation-triangle',
        confirmText: $tE({ de: 'Ja', en: 'Yes' }),
        cancelText: $tE({ de: 'Nein', en: 'No' }),
        size: 'is-large'
      })) return
    }

    try {
      await loading(async () => {
        $localState.testTableFlag = tableOption === 'test'

        await apiCall('POST', '/api/app/assignTable', { id: tableOption === 'test' ? null : tableOption?.id ?? null, batteryStatus: $batteryStatus })

        close()
        await reloadPage()
      })
    } catch (e) {
      console.error('Error assigning table:', e)

      await Dialog.alert({
        message: $tE({ de: 'Beim Zuweisen des Tisches ist ein Fehler aufgetreten!', en: 'An error occured while assigning the table!' }),
        type: 'is-danger',
        icon: 'exclamation-circle',
        size: 'is-large'
      })
    }
  }

  let type = $table?.type === 'kiosk' ? 'kiosk' : 'regular'
</script>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Tisch zuweisen', en: 'Assign Table' })}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <Button type={type === 'regular' ? 'is-dark' : 'is-light'} disabled={type === 'regular'} on:click={() => (type = 'regular')}>{$tE({ de: 'Tisch', en: 'Table' })}</Button>
          <Button type={type === 'kiosk' ? 'is-dark' : 'is-light'} disabled={type === 'kiosk'} on:click={() => (type = 'kiosk')}>{$tE({ de: 'Kiosk', en: 'Kiosk' })}</Button>
        </div>
      </div>
    </div>

    {#await fetchTables()}
      <Loader inline />
    {:then tables}
      <div class="big-buttons">
        <Button class="big-button" type="is-dark" on:click={close} iconLeft="arrow-left" disabled={$loading}>{$tE({ de: 'Zurück', en: 'Back' })}</Button>

        <Button class="big-button" type="is-secondary" on:click={() => assignTable(null)} iconLeft="ban" disabled={$loading || (!$table && !$isTestTableMode)}>{$tE({ de: 'Kein Tisch', en: 'No Table' })}</Button>

        {#each tables.filter(t => t.type === type) as tableOption}
          <Button class="big-button" type={((!tableOption.deviceLastSeen || $table?.id === tableOption.id) ? 'is-primary' : new Date(tableOption.deviceLastSeen).valueOf() < now - window.appVariables.configParams.deviceLastSeenTimeout ? 'is-warning' : 'is-danger')} on:click={() => assignTable(tableOption)} disabled={$loading || tableOption.id === $table?.id}>{tableOption.number}</Button>
        {/each}

        {#if type === 'regular'}
          <Button class="big-button" type="is-secondary" on:click={() => assignTable('test')} disabled={$loading || $isTestTableMode}>TEST</Button>
        {/if}
      </div>
    {:catch error}
      <h2 class="title is-2 has-text-danger">{$tE({ de: 'Fehler beim Laden der Tische', en: 'Error loading tables' })}</h2>
      <Button class="big-button" type="is-dark" on:click={close} iconLeft="times">{$tE({ de: 'Schließen', en: 'Close' })}</Button>
    {/await}
  </main>
</ModalDialog>
