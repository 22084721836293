<script>
  import bundle from '../stores/bundle'
  import RichTextNode from './RichTextNode.svelte'
  import SpicinessIndicator from './SpicinessIndicator.svelte'
  import DietIndicator from './DietIndicator.svelte'
  import { createEventDispatcher } from 'svelte'
  import { tC } from '../stores/i18n'
  import { allergens, getOptionLabel } from '../../payload/i18nConstants'
  import Edit2 from 'svelte-feather/components/Edit2.svelte'
  import Plus from 'svelte-feather/components/Plus.svelte'
  import Minus from 'svelte-feather/components/Minus.svelte'
  import Info from './icons/Info.svelte'
  import ArrowRight from 'svelte-feather/components/ArrowRight.svelte'
  import X from 'svelte-feather/components/X.svelte'
  import BrandButton from './BrandButton.svelte'
  import BrandButtonRow from './BrandButtonRow.svelte'
  import SubArticleRow from './SubArticleRow.svelte'
  import { formatCurrency, isRichTextNodeEmpty } from '../lib/utils'
  import SubArticleDetailsDialog from './SubArticleDetailsDialog.svelte'
  import SubArticleSelectionDialog from './SubArticleSelectionDialog.svelte'
  import dialogs from '../stores/dialogs'
  import EditCommentDialog from './EditCommentDialog.svelte'
  import { slide } from 'svelte/transition'
  import { isMatchingFilter, userData, updateAgeVerificationRequest } from '../stores/session'

  const dispatch = createEventDispatcher()

  export let articleId
  export let articleConfiguration = { article: articleId, subArticles: [], comment: '' }
  export let mode = 'main'
  export let narrow = false
  export let hasErrors = false

  $: article = $bundle.pages[articleId] ?? $bundle.articles[articleId]
  $: combinedAllergens = [...new Set([...article.allergens ?? [], ...articleConfiguration?.subArticles?.flatMap(subArticle => $bundle.articles[subArticle.article]?.allergens ?? [])].filter(allergen => allergen !== 'none'))].sort()
  $: combinedAgeRestriction = Math.max(article.ageRestriction, ...articleConfiguration?.subArticles?.map(subArticle => $bundle.articles[subArticle.article]?.ageRestriction ?? 0) ?? [])

  function updateSubArticleSelection (subArticleId, quantity, subArticleGroupId, removeOthers = false) {
    let changed = false

    if (removeOthers) {
      const newArray = articleConfiguration.subArticles.filter(subArticle => subArticle.subArticleGroupId !== subArticleGroupId)
      if (newArray.length !== articleConfiguration.subArticles.length) {
        articleConfiguration.subArticles = newArray
        changed = true
      }
    }

    const subArticleIndex = articleConfiguration.subArticles.findIndex(subArticle => subArticle.article === subArticleId && subArticle.subArticleGroupId === subArticleGroupId)
    if (quantity > 0) {
      if (subArticleIndex === -1) {
        articleConfiguration.subArticles.push({ article: subArticleId, quantity, subArticleGroupId })
        changed = true
      } else {
        articleConfiguration.subArticles[subArticleIndex].quantity = quantity
        changed = true
      }
    } else if (subArticleIndex !== -1) {
      articleConfiguration.subArticles.splice(subArticleIndex, 1)
      changed = true
    }

    if (changed) {
      articleConfiguration = articleConfiguration
      dispatch('change', articleConfiguration)

      if (quantity > 0 && $bundle.articles[subArticleId]?.ageRestriction > 0) updateAgeVerificationRequest(true).catch(() => {})
    }

    if (removeOthers) hideSelectionPopup()
  }

  function updateComment (comment) {
    articleConfiguration.comment = comment
    articleConfiguration = articleConfiguration
    dispatch('change', articleConfiguration)
  }

  function articleRequiresInfoButton (subArticle) {
    if (!subArticle) return false
    return subArticle.image || subArticle.allergens.some(a => a !== 'none') || subArticle.ageRestriction > 0 || !isRichTextNodeEmpty($tC(subArticle.description))
  }

  let popupShownForArticleId = null
  let popupShownForGroupId = null
  let selectionShownForGroupId = null

  function showDetailsPopup (articleId, groupId) {
    popupShownForArticleId = articleId
    popupShownForGroupId = groupId
  }

  function hideDetailsPopup () {
    popupShownForArticleId = null
    popupShownForGroupId = null
  }

  function onDetailsPopupButton () {
    const group = article.mergedGroups.find(group => group.id === popupShownForGroupId)

    updateSubArticleSelection(popupShownForArticleId, 1, popupShownForGroupId, group.effectiveSelectionMode === 'single')
  }

  function showSelectionPopup (groupId) {
    selectionShownForGroupId = groupId
  }

  function hideSelectionPopup () {
    selectionShownForGroupId = null
  }

  async function editComment () {
    const newComment = await dialogs.open(EditCommentDialog, { comment: articleConfiguration.comment })
    if (newComment != null) {
      updateComment(newComment)
    }
  }
</script>

<style lang="scss">
  main {
    h2 {
      text-transform: uppercase;
      hyphens: auto;

      :global(.tooltip) {
        transform: translateX(calc(-50% + var(--shift, 0px))) translateY(0.9em);
        z-index: 2;
      }
    }

    :global(.tooltip) {
      text-transform: none;
    }

    :global(.subgroup-button-row) {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-columns: max-content;
      grid-auto-flow: column;
    }

    :global(.subgroup-button-row > *) {
      min-width: 0;
    }

    .meta:empty {
      display: none;
    }
  }
</style>

<main class:is-narrow={narrow}>
  {#if article}
    <h2 class="title is-2">
      <span class:is-not-matching-filter={!$isMatchingFilter(article)}>{$tC(article.name)}</span>
      <SpicinessIndicator spiciness={article.spiciness} withTooltip />
      <DietIndicator diet={article.diet} withTooltip showOmnivoreIcon={$userData.dietFilter !== 'omnivore'} />
    </h2>

    {#if hasErrors}
      <div class="notification is-danger">
        {#if !article.available}
          {$tC({ de: 'Dieser Artikel ist leider nicht mehr verfügbar!', en: 'This article is unfortunately no longer available!' })}
        {:else if articleConfiguration?.subArticles?.some(s => $bundle.articles[s.article]?.available === false)}
          {$tC({ de: 'Einige der ausgewählten Komponenten sind leider nicht mehr verfügbar! Bitte neue Auswahl treffen!', en: 'Some of the selected components are unfortunately no longer available! Please make a new selection!' })}
        {:else}
          {$tC({ de: 'Die aktuelle Auswahl ist ungültig und kann so nicht bestellt werden. Bitte korrigieren!', en: 'The current selection is invalid and cannot be ordered as is. Please correct it!' })}
        {/if}
      </div>
    {:else if article.mergedGroups?.some(group => group.effectiveSelectionMode === 'single' && group.articleGroup.articles.every(article => !$isMatchingFilter(article)))}
      <div class="notification is-warning">
        {$tC({ de: 'Dieser Artikel hat keine mögliche Auswahl an Komponenten, die den Filtereinstellungen entsprechen würde.', en: 'This article has no possible selection of components that would match the filter settings.' })}
      </div>
    {:else if !$isMatchingFilter(article)}
      <div class="notification is-warning">
        {$tC({ de: 'Dieser Artikel entspricht nicht den aktuellen Filtereinstellungen.', en: 'This article does not match the current filter settings.' })}
      </div>
    {:else if !articleConfiguration.subArticles.every(subArticle => $isMatchingFilter($bundle.articles[subArticle.article]))}
      <div class="notification is-warning">
        {$tC({ de: 'Einige der ausgewählten Komponenten entsprechen nicht den aktuellen Filtereinstellungen.', en: 'Some of the selected components do not match the current filter settings.' })}
      </div>
    {/if}

    <RichTextNode node={{ children: $tC(article.description) }} {narrow} />

    {#if mode !== 'subArticle'}
      {#if article.posArticle?.price && article.mergedGroups?.length}
        <div class="level mb-3">
          <div class="level-left">
            <div class="level-item"><strong>{$tC({ de: 'Grundpreis:', en: 'Base price:' })}</strong></div>
          </div>
          <div class="level-right">
            <div class="level-item">{$tC && formatCurrency(article.posArticle.price)}</div>
          </div>
        </div>
      {/if}

      {#each (article.mergedGroups ?? []).filter(group => group.articleGroup?.articles?.length) as group}
        {@const showPricePlus = !!article.posArticle.price || group !== article.mergedGroups.find(g => g.effectiveSelectionMode === 'single')}
        {@const requiresInfoButton = group.articleGroup.articles.some(subArticle => articleRequiresInfoButton(subArticle))}
        {@const selectedSubArticles = articleConfiguration.subArticles.filter(subArticle => subArticle.subArticleGroupId === group.id)}
        <div class="subgroup my-5" class:has-overlay={group.displayType === 'overlay'}>
          {#if group.displayMode === 'overlay'}
            {#if !selectedSubArticles.length}
              {#if group.effectiveSelectionMode === 'single'}
                <div class="mb-1">
                  <strong>{$tC(group.articleGroup.name)}:</strong> <em>{$tC({ de: 'Auswahl erforderlich', en: 'Selection required' })}</em>
                </div>
                <BrandButton class="is-fullwidth my-1" on:click={() => showSelectionPopup(group.id)}><ArrowRight /> {$tC({ de: '{c} wählen', en: 'Select {c}' }, { c: $tC(group.articleGroup.name) })}</BrandButton>
              {:else}
                <BrandButton class="is-fullwidth my-1" on:click={() => showSelectionPopup(group.id)}><Plus /> {$tC({ de: '{c} hinzufügen', en: 'Add {c}' }, { c: $tC(group.articleGroup.name) })}</BrandButton>
              {/if}
            {:else}
              {#if selectedSubArticles.length === 1 && (group.effectiveSelectionMode === 'single' || selectedSubArticles[0].allowOnlySingleQuantity)}
                {@const subArticle = $bundle.articles[selectedSubArticles[0].article]}
                <div class="mb-1">
                  <SubArticleRow article={subArticle} showOmnivoreIcon={['vegetarian', 'vegan'].includes(article.diet)} {showPricePlus} quantity={selectedSubArticles[0].quantity > 1 ? selectedSubArticles[0].quantity : null} multiline unavailable={!subArticle.available}>
                    <strong>{$tC(group.articleGroup.name)}:</strong>
                  </SubArticleRow>
                </div>
              {:else}
                <div class="mb-1">
                  <strong>{$tC(group.articleGroup.name)}:</strong> {#if group.effectiveSelectionMode === 'single' && !selectedSubArticles.length}<em>{$tC({ de: 'Auswahl erforderlich', en: 'Selection required' })}</em>{/if}
                </div>
                {#each selectedSubArticles as selectionInfo (selectionInfo.article)}
                  {@const subArticle = $bundle.articles[selectionInfo.article]}
                  <SubArticleRow article={subArticle} showOmnivoreIcon={['vegetarian', 'vegan'].includes(article.diet)} {showPricePlus} quantity={(!subArticle.allowOnlySingleQuantity || selectionInfo.quantity > 1) ? selectionInfo.quantity : null} unavailable={!subArticle.available} />
                {/each}
              {/if}
              <BrandButton class="is-fullwidth my-1" on:click={() => showSelectionPopup(group.id)}><Edit2 /> {$tC({ de: '{c} ändern', en: 'Change {c}' }, { c: $tC(group.articleGroup.name) })}</BrandButton>
            {/if}

            {#if selectionShownForGroupId === group.id}
              <SubArticleSelectionDialog group={group} {articleConfiguration} on:close={hideSelectionPopup} {showPricePlus} {updateSubArticleSelection} />
            {/if}
          {:else}
            {#if group.displayMode !== 'inlineAnonymous'}
              <div class="mb-1">
                <strong>{$tC(group.articleGroup.name)}:</strong>
              </div>
            {:else if !selectedSubArticles.length && group.effectiveSelectionMode === 'single'}
              <div class="mb-1" transition:slide>
                <strong><em>{$tC({ de: 'Auswahl erforderlich:', en: 'Selection required:' })}</em></strong>
              </div>
            {/if}

            {#each group.articleGroup.articles as subArticle (subArticle.id)}
              {@const selectionInfo = selectedSubArticles.find(selectionInfo => selectionInfo.article === subArticle.id) ?? { article: subArticle.id, quantity: 0, subArticleGroupId: group.id }}
              {@const allowMultiple = group.effectiveSelectionMode === 'multiple' && !subArticle.allowOnlySingleQuantity}
              {#if selectionInfo.quantity > 0 || $isMatchingFilter(subArticle)}
                <BrandButtonRow class="my-1 subgroup-button-row">
                  <BrandButton class="is-fullwidth" toggle selected={selectionInfo.quantity > 0} on:click={() => updateSubArticleSelection(subArticle.id, (selectionInfo.quantity > 0 && group.effectiveSelectionMode === 'multiple') ? 0 : 1, group.id, group.effectiveSelectionMode === 'single')} disabled={!subArticle.available && selectionInfo.quantity === 0} unavailable={!subArticle.available}>
                    <SubArticleRow article={subArticle} showNewBadge showOmnivoreIcon={['vegetarian', 'vegan'].includes(article.diet)} {showPricePlus} quantity={(allowMultiple || selectionInfo.quantity > 1) ? selectionInfo.quantity : null} />
                  </BrandButton>
                  {#if allowMultiple && selectionInfo.quantity > 0}
                    <BrandButton icon on:click={() => updateSubArticleSelection(subArticle.id, selectionInfo.quantity + 1, group.id)}><Plus size="medium" width="1px" /></BrandButton>
                    <BrandButton icon on:click={() => updateSubArticleSelection(subArticle.id, selectionInfo.quantity - 1, group.id)}><Minus size="medium" width="1px" /></BrandButton>
                  {/if}
                  {#if requiresInfoButton}
                    <BrandButton icon invisible={!articleRequiresInfoButton(subArticle)} on:click={() => showDetailsPopup(subArticle.id, group.id)}><Info /></BrandButton>
                    {#if popupShownForArticleId === subArticle.id && popupShownForGroupId === group.id}
                      <SubArticleDetailsDialog article={subArticle} button={selectionInfo.quantity === 0 && (group.effectiveSelectionMode === 'multiple' ? 'add' : 'select')} on:close={hideDetailsPopup} on:select={onDetailsPopupButton} {showPricePlus} />
                    {/if}
                  {/if}
                </BrandButtonRow>
              {/if}
            {/each}
          {/if}
        </div>
      {/each}

      {#if article.allowComment || articleConfiguration.comment}
        <div class="subgroup my-5">
          {#if articleConfiguration.comment}
            <div class="mb-1">
              <strong>{$tC({ de: 'Kommentar/Sonderwunsch', en: 'Comment / Special Request' })}:</strong> {articleConfiguration.comment}
            </div>
          {/if}
          <BrandButtonRow class="my-1">
            {#if articleConfiguration.comment}
              <BrandButton class="is-fullwidth" on:click={editComment}><Edit2 /> {$tC({ de: 'Kommentar/Sonderwunsch ändern', en: 'Change Comment / Special Request' })}</BrandButton>
              <BrandButton icon on:click={() => updateComment('')}><X /></BrandButton>
            {:else}
              <BrandButton class="is-fullwidth" on:click={editComment}><Plus /> {$tC({ de: 'Kommentar/Sonderwunsch hinzufügen', en: 'Add Comment / Special Request' })}</BrandButton>
            {/if}
          </BrandButtonRow>
        </div>
      {/if}
    {/if}

    <div class="meta my-5">
      {#if !article.isPage}
        <p>
          <strong>{$tC({ de: 'Allergene:', en: 'Allergens:' })}</strong> {combinedAllergens.length ? combinedAllergens.map(allergen => $tC(getOptionLabel(allergens, allergen))).join(', ') : $tC({ de: 'Keine', en: 'None' })}
        </p>
      {/if}
      {#if combinedAgeRestriction > 0}
        <p>
          <strong>{$tC({ de: 'Altersbeschränkung:', en: 'Age restriction:' })}</strong> {$tC({ de: 'Ab {y} Jahren', en: 'From {y} years' }, { y: combinedAgeRestriction })}
        </p>
      {/if}
    </div>
  {:else}
    <h3 class="title is-3">{$tC({ de: 'Artikel nicht gefunden', en: 'Article not found' })}</h3>
  {/if}
</main>
