<script>
  import { languages } from '../../payload/i18nConstants'
  import { customerLanguage, tC } from '../stores/i18n'
  import { Button } from 'svelma-fixed'
  import ModalDialog from './ModalDialog.svelte'
  import { createEventDispatcher } from 'svelte'
  import { rippleOnTouch } from '../lib/utils'

  const dispatch = createEventDispatcher()

  function select (lang) {
    customerLanguage.set(lang)
    dispatch('close')
  }
</script>

<style lang="scss">
  main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    > span {
      position: relative;

      :global(.ripple-container) {
        border-radius: 16.5px;
        border: 1px solid transparent;
      }
    }

    :global(button) {
      height: auto;
      border: 1px solid $brand-color;
      border-radius: 16.5px;

      :global(> span) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    img {
      width: 12rem;
      height: 6rem;
      object-fit: contain;
    }
  }
</style>

<ModalDialog title={$tC({ de: 'Sprache wählen', en: 'Choose Language' })} closeable on:close>
  <main>
    {#each Object.entries(languages) as [lang, obj]}
      <span use:rippleOnTouch>
        <Button type="is-secondary" on:click={() => { select(lang) }}>
          <img src={obj._flag} alt={$tC(obj)} />
          <span>{obj[lang] ?? $tC(obj)}</span>
        </Button>
      </span>
    {/each}
  </main>
</ModalDialog>
