<script>
  import { tC } from '../stores/i18n'
  import CustomerLanguageSelector from './CustomerLanguageSelector.svelte'
  import session, { cart, cartTotal, currentView, paymentState, uiState, newOrder, newCustomer, updateAgeVerificationRequest, reportSessionActivity } from '../stores/session'
  import { getProcessNumber } from '../../shared/lib/misc'
  import { table } from '../stores/table'
  import bundle from '../stores/bundle'
  import FontSizeSelector from './FontSizeSelector.svelte'
  import BrandButtonRow from './BrandButtonRow.svelte'
  import BrandButton from './BrandButton.svelte'
  import ArrowLeft from 'svelte-feather/components/ArrowLeft.svelte'
  import AlertTriangle from 'svelte-feather/components/AlertTriangle.svelte'
  import Frown from 'svelte-feather/components/Frown.svelte'
  import MessageCircle from 'svelte-feather/components/MessageCircle.svelte'
  import Mail from 'svelte-feather/components/Mail.svelte'
  import Check from 'svelte-feather/components/Check.svelte'
  import { delay, formatCurrency, renderQrCode, rippleOnTouch, round2, sendToServerLog } from '../lib/utils'
  import { fade, fly, scale } from 'svelte/transition'
  import { batteryStatus, executePayment, getLastTransaction, paymentDisabled } from '../stores/yaoKiosk'
  import Contactless from './icons/Contactless.svelte'
  import CashRegister from './icons/CashRegister.svelte'
  import { localState } from '../stores/localState'
  import dialogs from '../stores/dialogs'
  import EnterTipDialog from './EnterTipDialog.svelte'
  import MessageDialog from './MessageDialog.svelte'
  import PhoneNumberDialog from './PhoneNumberDialog.svelte'
  import EmailDialog from './EmailDialog.svelte'
  import FancyLoader from './FancyLoader.svelte'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import { createLoadingStore } from '../stores/loading'
  import DrawnArrow from './DrawnArrow.svelte'
  import AnimatedCheckMark from './AnimatedCheckMark.svelte'
  import { Toast } from 'svelma-fixed'
  import critical from '../stores/critical'
  import VendorLogos from './icons/VendorLogos.svelte'

  const loading = createLoadingStore()

  $: if (!$paymentState) $paymentState = 'choice'
  $: canGoBack = ['choice', 'tip', 'payAtCounter', 'failure'].includes($paymentState)

  function goBack (logoClicked = false) {
    if (logoClicked && $paymentState === 'success') {
      newOrder()
      return
    }

    if (!canGoBack) return

    if ($paymentState === 'choice') {
      $currentView = 'menu'
    } else {
      $paymentState = 'choice'
    }
  }

  $: overallAgeRestriction = Math.max(0, ...$cart.flatMap(item => [item, ...item.subArticles]).map(item => $bundle.articles[item.article]?.ageRestriction ?? 0))
  $: devicePaymentDisabled = paymentDisabled || !$bundle.settings.powerSwitch.paymentsEnabled || $batteryStatus?.level <= 5 || $cartTotal > $bundle.settings.maxDevicePaymentAmount || (overallAgeRestriction > 0 && !$session.userData.ageVerified)

  $: if ($paymentState === 'fromNfc') {
    $paymentState = devicePaymentDisabled ? 'choice' : 'tip'
  }

  let order

  let paymentInitialized = false
  let lastPayment = 0
  async function startPayment (tipAmount = 0, recover = false) {
    await critical(async () => {
      tipAmount = round2(tipAmount)

      $localState.orderSubmissionInProgress = false
      $localState.hasFatalPaymentError = false

      if (!recover) {
        $localState.paymentInProgress = null

        if (!($cartTotal >= 0.01)) throw new Error('Cart total must be positive')

        if (devicePaymentDisabled) {
          await dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Zahlung ist auf diesem Gerät aktuell nicht möglich!', en: 'Payment is currently not possible on this device!' }), iconClass: 'has-text-danger', Icon: AlertTriangle })
          return
        }

        if (round2($cartTotal + tipAmount) > $bundle.settings.maxDevicePaymentAmount) {
          await dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Zahlungen sind auf diesem Gerät nur bis maximal {max} möglich!', en: 'Payments are possible on this device only up to a maxiumum total of {max}!' }, { max: formatCurrency($bundle.settings.maxDevicePaymentAmount) }) })
          return
        }

        $uiState.tipAmount = tipAmount
        paymentInitialized = true
        $paymentState = 'processing'

        try {
          await updateAgeVerificationRequest(false)
        } catch (e) {}

        try {
          await apiCall('POST', '/api/app/validateOrder', { sessionId: $session.id, cart: $cart })
        } catch (e) {
          console.error('Validating order failed', e)
          await sendToServerLog('error', `Validating order failed on table ${$table?.number}: ${e}`, 'validatingOrderFailed', 'payment', e)

          $paymentState = 'failure'
          return
        }

        $localState.paymentInProgress = {
          sessionId: $session.id,
          cartTotal: $cartTotal,
          tipAmount: $uiState.tipAmount,
          timestamp: Date.now()
        }
      }

      try {
        let result
        try {
          if (recover) {
            await sendToServerLog('info', 'Resuming payment', 'resumingPayment', 'payment', null, { data: $localState.paymentInProgress })
            result = await getLastTransaction()
          } else {
            const timeToWait = Math.max(800, (lastPayment + 5000) - Date.now()) // Without this, we can get an error about TX already in progress
            await delay(timeToWait)

            result = await executePayment(round2($cartTotal + $uiState.tipAmount))
            lastPayment = Date.now()
          }
        } catch (e) {
          console.error('Payment failed (exception)', e)
          await sendToServerLog('error', `Payment failed on table ${$table?.number} (exception): ${e}`, 'paymentFailedException', 'payment', e)

          $paymentState = 'failure'
          return
        }

        if (recover && result && !result.error && result.result !== 0) {
          result.error = { errorCode: `X-${result.result}`, exception: 'YaoKioskNonzeroResultException' }
        }

        try {
          if (result?.error) {
            if (result.error.errorCode === '1-096' || result.error.exception.includes('TransactionCancelledByUserException')) {
              console.log('User cancelled payment')
            } else {
              console.error('Payment failed', result.error)
              await sendToServerLog('warning', `Payment failed on table ${$table?.number}: ${result?.error?.exception} (${result?.error?.errorCode})`, 'paymentFailed', 'payment', null, { result })
            }

            $paymentState = 'failure'
          } else if (recover && (!result || result.state.state === 1 || new Date(result.date ? `20${result.date.match(/../g).join('-')}T${result.time.match(/../g).join(':')}` : result.state.updated).valueOf() < $localState.paymentInProgress.timestamp)) {
            console.error('Bad last payment state', result)
            await sendToServerLog('error', `Bad last payment state on table ${$table?.number}: ${result?.error?.exception}`, 'badLastPaymentState', 'payment', null, { result })

            $localState.hasFatalPaymentError = true
            $paymentState = 'fatalError'
          } else {
            console.log('Payment successful', result)

            $localState.paymentInProgress = null
            $localState.orderSubmissionInProgress = true
            order = await apiCall('POST', '/api/app/order', { sessionId: $session.id, cart: $cart, cartTotal: $cartTotal, tipAmount, paymentResult: result })

            $paymentState = 'success'

            if (order && !$session.orders.includes(order.id)) {
              $session.orders = [...$session.orders, order.id]
            }
          }
        } catch (e) {
          console.error('Payment handling failed', e)
          await sendToServerLog('error', `Payment handling failed on table ${$table?.number}: ${e}`, 'paymentHandlingFailed', 'payment', e, { result })

          $localState.hasFatalPaymentError = true
          $paymentState = 'fatalError'
        }
      } finally {
        paymentInitialized = false
        $localState.paymentInProgress = null
        $localState.orderSubmissionInProgress = false

        try {
          reportSessionActivity()
        } catch (e) {
          console.error('Failed to report session activity', e)
        }
      }
    })
  }

  console.log('STATE', $paymentState, $localState)
  $: if (($paymentState === 'processing' || $paymentState === 'failure') && !paymentInitialized && ($localState.hasFatalPaymentError || $localState.orderSubmissionInProgress)) {
    // For some reason, when reloading the page, the local state update does not seem to happen *immediately*, but the session is still updated, so we end up in the "failure" state but with orderSubmissionInProgress set
    console.error('Order submission interrupted')
    sendToServerLog('error', `Order submission interrupted on table ${$table?.number}`, 'orderSubmissionInterrupted', 'payment')

    $paymentState = 'fatalError'
  } else if ($paymentState === 'processing' && !paymentInitialized) {
    if ($localState.paymentInProgress) {
      startPayment(null, true)
    } else {
      startPayment($uiState.tipAmount)
    }
  }

  $: if ($paymentState === 'tip' && !$bundle.settings.allowTip) startPayment(0)

  async function customTip () {
    const tip = await dialogs.open(EnterTipDialog)
    if (tip == null) return

    startPayment(Number(tip))
  }

  $: if ($paymentState === 'success' && !order) {
    loading(async () => {
      order = await apiCall('GET', uri`/api/app/lastOrder/${$session.id}`)
      if (!order?.id) {
        console.error('Failed to load order', order)
        await sendToServerLog('error', 'Failed to load order', 'failedToLoadOrder', 'payment', null, { order })

        $localState.hasFatalPaymentError = true
        $paymentState = 'fatalError'
      }
    })
  }

  const sendingSms = createLoadingStore()
  const sendingEmail = createLoadingStore()
  const sending = { sms: sendingSms, email: sendingEmail }
  async function sendInvoice (method) {
    const data = await dialogs.open(method === 'sms' ? PhoneNumberDialog : EmailDialog, $session.userData)
    if (!data) return

    $session.userData = Object.assign($session.userData, data)

    try {
      await sending[method](async () => {
        await apiCall('POST', uri`/api/app/sendInvoice/${method}`, { orderId: order.id, userData: $session.userData })
      })
    } catch (e) {
      console.error('Failed to send invoice', e)

      await dialogs.open(MessageDialog, { title: $tC({ de: 'Senden fehlgeschlagen', en: 'Sending Failed' }), text: $tC({ de: 'Leider ist beim Absenden der Rechnung ein Problem aufgetreten! Bitte kontrollieren Sie Ihre Eingabe und versuchen Sie es erneut!', en: 'Unfortunately, there was a problem sending the invoice! Please check your input and try again!' }), iconClass: 'has-text-danger', Icon: AlertTriangle })

      return
    }

    Toast.create({ message: $tC({ de: 'Rechnung wurde erfolgreich versendet!', en: 'Invoice was sent successfully!' }), type: 'is-success', duration: 6000 })
  }

  async function onUnknownNfcTag () {
    if ($paymentState === 'choice') {
      if (devicePaymentDisabled) {
        await dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Kontaktlose Zahlung ist nicht möglich! Bitte an der Theke bezahlen!', en: 'Contactless payment is not possible! Please pay at the counter!' }), iconClass: 'has-text-danger', Icon: AlertTriangle })
      } else {
        $paymentState = 'tip'
      }
    } else if ($paymentState === 'tip') {
      await dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Bitte wählen Sie zuerst eine Trinkgeld-Option!', en: 'Please choose a tip option first!' }), iconClass: 'has-text-danger', Icon: AlertTriangle })
    } else if ($paymentState === 'failure' && !devicePaymentDisabled) {
      startPayment($uiState.tipAmount)
    }
  }
</script>

<style lang="scss">
  main {
    color: $text;
    background-color: white;

    width: 100%;
    height: 100%;

    $top-height: 150px;

    display: grid;
    grid-template-columns: 42fr 32fr 24fr;
    grid-template-rows: $top-height 1fr;
    grid-template-areas:
      "title title logo"
      "content content content";

    > * {
      // Prevent grid blowout
      min-width: 0;
      min-height: 0;
    }

    .title-area {
      grid-area: title;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: calc(2 * $default-margin);

      h1 {
        margin: 0;
        text-transform: uppercase;
        font-size: 4rem;
      }
    }

    .logo-area {
      grid-area: logo;
      padding: $default-margin;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }
    }

    .content-area {
      grid-area: content;
      background-color: $field-bg;
      border-top: 2px solid $brand-color;
      position: relative;
      display: grid;
      justify-content: center;
      align-items: center;

      .vendor-logos {
        position: absolute;
        bottom: 2vh;
        left: 10%;
        width: 80%;
        height: 15vh;
        object-fit: contain;
        pointer-events: none;

        :global(svg) {
          opacity: 0.2;
        }
      }

      section {
        grid-area: 1 / 1 / span 1 / span 1;
        min-width: 0;
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: calc(1.5 * $default-margin);
        overflow-y: auto;
      }

      section.choice {
        :global(.brand-button-row) {
          gap: 2rem;
        }

        :global(.brand-button.huge) {
          border-radius: 20px;
          height: auto;
          font-size: 1.5rem;
          font-weight: lighter;
          white-space: normal;
          width: 20rem;
          height: 10rem;

          :global(svg) {
            width: 6rem;
            height: 4rem;
            object-fit: contain;
            margin-bottom: 1rem;
            margin-right: 0;
            transform: none;
          }

          :global(div.small) {
            font-size: 1rem;
          }
        }
      }

      section.pay-at-counter, section.fatal-error, section.failure, section.success {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-template-rows: auto min-content;
        grid-template-areas:
          "left-icon main-text"
          "bottom-button bottom-button";
        width: 80vw;
        gap: 2rem;

        .left-icon {
          grid-area: left-icon;
          position: relative;
        }

        .main-text {
          grid-area: main-text;
        }

        .bottom-button {
          grid-area: bottom-button;

          :global(.brand-button svg) {
            transform: scale(2.5) translateY(-0.05em);
            margin-right: 0.75em;
          }
        }
      }

      section.success {
        grid-template-rows: min-content auto min-content;
        grid-template-areas:
          "header header"
          "left-icon main-text"
          "bottom-button bottom-button";

        z-index: 1;

        .header {
          grid-area: header;
        }

        :global(.fancy-loader) {
          grid-area: 1 / 1 / span 3 / span 2;
          margin: auto;
        }

        canvas {
          box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
        }

        .paper-invoice {
          width: 18rem;
          margin: auto;
          line-height: 1;
          font-size: 0.8rem;
          text-align: center;
        }
      }

      section.failure, section.success {
        :global(.button-container) {
          width: 100%;
          text-align: center;
        }
      }

      section.fatal-error .left-icon :global(svg), section.failure .left-icon :global(svg) {
        width: 100%;
        height: 100%;
        stroke-width: 0.6px;
      }

      h2, h3 {
        margin: 2rem;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      section.tip, section.failure, section.success {
        :global(.brand-button) {
          margin-bottom: 0.5rem;
          width: 20rem;
          min-width: 0;
        }
      }
    }
  }
</style>

<svelte:window on:unknownNfcTag={onUnknownNfcTag} />

<FontSizeSelector />
<CustomerLanguageSelector />

<main>
  <div class="title-area">
    <h1 class="title is-1">{$tC({ de: 'Zahlung', en: 'Payment' })}</h1>
  </div>
  <div class="logo-area">
    <img in:fade|global src="/images/logo.svg?_=2" alt="YaoOrder" on:click={() => goBack(true)} />
  </div>
  <div class="content-area">
    {#if canGoBack}
      <div class="back-button" transition:fly={{ x: -10 }} on:click={() => goBack()} use:rippleOnTouch><ArrowLeft /></div>
    {/if}

    {#if $paymentState === 'choice'}
      <section class="choice" in:fly={{ y: 30, delay: 400 }} out:fly={{ y: 30 }}>
        {#if paymentDisabled || !$bundle.settings.powerSwitch.paymentsEnabled || $batteryStatus?.level <= 5}
          <div class="notification is-warning" in:fly|global={{ y: 30, delay: 500 }}>
            {$tC({ de: 'Momentan ist die kontaktlose Zahlung an diesem Gerät leider nicht möglich. Bitte zahlen Sie stattdessen an der Theke. Vielen Dank!', en: 'Unfortunately, payment directly at the device is currently not possible. Please pay at the counter instead. Thank you!' })}
          </div>
        {:else if $cartTotal > $bundle.settings.maxDevicePaymentAmount}
          <div class="notification is-warning" in:fly|global={{ y: 30, delay: 500 }}>
            {$tC({ de: 'Leider sind kontaktlose Zahlungen an diesem Gerät nur bis {max} möglich. Bitte zahlen Sie stattdessen an der Theke. Vielen Dank!', en: 'Unfortunately, contactless payments at this device are only possible up to {max}. Please pay at the counter instead. Thank you!' }, { max: formatCurrency($bundle.settings.maxDevicePaymentAmount) })}
          </div>
        {:else if overallAgeRestriction > 0 && !$session.userData.ageVerified}
          <div class="notification is-warning" in:fly|global={{ y: 30, delay: 500 }}>
            {$tC({ de: 'Einige Artikel in Ihrem Warenkorb sind erst ab {age} Jahren erhältlich. Es ist uns nicht erlaubt, solche Artikel ohne die Hilfe eines Mitarbeiters zu verkaufen. Bitte zahlen Sie deshalb an der Theke. Vielen Dank für Ihr Verständnis!', en: 'Some items in your cart are only available from the age of {age}. We are not allowed to sell such items without a staff member taking the order. Therefore, please pay at the counter. Thank you for your understanding!' }, { age: overallAgeRestriction })}
          </div>
        {:else}
          <h2 class="has-text-centered title is-2" in:fly|global={{ y: 30, delay: 500 }}>{$tC({ de: 'Wie möchten Sie zahlen?', en: 'How do you want to pay?' })}</h2>
        {/if}

        <div in:scale|global={{ delay: 700 }}>
          <BrandButtonRow>
            <BrandButton class="huge" disabled={devicePaymentDisabled} on:click={() => ($paymentState = 'tip')}>
              <Contactless />
              <div>{$tC({ de: 'Hier kontaktlos bezahlen', en: 'Pay Here Contactless' })}</div>
              <div class="small">{$tC({ de: 'mit MasterCard, Visa oder Handy-Wallet', en: 'with MasterCard, Visa or mobile wallet' })}</div>
            </BrandButton>
            <BrandButton class="huge" on:click={() => { $paymentState = 'payAtCounter'; updateAgeVerificationRequest(false) }}>
              <CashRegister />
              <div>{$tC({ de: 'An der Theke bezahlen', en: 'Pay At Counter' })}</div>
              <div class="small">{$tC({ de: 'mit Bargeld oder Karte', en: 'with cash or card' })}</div>
            </BrandButton>
          </BrandButtonRow>
        </div>

        <h2 class="has-text-centered title is-2"in:fly|global={{ y: 30, delay: 900 }}>{$tC && formatCurrency($cartTotal)}</h2>
      </section>
    {:else if $paymentState === 'payAtCounter'}
      <section class="pay-at-counter" in:fly={{ y: 30, delay: 400 }} out:fly={{ y: 30 }}>
        <div class="left-icon" in:scale|global={{ delay: 500 }}>
          <CashRegister />
        </div>
        <div class="main-text">
          <h2 class="has-text-centered title is-2" in:fly|global={{ x: 30, delay: 700 }}>{$tC({ de: 'Gehen Sie jetzt zur Theke, um zu bezahlen.', en: 'Please go to the counter to pay now.' })}</h2>
          {#if $table?.type === 'kiosk'}
            <h3 class="has-text-centered title is-3" in:fly|global={{ x: 30, delay: 900 }}>{$tC({ de: 'Ihre Vorgangsnummer ist {process}.', en: 'Your process number is {process}.' }, { process: getProcessNumber($session.id) })}</h3>
          {:else}
            <h3 class="has-text-centered title is-3" in:fly|global={{ x: 30, delay: 900 }}>{$tC({ de: 'Sie haben Tisch Nummer {table}.', en: 'You have table number {table}.' }, { table: $table.number })}</h3>
          {/if}
          {#if $table?.type === 'kiosk'}
            <div class="button-container" in:fly|global={{ x: 30, delay: 1000 }}>
              <BrandButton class="is-fullwidth is-primary" on:click={() => newCustomer()}><Check /> {$tC({ de: 'Alles klar!', en: 'Okay!' })}</BrandButton>
            </div>
          {/if}
        </div>

        {#if !devicePaymentDisabled}
          <div class="bottom-button" in:fly|global={{ y: 30, delay: 1100 }}>
            <BrandButton class="is-fullwidth" on:click={() => ($paymentState = 'tip')}><Contactless /> {$tC({ de: 'Stattdessen hier kontaktlos bezahlen', en: 'Pay here contactless instead' })}</BrandButton>
          </div>
        {/if}
      </section>
    {:else if $paymentState === 'tip'}
      <section class="tip" in:fly={{ y: 30, delay: 400 }} out:fly={{ y: 30 }}>
        <h2 class="has-text-centered title is-2" in:fly|global={{ y: 30, delay: 500 }}>{$tC({ de: 'Möchten Sie ein Trinkgeld geben?', en: 'Would you like to give a tip?' })}</h2>

        <div in:fly|global={{ y: 30, delay: 700 }}>
          <BrandButton class="is-primary" on:click={() => startPayment($cartTotal * 0.05)}>{$tC({ de: '{percentage} % ({amount})', en: '{percentage}% ({amount})' }, { percentage: 5, amount: formatCurrency($cartTotal * 0.05) })}</BrandButton>
        </div>
        <div in:fly|global={{ y: 30, delay: 800 }}>
          <BrandButton class="is-primary" on:click={() => startPayment($cartTotal * 0.1)}>{$tC({ de: '{percentage} % ({amount})', en: '{percentage}% ({amount})' }, { percentage: 10, amount: formatCurrency($cartTotal * 0.10) })}</BrandButton>
        </div>
        <div in:fly|global={{ y: 30, delay: 900 }}>
          <BrandButton class="is-primary" on:click={() => startPayment($cartTotal * 0.15)}>{$tC({ de: '{percentage} % ({amount})', en: '{percentage}% ({amount})' }, { percentage: 15, amount: formatCurrency($cartTotal * 0.15) })}</BrandButton>
        </div>
        <div in:fly|global={{ y: 30, delay: 1000 }}>
          <BrandButton class="is-primary" on:click={() => customTip()}>{$tC({ de: 'Eigenen Betrag wählen', en: 'Choose Custom Amount' })}</BrandButton>
        </div>

        <div class="mt-5" in:fly|global={{ y: 30, delay: 1100 }}>
          <BrandButton on:click={() => startPayment(0)}>{$tC({ de: 'Nein, danke', en: 'No, Thanks' })}</BrandButton>
        </div>

        <h2 class="has-text-centered title is-2"in:fly|global={{ y: 30, delay: 1300 }}>{$tC && formatCurrency($cartTotal)}</h2>
      </section>
    {:else if $paymentState === 'processing'}
      <section class="processing" in:fly|global={{ y: 30, delay: 400 }} out:fly={{ y: 30 }}>
        <h2 class="has-text-centered title is-2" in:fly|global={{ y: 30, delay: 500 }}>{$tC({ de: 'Vorgang läuft...', en: 'Processing...' })}</h2>

        <div in:scale|global={{ delay: 700 }}>
          <FancyLoader />
        </div>

        <div class="vendor-logos" in:fly|global={{ y: 50, delay: 900, duration: 1000 }}>
          <VendorLogos />
        </div>
      </section>
    {:else if $paymentState === 'success'}
      {#if order}
        <AnimatedCheckMark />
      {/if}
      <section class="success" in:fly={{ y: 30, delay: 600 }} out:fly={{ y: 30 }}>
        {#if !order}
          <FancyLoader />
        {:else}
          <div class="header">
            <h2 class="has-text-centered title is-2 mb-0" in:fly|global={{ x: 30, delay: 900 }}>{$tC({ de: 'Vielen Dank für Ihre Bestellung!', en: 'Thank you for your order!' })}</h2>
            {#if $table?.type === 'kiosk'}
              <h4 class="has-text-centered title is-4" in:fly|global={{ x: 30, delay: 1000 }}>{$tC({ de: 'Ihre Bestellung wird zubereitet. Bestellnummer:', en: 'Your order is being prepared. Order #:' }, { n: order.receiptNumbers?.join('/') ?? '???' })}</h4>
              <h1 class="has-text-centered title is-1" in:scale|global={{ delay: 1050 }}>{order.receiptNumbers?.join('/') ?? '???'}</h1>
              <h4 class="has-text-centered title is-4" in:fly|global={{ x: 30, delay: 1100 }}>
                {#if $session.userData.phoneNumber}
                  {$tC({ de: 'Wenn Ihre Bestellung abholbereit ist, erhalten Sie eine SMS auf Ihr Mobiltelefon.', en: 'When your order is ready for pickup, you will receive a text message on your phone.' })}
                {:else}
                  {$tC({ de: 'Bitte achten Sie auf die Anzeigetafel, Ihre Nummer erscheint dort, wenn Ihre Bestellung abholbereit ist.', en: 'Please watch the display board, your number will appear there when your order is ready for pickup.' })}
                {/if}
              </h4>
            {:else}
              <h4 class="has-text-centered title is-4" in:fly|global={{ x: 30, delay: 1000 }}>{$tC({ de: 'Ihre Bestellung wird Ihnen in Kürze gebracht. Bestellnummer: {n}', en: 'Your order will be brought to you shortly. Order #: {n}' }, { n: order.receiptNumbers?.join('/') ?? '???' })}</h4>
            {/if}
          </div>

          {#if order.invoiceUrl}
            <div class="left-icon" in:scale|global={{ delay: 700 }}>
              {#key order.invoiceUrl}
                <canvas use:renderQrCode={{ url: order.invoiceUrl }}></canvas>
                <DrawnArrow>{$tC({ de: 'Das ist Ihre Rechnung! Einfach scannen und herunterladen!', en: 'This is your invoice! Just scan and download!' })}</DrawnArrow>
              {/key}
            </div>

            <div class="main-text">
              <div class="button-container" in:fly|global={{ x: 30, delay: 1200 }}>
                <BrandButton class="is-fullwidth" on:click={() => sendInvoice('sms')} loading={$sendingSms}><MessageCircle /> {$tC({ de: 'Rechnung per SMS erhalten', en: 'Get Invoice via SMS' })}</BrandButton>
              </div>
              <div class="button-container" in:fly|global={{ x: 30, delay: 1300 }}>
                <BrandButton class="is-fullwidth" on:click={() => sendInvoice('email')} loading={$sendingEmail}><Mail /> {$tC({ de: 'Rechnung per E-Mail erhalten', en: 'Get Invoice via Email' })}</BrandButton>
              </div>
              <p class="paper-invoice" in:fly|global={{ x: 30, delay: 1400 }}>{$tC({ de: 'Für eine Rechnung in Papierform wenden Sie sich bitte an einen Mitarbeiter.', en: 'For a paper invoice, please contact a member of staff.' })}</p>

              <div class="button-container mt-5" in:fly|global={{ x: 30, delay: 1500 }}>
                <BrandButton class="is-fullwidth is-primary" on:click={() => newOrder()}><Check /> {$tC({ de: 'Fertig', en: 'Done' })}</BrandButton>
              </div>
            </div>
          {:else}
            <div class="bottom-button mt-5 has-text-centered" in:fly|global={{ x: 30, delay: 900 }}>
              <p class="paper-invoice" in:fly|global={{ x: 30, delay: 1000 }}>{$tC({ de: 'Für eine Rechnung in Papierform wenden Sie sich bitte an einen Mitarbeiter.', en: 'For a paper invoice, please contact a member of staff.' })}</p>
              <BrandButton class="is-primary" on:click={() => newOrder()}><Check /> {$tC({ de: 'Fertig', en: 'Done' })}</BrandButton>
            </div>
          {/if}
        {/if}
      </section>
    {:else if $paymentState === 'failure'}
      <section class="failure" in:fly={{ y: 30, delay: 400 }} out:fly={{ y: 30 }}>
        <div class="left-icon" in:scale|global={{ delay: 500 }}>
          <Frown />
        </div>
        <div class="main-text">
          <h2 class="has-text-centered title is-2" in:fly|global={{ x: 30, delay: 700 }}>{$tC({ de: 'Das hat leider nicht geklappt.', en: 'That did not work, unfortunately.' })}</h2>

          <div class="button-container" in:fly|global={{ x: 30, delay: 900 }}>
            <BrandButton class="is-fullwidth is-primary" on:click={() => (startPayment($uiState.tipAmount))}>{$tC({ de: 'Erneut versuchen', en: 'Try Again' })}</BrandButton>
          </div>

          <div class="button-container" in:fly|global={{ x: 30, delay: 1100 }}>
            <BrandButton class="is-fullwidth" on:click={() => { $paymentState = 'payAtCounter'; updateAgeVerificationRequest(false) }}>{$tC({ de: 'An der Theke bezahlen', en: 'Pay at Counter' })}</BrandButton>
          </div>
        </div>
      </section>
    {:else if $paymentState === 'fatalError'}
      <section class="fatal-error" in:fly={{ y: 30, delay: 400 }} out:fly={{ y: 30 }}>
        <div class="left-icon" in:scale|global={{ delay: 500 }}>
          <Frown />
        </div>
        <div class="main-text">
          <h2 class="has-text-centered title is-2" in:fly|global={{ x: 30, delay: 700 }}>{$tC({ de: 'Leider ist ein Problem aufgetreten.', en: 'Unfortunately, an error occured.' })}</h2>
          <h3 class="has-text-centered title is-3" in:fly|global={{ x: 30, delay: 900 }}>{$tC({ de: 'Bitte wenden Sie sich an einen Mitarbeiter.', en: 'Please contact a member of staff.' })}</h3>
        </div>
      </section>
    {/if}
  </div>
</main>
