<script>
  import { tC } from '../stores/i18n'
  import { formatCurrency } from '../lib/utils'
  import SpicinessIndicator from './SpicinessIndicator.svelte'
  import DietIndicator from './DietIndicator.svelte'
  import NewBadge from './NewBadge.svelte'
  import { isMatchingFilter } from '../stores/session'

  export let article
  export let showOmnivoreIcon = false
  export let showNewBadge = false
  export let showPricePlus = false
  export let quantity = null
  export let multiline = false
  export let unavailable = false
</script>

<style lang="scss">
  article:not(.is-multiline) {
    // Name is left, has dynamic width but gets text-ellipsis when too long overall (suffix and price need to be always visible).
    // Suffix is shown directly to the right of name
    // Price is right-aligned
    display: flex;
    align-items: baseline;
    gap: 0.25em;
    white-space: nowrap;

    :global(.new-badge) {
      left: -4px;
      top: -3px;
      z-index: 1;
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .suffix-and-price {
      white-space: nowrap;
      flex: 1;
      display: flex;
      align-items: baseline;
      gap: 0.5em;
      justify-content: space-between;
    }
  }

  article.is-multiline {
    hyphens: auto;

    div {
      display: inline;
    }

    .suffix-and-price {
      float: right;
    }
  }

  article.is-unavailable {
    color: inherit;
    opacity: inherit;

    .name-inner, .price {
      color: $danger;
      text-decoration: line-through;
    }
  }
</style>

<article class="article-row" class:is-multiline={multiline} class:is-unavailable={unavailable}>
  {#if article}
    {#if article.newBadge && showNewBadge}
      <NewBadge />
    {/if}
    <div class="name">
      <slot />
      {#if quantity}
        <strong class="quantity">{quantity}&times;</strong>
      {/if}
      <span class="name-inner" class:is-not-matching-filter={!$isMatchingFilter(article)}>{$tC(article.name)}{#if multiline}{#if article.spiciness > 0}&nbsp;<SpicinessIndicator spiciness={article.spiciness} />{/if}&nbsp;<DietIndicator diet={article.diet} {showOmnivoreIcon} />{/if}</span>
    </div>
    <div class="suffix-and-price">
      {#if !multiline}
        <div class="suffix">
          <SpicinessIndicator spiciness={article.spiciness} />
          <DietIndicator diet={article.diet} {showOmnivoreIcon} />
        </div>
      {/if}
      <div class="price">
        {#if article.minPrice}
          &nbsp;{(showPricePlus && article.minPrice > 0) ? '+ ' : ''}{$tC && formatCurrency(article.minPrice * (quantity || 1))}
        {/if}
      </div>
    </div>
  {:else}
    <div class="name">
      <em>{$tC({ de: 'Unbekannter Artikel', en: 'Unknown article' })}</em>
    </div>
  {/if}
</article>
