<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { counterBundle } from '../stores/counterBundle'

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('close')
  }
</script>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Bestellung abgeholt', en: 'Order Colleted' })}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item"></div>
      </div>
    </div>

    <div class="big-buttons">
      <Button class="big-button" type="is-dark" on:click={close} iconLeft="arrow-left">{$tE({ de: 'Zurück', en: 'Back' })}</Button>

      {#each $counterBundle.readyReceiptNumbers as { receiptNumber }}
        <Button class="big-button" type="is-secondary" on:click={() => dispatch('close', receiptNumber)}>{receiptNumber}</Button>
      {/each}
    </div>
  </main>
</ModalDialog>
