<script>
  import { tC } from '../stores/i18n'
  import ModalDialog from './ModalDialog.svelte'
  import BrandButton from './BrandButton.svelte'
  import { createEventDispatcher } from 'svelte'
  import Check from 'svelte-feather/components/Check.svelte'

  const dispatch = createEventDispatcher()

  export let title
  export let text
  export let iconClass
  export let Icon
  export let yesNo = false
</script>

<style lang="scss">
  main {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    :global(svg) {
      width: 3rem;
      height: 3rem;
      stroke-width: 1;
    }

    span {
      white-space: pre-wrap;
    }
  }
</style>

<ModalDialog {title} closeable on:close>
  <main class="mb-4">
    {#if Icon}
      <span class={iconClass ?? ''}><svelte:component this={Icon} /></span>
    {/if}

    <span>{text}</span>
  </main>

  {#if yesNo}
    <div class="has-text-right">
      <BrandButton class="is-primary" on:click={() => dispatch('close', true)}>{$tC({ de: 'Ja', en: 'Yes' })}</BrandButton>
      <BrandButton on:click={() => dispatch('close', false)}>{$tC({ de: 'Nein', en: 'No' })}</BrandButton>
    </div>
  {:else}
    <div class="has-text-right">
      <BrandButton on:click={() => dispatch('close')}><Check /> {$tC({ de: 'OK', en: 'OK' })}</BrandButton>
    </div>
  {/if}
</ModalDialog>
