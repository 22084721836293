<script>
  import { tC } from '../stores/i18n'
</script>

<style lang="scss">
  .new-badge {
    position: absolute;
    left: -0.025rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.5rem;
    font-weight: bold;
    background-color: $danger;
    color: white;
    border-radius: 9999px;
    padding: 1px;
    aspect-ratio: 1;
  }
</style>

<div class="new-badge">{$tC({ de: 'Neu', en: 'New' })}</div>
