<script>
  import { fade } from 'svelte/transition'
  import { tC } from '../stores/i18n'
  import panzoom from 'panzoom'
  import { createEventDispatcher } from 'svelte'
  import { cubicOut } from 'svelte/easing'
  import { isVideo, appendToBody } from '../lib/utils'

  const dispatch = createEventDispatcher()

  export let src
  export let thumbnailElement

  let blockInput = true
  setTimeout(() => (blockInput = false), 400)

  $: srcIsVideo = isVideo(src)
  let videoError = false
  let videoLoaded = false
  let videoElement

  // Based on default scale animation
  function zoomFrom (node, { delay = 0, duration = 400, easing = cubicOut, start = 0, opacity = 0, originalX = null, originalY = null } = {}) {
    const style = getComputedStyle(node)
    const targetOpacity = Number(style.opacity)
    const transform = style.transform === 'none' ? '' : style.transform
    const sd = 1 - start
    const od = targetOpacity * (1 - opacity)
    let translateX = null
    let translateY = null
    if (originalX != null && originalY != null) {
      const currentX = (node.getBoundingClientRect().left + node.getBoundingClientRect().right) / 2
      const currentY = (node.getBoundingClientRect().top + node.getBoundingClientRect().bottom) / 2

      translateX = originalX - currentX
      translateY = originalY - currentY
    }
    return {
      delay,
      duration,
      easing,
      css: (_t, u) => `
        transform: ${transform} scale(${1 - sd * u}) ${translateX != null ? `translate(${translateX * u / (1 - u)}px, ${translateY * u / (1 - u)}px)` : ''};
        opacity: ${targetOpacity - od * u}
      `
    }
  }

  function panAndZoom (element) {
    const pz = panzoom(element, {
      maxZoom: 2.5,
      minZoom: 1,
      bounds: true,
      boundsPadding: 0.5,
      beforeMouseDown: (e) => {
        if (e.target.tagName === 'IMG') {
          e.preventDefault()
        }
      }
    })

    return {
      destroy: () => pz.dispose()
    }
  }

  function close () {
    videoElement?.pause()

    dispatch('close')
  }

  async function loadImage (src) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
    })
  }
</script>

<style lang="scss">
  main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 40;

    .block-input {
      pointer-events: none;
    }

    .image-zoomer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .close-button {
      position: fixed;
      top: 16px;
      right: 16px;
      cursor: pointer;
      z-index: 31;

      width: 64px;
      height: 64px;
      min-width: 64px;
      min-height: 64px;
      max-width: 64px;
      max-height: 64px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: grab;
      filter: drop-shadow(2px 4px 6px black);
    }

    video {
      width: 100%;
      height: 100%;

      &::-webkit-media-controls-fullscreen-button {
        display: none;
      }
    }
  }
</style>

<main transition:fade use:appendToBody>
  {#if srcIsVideo}
    {#if !videoError}
      <video style:display={videoLoaded ? 'block' : 'none'} controls controlsList="nofullscreen nodownload noremoteplayback" disablepictureinpicture autoplay on:ended={close} on:canplay={() => (videoLoaded = true)} on:error={() => (videoError = true)} bind:this={videoElement}>
        <source {src} type="video/mp4">
        <p class="has-text-light">{$tC({ de: 'Ihr Browser unterstützt keine Videos', en: 'Your browser does not support videos' })}</p>
      </video>
      {#if !videoLoaded}
        <div class="image-zoomer">
          <div class="loader is-large"></div>
        </div>
      {/if}
    {:else}
      <div class="image-zoomer">
        <p class="has-text-light">{$tC({ de: 'Fehler beim Laden des Videos', en: 'Error loading video' })}</p>
      </div>
    {/if}
  {:else}
    {#await loadImage(src)}
      <div class="image-zoomer" class:block-input={blockInput}>
        <div class="loader is-large" in:fade|global={{ delay: 1000, duration: 200 }}></div>
      </div>
    {:then}
      <div class="image-zoomer" class:block-input={blockInput} transition:zoomFrom|global={{
        originalX: thumbnailElement ? (thumbnailElement.getBoundingClientRect().left + thumbnailElement.getBoundingClientRect().right) / 2 : null,
        originalY: thumbnailElement ? (thumbnailElement.getBoundingClientRect().top + thumbnailElement.getBoundingClientRect().bottom) / 2 : null
      }}>
        <img {src} alt={$tC({ de: 'Bild', en: 'Image' })} use:panAndZoom>
      </div>
      <div class="close-button delete is-large" on:click={close}></div>
    {:catch}
      <div class="image-zoomer">
        <p class="has-text-light">{$tC({ de: 'Fehler beim Laden des Bildes', en: 'Error loading image' })}</p>
      </div>
    {/await}
  {/if}
  <div class="close-button delete is-large" on:click={close}></div>
</main>
