<style lange="scss">
  svg {
    width: var(--width, auto);
    height: var(--height, 0.75em);
    color: var(--color, currentColor);
  }
</style>

<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="131.000000pt" height="231.000000pt" viewBox="0 0 131.000000 231.000000" preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,231.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
    <path d="M590 2206 c0 -32 2 -34 47 -40 69 -9 127 -47 153 -101 20 -40 25 -45 56 -45 41 0 43 12 13 75 -39 83 -138 145 -231 145 -36 0 -38 -1 -38 -34z"/>
    <path d="M703 1920 c-17 -10 -37 -28 -43 -39 l-11 -21 53 21 c29 12 59 23 66 26 21 9 41 -16 59 -73 9 -30 20 -54 23 -54 3 0 14 24 23 54 25 78 34 82 108 57 66 -22 80 -21 59 4 -30 36 -67 45 -187 45 -99 0 -123 -3 -150 -20z"/>
    <path d="M695 1799 c-27 -11 -51 -22 -53 -24 -2 -1 15 -50 38 -109 85 -224 112 -395 91 -577 -43 -379 -221 -659 -564 -888 -75 -50 -137 -95 -137 -100 0 -18 52 -21 159 -11 144 13 261 48 397 115 287 143 492 403 581 735 34 127 43 352 20 485 -18 100 -57 225 -93 297 -22 44 -27 47 -104 73 -45 15 -83 25 -84 24 -2 -2 -18 -49 -37 -104 -31 -91 -36 -100 -59 -100 -23 0 -28 9 -60 103 -19 56 -37 102 -40 101 -3 0 -27 -9 -55 -20z"/>
  </g>
</svg>
