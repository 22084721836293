<style lange="scss">
  svg {
    width: var(--width, auto);
    height: var(--height, 0.75em);
    color: var(--color, currentColor);
  }
</style>

<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="324.000000pt" height="441.000000pt" viewBox="0 0 324.000000 441.000000" preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,441.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
    <path d="M2571 4173 c-130 -267 -328 -491 -520 -588 -29 -14 -97 -42 -150 -60 -166 -58 -555 -253 -815 -407 -361 -216 -618 -527 -729 -883 -59 -190 -81 -387 -81 -740 0 -228 10 -433 20 -422 2 2 24 78 49 168 131 489 307 825 556 1064 100 96 169 146 350 253 371 220 740 496 1087 810 56 51 102 89 102 84 0 -20 -101 -162 -176 -247 -194 -218 -539 -486 -934 -723 -189 -113 -317 -228 -467 -422 -312 -403 -507 -962 -530 -1524 -6 -148 -6 -149 18 -167 39 -30 48 -24 51 34 l3 52 65 33 c117 58 252 75 383 47 91 -19 158 -51 241 -115 92 -70 307 -181 428 -219 132 -42 224 -55 343 -48 97 5 106 8 292 85 250 105 343 169 506 351 104 117 212 291 233 375 6 26 5 28 -11 22 -87 -33 -189 -50 -300 -51 -131 0 -185 13 -282 66 -117 65 -478 196 -615 223 -116 24 -304 21 -409 -5 -173 -44 -332 -147 -553 -359 -64 -62 -116 -115 -116 -119 0 -3 60 23 133 59 331 164 574 197 849 115 251 -75 447 -116 698 -147 202 -24 212 -32 55 -44 -151 -12 -449 36 -696 112 -123 38 -126 39 -294 39 -187 0 -265 -12 -428 -67 -149 -49 -405 -187 -484 -261 -29 -26 -29 -14 2 79 103 307 353 566 635 657 41 14 125 32 185 42 185 28 501 141 701 250 268 146 490 342 600 531 36 61 242 633 265 734 31 138 44 363 31 547 -14 193 -50 412 -92 553 -35 118 -115 313 -134 329 -10 9 -25 -13 -65 -96z"/>
  </g>
</svg>
