<script>
  import ErrorBox from '../components/ErrorBox.svelte'
</script>

<svelte:head>
  <title>Four-Oh-Four - YaoOrder</title>
</svelte:head>

<div class="container">
  <ErrorBox text="Nope, nothing here">
    Well, whatever you were looking for, it's not here. Maybe somewhere else. No idea where though.<br/>
      You may be better off going back to the <a href="/">homepage</a>.
  </ErrorBox>
</div>
