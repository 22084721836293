<script>
  import { tC } from '../stores/i18n'
  import MobileTooltip from './MobileTooltip.svelte'
  import Leaf from './icons/Leaf.svelte'
  import LeafStroke from './icons/LeafStroke.svelte'

  export let diet // Careful! Setting a default here only works for the first render
  export let withTooltip = false
  export let showOmnivoreIcon = false

  $: text = {
    vegan: $tC({ de: 'Vegan', en: 'Vegan' }),
    vegetarian: $tC({ de: 'Vegetarisch', en: 'Vegetarian' }),
    omnivore: $tC({ de: 'Mit Fleisch/Fisch', en: 'With Meat/Fish' }),
    na: ''
  }[diet] ?? diet ?? ''

  $: show = (diet && diet !== 'na') && (diet !== 'omnivore' || showOmnivoreIcon)

  $: color = {
    vegan: '#57c52b',
    vegetarian: '#00811c',
    omnivore: '#a70303'
  }[diet] || 'currentColor'

  $: Icon = diet === 'omnivore' ? LeafStroke : Leaf
</script>

<style lang="scss">
  .diet {
    display: inline-block;
  }
</style>

{#if show}
  {#if withTooltip}
    <MobileTooltip label={text}><span class="diet"><svelte:component this={Icon} --color={color} /></span></MobileTooltip>
  {:else}
    <span class="diet"><svelte:component this={Icon} --color={color} /></span>
  {/if}
{/if}
