<script>
  import { scale } from 'svelte/transition'
  import { tE } from '../stores/i18n'
  import { appendToBody } from '../lib/utils'
  import { Button } from 'svelma-fixed'
  import counterBundle, { ageVerificationRequests } from '../stores/counterBundle'
  import { apiCall } from '../lib/api'

  export let sessionId

  async function updateAgeVerification (ageVerified) {
    ageVerificationRequests.updateRequest(sessionId, false)
    await apiCall('POST', '/api/app/setAgeVerification', { sessionId, ageVerified })
  }
</script>

<style lang="scss">
  main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background: $warning;
    color: $text;
    font-size: 3rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    z-index: 901;

    div {
      font-size: 4rem;
      padding: 4rem;

      i {
        font-size: 6rem;

        animation: blink 0.5s infinite;
      }

      .button-row {
        font-size: 1rem;
        margin: 2rem 0;
      }
    }
  }

  @keyframes blink {
    0% { opacity: 1; }
    49% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
  }
</style>

{#key sessionId}
  <main transition:scale|global use:appendToBody>
    <div>
      <i class="fa fa-cocktail"></i>
    </div>

    <div>
      <p>{$tE({ de: 'Tisch', en: 'Table' })} <strong>{Object.values($counterBundle.tables).find(table => table.currentSession?.id === sessionId)?.number ?? '???'}</strong>:</p>
      <p>{$tE({ de: 'Alter 18+ OK?', en: 'Age 18+ OK?' })}</p>
      <p class="button-row">
        <Button class="big-button" type="is-success" iconLeft="check" on:click={() => updateAgeVerification(true)}>{$tE({ de: 'Ja', en: 'Yes' })}</Button>
        <Button class="big-button" type="is-danger" iconLeft="times" on:click={() => updateAgeVerification(false)}>{$tE({ de: 'Nein', en: 'No' })}</Button>
      </p>
    </div>

    <audio src="/misc/notification.mp3" autoplay></audio>
  </main>
{/key}
