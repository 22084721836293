<style lang="scss">
  div {
    display: flex;
    flex-direction: row;
    gap: 0.25em;

    :global(.brand-button.is-fullwidth), :global(.tooltip-wrapper:has(.brand-button.is-fullwidth)) {
      width: auto;
      flex: 1;
    }

    :global(.tooltip-wrapper .tooltip-trigger), :global(.tooltip-wrapper .brand-button.is-fullwidth) {
      width: 100%;
    }
  }

  :global(:not(.brand-button-row).mb-1 + .brand-button-row.mt-1, :not(.brand-button-row).mb-1 + .brand-button-row.my-1, :not(.brand-button-row).my-1 + .brand-button-row.mt-1, .my-1 + :not(.brand-button-row).brand-button-row.my-1) {
    margin-top: 0.5rem !important; // Simulate non-margin-collapsing
  }
</style>

<div class="brand-button-row {$$props.class ?? ''}">
  <slot />
</div>
