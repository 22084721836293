<script>
  import { tC } from '../stores/i18n'
  import BrandButton from './BrandButton.svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Field, Input } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import Check from 'svelte-feather/components/Check.svelte'

  const dispatch = createEventDispatcher()

  export let comment = ''
  let formEl

  function submit () {
    dispatch('close', comment.trim())
  }
</script>

<style lang="scss">
</style>

<ModalDialog class="dialog-top-half" title={$tC({ de: 'Kommentar/Sonderwunsch', en: 'Comment / Special Request' })} closeable on:close on:introend={formEl.querySelector('textarea').focus()}>
  <form on:submit|preventDefault={submit} bind:this={formEl}>
    <Field>
      <Input type="textarea" bind:value={comment} />
    </Field>

    <BrandButton type="submit" class="is-fullwidth is-primary"><Check /> {$tC({ de: 'Speichern', en: 'Save' })}</BrandButton>
  </form>
</ModalDialog>
