import { signalColors } from '../../payload/i18nConstants'

export function getLedTargetSetting (table, settings) {
  if (settings?.powerSwitch.mode === 'off' && !['occupied', 'reserved'].includes(table.status)) return 'off'
  if (settings?.powerSwitch.mode === 'event' && !['occupied', 'reserved'].includes(table.status)) return 'white'
  if (table.type === 'kiosk') return 'off'
  if (table.status === 'free') return 'green'
  if (table.status === 'blocked' || table.status === 'disabled' || table.status === 'cleaning') return 'red'
  if (table.status === 'reserved' || (table.status === 'occupied' && new Date(table.signalledSince).valueOf() > Date.now() - 60000)) return '!' + table.signalColor
  if (table.status === 'occupied') return 'white'
  return 'off'
}

export function getSignalColor (currentLedLampSetting) {
  currentLedLampSetting = currentLedLampSetting?.replace('!', '')
  if (currentLedLampSetting === 'off') return 'grey'
  if (['green', 'red', 'white'].includes(currentLedLampSetting)) return currentLedLampSetting
  const rgb = signalColors.find(color => color.value === currentLedLampSetting)?.color
  if (!rgb) return 'black'
  return `rgb(${rgb.join(', ')})`
}
