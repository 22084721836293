import { createLoadingStore } from './loading'

let scheduled = []

export const critical = createLoadingStore()

export let isCritical

critical.subscribe($critical => {
  isCritical = $critical

  console.log('Critical state changed', isCritical)

  if (!isCritical) executeScheduled()
})

function executeScheduled () {
  const toExecute = scheduled
  scheduled = []
  for (const { fn } of toExecute) {
    try {
      fn()
    } catch (e) {
      console.error('Failed to execute scheduled function', e)
    }
  }
}

export function executeWhenNotCritical (id, fn) {
  if (!isCritical) {
    fn()
  } else {
    console.log('Scheduling execution (currently in critical state)', id, fn)
    if (id) scheduled = scheduled.filter(s => s.id !== id)
    scheduled.push({ id, fn })
  }
}

export default critical
