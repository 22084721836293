<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Dialog, Toast } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { apiCall } from '../lib/api'
  import counterBundle, { updateCounterBundle } from '../stores/counterBundle'
  import { getProcessNumber } from '../../shared/lib/misc'
  import { formatCurrency } from '../lib/utils'
  import { createLoadingStore } from '../stores/loading'
  import bundle from '../stores/bundle'
  import html from 'html-template-tag'

  const dispatch = createEventDispatcher()
  const loading = createLoadingStore()

  export let order

  function close () {
    dispatch('close')
  }

  async function markReady () {
    loading(async () => {
      for (const receiptNumber of order.receiptNumbers) {
        try {
          const { smsError } = await apiCall('POST', '/api/app/submitReadyReceipt', { receiptNumber })

          await updateCounterBundle().catch(e => {})

          Toast.create({ message: $tE({ de: 'Bestellung als fertig markiert.', en: 'Order marked as ready.' }), type: 'is-success' })
          if (smsError) {
            Toast.create({ message: $tE({ de: 'SMS konnte nicht gesendet werden!', en: 'SMS could not be sent!' }), type: 'is-danger' })
          }
        } catch (e) {
          Dialog.alert({
            message: html`
              <p class="mb-3">
                ${$tE({ de: 'Fehler beim Ausführen der Aktion:', en: 'Error executing action:' })}
              </p>
              <p>
                ${e.serverErrorMessage ?? e.message}
              </p>
            `,
            type: 'is-danger',
            icon: 'exclamation-circle'
          })
        }
      }

      dispatch('close', { ready: true })
    })
  }
</script>

<style lang="scss">
  main {
    .details-col .level {
      margin-bottom: 0.25em !important;
      font-size: 1.5em;
    }

    .order-id {
      font-size: 0.75em;
      margin-top: -2em;
      margin-left: 0.15em;
    }

    .cart-area {
      max-height: 28rem;
      overflow-y: auto;

      label:nth-child(odd) .cart-row {
        background: #f2f2f2;
      }

      label:nth-child(even) .cart-row {
        background: #fcfcfc;
      }

      .cart-row {
        display: flex;
        gap: 0.25rem;
        align-items: center;
        padding: 0.5rem 0;
        border-top: 1px solid #ccc;

        &.sub-article {
          padding-left: 2rem;
        }

        &.comment {
          padding-left: 5rem;
          font-style: italic;
        }

        .quantity {
          flex: 0 0 2rem;
          text-align: right;
        }

        .name {
          flex: 1;
        }

        .price {
          flex: 0 0 6rem;
          text-align: right;
        }
      }
    }
  }
</style>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Bestellungs-Details', en: 'Order Details' })}</h1>
        </div>
      </div>
      <div class="level-right">
        {#if !order.ready && order.receiptNumbers?.length}
          <div class="level-item">
            <Button class="big-button semi-big tight" type="is-success" on:click={markReady} iconLeft="check-square" loading={$loading}>{$tE({ de: 'Fertig', en: 'Ready' })}</Button>
          </div>
        {/if}
        <div class="level-item">
          <Button class="big-button semi-big tight" type="is-dark" on:click={close} iconLeft="arrow-left">{$tE({ de: 'Zurück', en: 'Back' })}</Button>
        </div>
      </div>
    </div>

    <div class="order-id has-text-light-grey">ID: {order.id}</div>

    {#if order.error}
      <div class="notification is-danger"><strong>{$tE({ de: 'Fehler im Buchungsvorgang', en: 'Error in Ordering Process' })}</strong>: {order.error}</div>
    {/if}

    <div class="columns">
      <div class="column is-4 details-col">
        <div class="level">
          <div class="level-left"><div class="level-item">{$tE({ de: 'Zeit', en: 'Time' })}</div></div>
          <div class="level-right"><div class="level-item">{new Date(order.createdAt).toLocaleTimeString()}</div></div>
        </div>
        <div class="level">
          <div class="level-left"><div class="level-item">{$tE({ de: 'Tisch', en: 'Table' })}</div></div>
          <div class="level-right"><div class="level-item"><strong>{$counterBundle.tables[order.table]?.number ?? '???'}</strong></div></div>
        </div>
        <div class="level">
          <div class="level-left"><div class="level-item">{$tE({ de: 'Geräte-ID', en: 'Device ID' })}</div></div>
          <div class="level-right"><div class="level-item">{order.deviceId ?? '???'}</div></div>
        </div>
        <div class="level">
          <div class="level-left"><div class="level-item">{$tE({ de: 'Vorgang', en: 'Process' })}</div></div>
          <div class="level-right"><div class="level-item" class:has-text-light-grey={$counterBundle.tables[order.table]?.type !== 'kiosk'}>{getProcessNumber(order.session.id)}</div></div>
        </div>
        <div class="level">
          <div class="level-left"><div class="level-item">{$tE({ de: 'Bestellnr.', en: 'Order Number' })}</div></div>
          <div class="level-right"><div class="level-item"><strong>{order.receiptNumbers?.join('/') || '---'}</strong></div></div>
        </div>
        <div class="level">
          <div class="level-left"><div class="level-item">{$tE({ de: 'Rechnungsnr.', en: 'Invoice Number' })}</div></div>
          <div class="level-right"><div class="level-item">{order.invoiceNumber || '---'}</div></div>
        </div>
        <div class="level">
          <div class="level-left"><div class="level-item">{$tE({ de: 'Betrag', en: 'Amount' })}</div></div>
          <div class="level-right"><div class="level-item">{$tE && formatCurrency(order.totalAmount, true)}</div></div>
        </div>
        <div class="level">
          <div class="level-left"><div class="level-item">{$tE({ de: 'Tel.', en: 'Phone' })}</div></div>
          <div class="level-right"><div class="level-item">{order.session.userData.phoneNumber || '---'}</div></div>
        </div>
        {#if order.paidOnDevice}
          <div class="level">
            <div class="level-left"><div class="level-item"><i class="fa fa-fw fa-credit-card has-text-primary"></i> {$tE({ de: 'Bezahlt am Gerät', en: 'Paid on Device' })}</div></div>
            <div class="level-right"></div>
          </div>
        {/if}
        {#if order.paidAtCounter}
          <div class="level">
            <div class="level-left"><div class="level-item"><i class="fa fa-fw fa-cash-register has-text-primary"></i> {$tE({ de: 'An der Theke bezahlt', en: 'Paid at Counter' })}</div></div>
            <div class="level-right"></div>
          </div>
        {/if}
        {#if order.bookedOrder}
          <div class="level">
            <div class="level-left"><div class="level-item"><i class="fa fa-fw fa-share has-text-primary"></i> {$tE({ de: 'Bestellung verbucht', en: 'Order Booked' })}</div></div>
            <div class="level-right"></div>
          </div>
        {/if}
        {#if order.bookedInvoice}
          <div class="level">
            <div class="level-left"><div class="level-item"><i class="fa fa-fw fa-receipt has-text-primary"></i> {$tE({ de: 'Rechnung verbucht', en: 'Invoice Booked' })}</div></div>
            <div class="level-right"></div>
          </div>
        {/if}
        {#if order.ready}
          <div class="level">
            <div class="level-left"><div class="level-item"><i class="fa fa-fw fa-check-square has-text-primary"></i> {$tE({ de: 'Fertig', en: 'Ready' })}</div></div>
            <div class="level-right"></div>
          </div>
        {/if}
        {#if order.error}
          <div class="level">
            <div class="level-left"><div class="level-item"><i class="fa fa-fw fa-exclamation-triangle has-text-danger"></i> {$tE({ de: 'Fehler', en: 'Error' })}</div></div>
            <div class="level-right"></div>
          </div>
        {/if}
      </div>

      <div class="column is-8">
        <div class="cart-area">
          {#each order.articles as item}
            <label>
              <div class="cart-row">
                <div class="quantity">{item.quantity}&times;</div>
                <div class="name">{$tE($bundle.articles[item.article].name ?? { de: 'Unbekannt', en: 'Unknown' })}</div>
                <div class="price">{$tE && formatCurrency($bundle.articles[item.article]?.posArticle?.price * item.quantity, true)}</div>
              </div>
            </label>

            {#each item.subArticles as subArticleInfo}
              <label>
                <div class="cart-row sub-article">
                  <div class="quantity">{subArticleInfo.quantity * item.quantity}&times;</div>
                  <div class="name">{$tE($bundle.articles[subArticleInfo.article].name ?? { de: 'Unbekannt', en: 'Unknown' })}</div>
                  <div class="price">{$tE && formatCurrency($bundle.articles[subArticleInfo.article]?.posArticle?.price * subArticleInfo.quantity * item.quantity, true)}</div>
                </div>
              </label>
            {/each}

            {#if item.comment}
              <div class="cart-row comment">
                <div class="comment">
                  {item.comment}
                </div>
              </div>
            {/if}
          {/each}

          {#if order.tipAmount}
            <label>
              <div class="cart-row">
                <div class="quantity"><i class="fa fa-beer"></i></div>
                <div class="name">{$tE({ de: 'Trinkgeld', en: 'Tip' })}</div>
                <div class="price">{$tE && formatCurrency(order.tipAmount, true)}</div>
              </div>
            </label>
          {/if}
        </div>
      </div>
    </div>
  </main>
</ModalDialog>
