<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Dialog, Toast } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { formatCurrency, withProcessingOverlay } from '../lib/utils'
  import { apiCall } from '../lib/api'
  import counterBundle, { updateCounterBundle } from '../stores/counterBundle'
  import bundle from '../stores/bundle'
  import html from 'html-template-tag'
  import uri from 'uri-tag'
  import { getCartTotal } from '../stores/session'
  import { getProcessNumber } from '../../shared/lib/misc'

  export let sessionId

  $: session = $counterBundle.payAtCounterSessions.find(s => s.id === sessionId)

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('close')
  }

  $: if (!session) close()

  let selected
  $: if (session && !selected) {
    selected = Object.fromEntries(session.cart.map(item => item.uuid).concat(session.cart.flatMap(item => item.subArticles.map(subArticle => subArticle.uuid))).map(uuid => [uuid, true]))
  }

  $: filteredCart = session?.cart.filter(item => selected[item.uuid]).map(item => ({
    ...item,
    subArticles: item.subArticles.filter(subArticle => selected[subArticle.uuid])
  })) ?? []

  $: filteredTotal = getCartTotal(filteredCart, $bundle)

  $: overallAgeRestriction = Math.max(0, ...filteredCart.flatMap(item => [item, ...item.subArticles]).map(item => $bundle.articles[item.article]?.ageRestriction ?? 0))

  async function bookNow () {
    await withProcessingOverlay(async () => {
      try {
        const { receiptNumbers } = await apiCall('POST', uri`/api/app/order`, { sessionId: session.id, cart: filteredCart, cartTotal: filteredTotal, pac: true })

        await updateCounterBundle()

        close()

        Dialog.alert({
          message: html`
            <p class="mb-3">
              ${$tE({ de: 'Bestellung erfolgreich eingebucht.', en: 'Order successfully booked.' })}
            </p>
            <p>
              ${$tE({ de: 'Belegnummer:', en: 'Receipt number:' })} <strong style="font-size: 200%;">${receiptNumbers.join(', ')}</strong>
            </p>
          `,
          type: 'is-success',
          icon: 'check-circle',
          size: 'is-large'
        })
      } catch (e) {
        Dialog.alert({
          message: html`
            <p class="mb-3">
              ${$tE({ de: 'Fehler beim Ausführen der Aktion:', en: 'Error executing action:' })}
            </p>
            <p>
              ${e.serverErrorMessage ?? e.message}
            </p>
          `,
          type: 'is-danger',
          icon: 'exclamation-circle'
        })
      }
    })
  }

  async function deletePendingOrder () {
    await withProcessingOverlay(async () => {
      try {
        await apiCall('POST', uri`/api/app/closeSession`, { id: session.id, closePac: true })

        await updateCounterBundle()

        close()

        Toast.create({ message: $tE({ de: 'Bestellung erfolgreich gelöscht.', en: 'Order successfully deleted.' }), type: 'is-success' })
      } catch (e) {
        Dialog.alert({
          message: html`
            <p class="mb-3">
              ${$tE({ de: 'Fehler beim Ausführen der Aktion:', en: 'Error executing action:' })}
            </p>
            <p>
              ${e.serverErrorMessage ?? e.message}
            </p>
          `,
          type: 'is-danger',
          icon: 'exclamation-circle'
        })
      }
    })
  }
</script>

<style lang="scss">
  .cart-area {
    max-height: 28rem;
    overflow-y: auto;

    label:nth-child(odd) .cart-row {
      background: #f2f2f2;
    }

    label:nth-child(even) .cart-row {
      background: #fcfcfc;
    }

    .cart-row {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      padding: 0.5rem 0;
      border-top: 1px solid #ccc;

      &.sub-article {
        padding-left: 2rem;
      }

      &.comment {
        padding-left: 5rem;
        font-style: italic;
      }

      &.is-disabled > :not(.checkbox-col) {
        opacity: 0.5;
      }

      .checkbox-col {
        flex: 0 0 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          width: 1rem;
          height: 1rem;
        }
      }

      .quantity {
        flex: 0 0 2rem;
        text-align: right;
      }

      .name {
        flex: 1;
      }

      .price {
        flex: 0 0 6rem;
        text-align: right;
      }
    }
  }
</style>

<ModalDialog --width="95vw" class="employee-ui">
  {#if session}
    <main>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title is-1">{$tE({ de: 'Tisch', en: 'Table' })} {session.table.number} / Vorgang {getProcessNumber(session.id)}</h1>
          </div>
        </div>
        <div class="level-right">
          <h3 class="title is-3 my-0">
            {#if overallAgeRestriction > 0}
              <div class="tag is-danger is-large">{$tE({ de: 'Alter {age}+', en: 'Age {age}+' }, { age: overallAgeRestriction })}</div>
            {/if}
            {$tE && formatCurrency(filteredTotal, true)}
          </h3>
        </div>
      </div>

      <div class="columns">
        <div class="column is-4">
          <div class="big-buttons">
            <Button class="big-button" type="is-dark" on:click={close} iconLeft="times">{$tE({ de: 'Schließen', en: 'Close' })}</Button>
            <Button class="big-button" type="is-success" disabled={!filteredCart.length} on:click={bookNow} iconLeft="share">{$tE({ de: 'In Kassa einbuchen', en: 'Book into PoS' })}</Button>
            <Button class="big-button" type="is-danger" on:click={deletePendingOrder} iconLeft="trash">{$tE({ de: 'Löschen', en: 'Delete' })}</Button>
          </div>
        </div>

        <div class="column is-8">
          <div class="cart-area">
            {#each session.cart as item}
              <label>
                <div class="cart-row" class:is-disabled={!selected[item.uuid]}>
                  <div class="checkbox-col">
                    <input type="checkbox" bind:checked={selected[item.uuid]} on:change={() => { item.subArticles.forEach(subArticle => (selected[subArticle.uuid] = selected[item.uuid])) }} />
                  </div>
                  <div class="quantity">{item.quantity}&times;</div>
                  <div class="name">{$tE($bundle.articles[item.article].name ?? { de: 'Unbekannt', en: 'Unknown' })}</div>
                  <div class="price">{$tE && formatCurrency($bundle.articles[item.article]?.posArticle?.price * item.quantity, true)}</div>
                </div>
              </label>

              {#each item.subArticles as subArticleInfo}
                <label>
                  <div class="cart-row sub-article" class:is-disabled={!selected[subArticleInfo.uuid]}>
                    <div class="checkbox-col">
                      <input type="checkbox" bind:checked={selected[subArticleInfo.uuid]} on:change={() => { if (selected[subArticleInfo.uuid]) selected[item.uuid] = true }} />
                    </div>
                    <div class="quantity">{subArticleInfo.quantity * item.quantity}&times;</div>
                    <div class="name">{$tE($bundle.articles[subArticleInfo.article].name ?? { de: 'Unbekannt', en: 'Unknown' })}</div>
                    <div class="price">{$tE && formatCurrency($bundle.articles[subArticleInfo.article]?.posArticle?.price * subArticleInfo.quantity * item.quantity, true)}</div>
                  </div>
                </label>
              {/each}

              {#if item.comment}
                <div class="cart-row comment" class:is-disabled={!selected[item.uuid]}>
                  <div class="comment">
                    {item.comment}
                  </div>
                </div>
              {/if}
            {/each}
          </div>
        </div>
      </div>
    </main>
  {/if}
</ModalDialog>
