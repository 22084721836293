export const SUSPENDED_QUEUE_INDEX = -10000

export function getEmptySession (language, tableId, deviceId) {
  return {
    id: null,
    updatedAt: new Date().toISOString(),
    table: tableId ?? null,
    status: 'active',
    startTime: null,
    lastActivityTime: new Date().toISOString(),
    finishTime: null,
    userData: {
      language,
      fontSize: 3,
      phoneNumber: null,
      email: null,
      marketingConsentSms: false,
      marketingConsentEmail: false,
      allergenFilter: [],
      dietFilter: 'omnivore'
    },
    uiState: {
      currentView: 'welcome',
      welcomeSubView: null,
      selectedCategoryId: null,
      selectedArticleId: null,
      articleConfigurations: [],
      history: [],
      historyIndex: -1
    },
    lastDeviceId: deviceId ?? null,
    cart: [],
    wishlist: [],
    orders: []
  }
}

export function funhash (s) {
  let h = 0xdeadbeef
  for (let i = 0; i < s.length; i++) {
    h = Math.imul(h ^ s.charCodeAt(i), 2654435761)
  }
  return (h ^ h >>> 16) >>> 0
}

export function getProcessNumber (sessionId) {
  return (funhash(sessionId) % 1e3).toString().padStart(3, '0')
}
