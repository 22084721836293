<script>
  import { rippleOnTouch } from '../lib/utils'
  import { scale } from 'svelte/transition'
  import { tC } from '../stores/i18n'

  export let toggle = false
  export let selected = false
  export let icon = false
  export let invisible = false
  export let disabled = false
  export let small = false
  export let type = 'button'
  export let unavailable = false
  export let loading = false
</script>

<style lang="scss">
  :global(button.brand-button:has(article.article-row)) {
    overflow: visible !important;
  }

  :global(button.brand-button.is-fullwidth) {
    width: 100%;
  }

  button {
    background-color: var(--bg-color, transparent);
    border: 1px solid var(--border-color, #{$brand-color});
    color: var(--fg-color, #{$brand-color});
    box-shadow: none;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    border-radius: 9999px;
    padding: 8px 20px 7px;
    height: calc(1.18em + 18px);

    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.is-invisible {
      visibility: hidden;
      pointer-events: none;
    }

    &[data-unavailable]::after {
      font-size: 1rem;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $brand-color;
        color: white;
      }

      &:active {
        opacity: 0.9;
      }
    }

    &.is-selected {
      background-color: mix($brand-color, white, 65%);
      color: white;
    }

    &.is-primary {
      background-color: $brand-color;
      color: white;

      &:active {
        background-color: darken($brand-color, 10%);
      }
    }

    &:not(.toggle):focus {
      transition: background-color 2s, color 2s;
    }

    &:not(.toggle):active {
      transition: background-color 0.1s, color 0.1s;
    }

    &:active {
      background-color: $brand-color;
      color: white;
    }

    &.toggle {
      transition: background-color 0.2s color 0.2s;
    }

    :global(.ripple-container) {
      border-radius: 9999px;
    }

    :global(svg) {
      vertical-align: middle;
      transform: translateY(-0.1em) scale(1.5);

      width: 1em;
      height: 1em;
      stroke-width: 1px;

      margin-right: 0.35em;
    }

    &.is-icon {
      padding: 8px 9px 7px;
      width: calc(1.18em + 18px);
      text-overflow: clip;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      :global(svg) {
        transform: scale(2) translate(0.01em, -0.01em);
        margin-right: 0;
      }

      &.small {
        width: 1.75em;
        height: 1.75em;

        :global(svg) {
          width: 1em;
          height: 1em;
          transform: translateY(-0.1em) scale(1.5);
        }
      }
    }

    &.is-loading::after {
      border-left-color: $brand-color;
      border-bottom-color: $brand-color;
    }

    &.is-primary.is-loading::after {
      border-left-color: white;
      border-bottom-color: white;
    }
  }
</style>

<button {type} class="brand-button {$$props.class ?? ''}" class:is-selected={selected} class:toggle class:is-icon={icon} class:is-invisible={invisible} class:small class:disabled class:is-loading={loading} use:rippleOnTouch on:click|stopPropagation in:scale={{ duration: 200 }} {...unavailable ? { 'data-unavailable': $tC({ de: 'Nicht verfügbar', en: 'Not Available' }) } : {}}>
  <span><slot /></span>
</button>
