<svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 520.000000 520.000000" preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,520.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
    <path d="M3079 4843 c-62 -32 -113 -93 -130 -155 -6 -25 -9 -109 -7 -217 3 -196 11 -222 80 -288 66 -64 85 -67 378 -73 l265 -5 0 -80 0 -80 -549 -5 -549 -5 -14 -22 c-14 -20 -13 -24 11 -48 l25 -25 840 0 840 0 20 -26 c21 -26 21 -35 19 -413 l-3 -386 -1705 0 -1705 0 -3 386 c-2 378 -2 387 19 413 17 22 28 26 69 26 86 0 125 31 95 76 -13 21 -23 24 -73 24 -100 -1 -178 -54 -202 -138 -6 -23 -10 -195 -10 -445 l0 -407 -161 -723 c-88 -397 -163 -725 -167 -729 -4 -3 -61 -8 -128 -10 -112 -3 -123 -5 -162 -31 -23 -15 -54 -46 -69 -69 l-28 -42 -3 -420 -3 -419 25 -52 c14 -28 39 -61 56 -73 66 -50 89 -52 462 -52 222 0 356 4 369 10 26 14 26 66 0 80 -13 6 -149 10 -376 10 -387 0 -396 1 -425 56 -13 22 -15 95 -15 430 0 393 1 403 21 431 11 15 36 33 55 40 49 17 4669 17 4718 0 19 -7 44 -25 55 -40 20 -28 21 -38 21 -431 0 -439 0 -443 -57 -472 -23 -12 -301 -14 -1875 -14 -1657 0 -1851 -2 -1871 -16 -28 -19 -28 -49 0 -68 20 -14 213 -16 1864 -16 1990 0 1880 -3 1954 52 17 12 42 45 56 73 l25 52 -3 419 -3 420 -28 42 c-15 23 -46 54 -69 69 -39 26 -50 28 -162 31 -67 2 -124 7 -128 10 -4 4 -48 192 -98 417 -50 226 -95 422 -101 437 -6 15 -18 29 -26 33 -18 6 -63 -20 -63 -38 0 -6 41 -195 91 -420 50 -224 88 -414 85 -422 -5 -13 -246 -15 -2026 -15 -1762 0 -2021 2 -2026 15 -3 8 64 326 149 707 l154 693 1723 0 1724 0 26 -120 c15 -66 30 -132 33 -147 8 -31 38 -51 62 -42 35 14 36 38 0 198 l-35 155 0 408 c0 250 -4 422 -10 445 -13 45 -51 93 -94 116 -26 14 -70 18 -281 22 l-250 5 0 80 0 80 265 5 c252 5 267 6 312 28 61 30 114 93 133 156 22 72 21 336 -1 404 -22 67 -63 115 -126 146 l-52 26 -586 0 -586 0 -55 -27z m1254 -86 c61 -40 67 -64 67 -262 0 -201 -7 -225 -72 -265 -32 -20 -48 -20 -621 -18 l-588 3 -40 42 -39 42 0 186 c0 196 4 219 49 259 11 10 35 22 53 26 18 5 287 8 598 7 527 -2 568 -3 593 -20z"/>
    <path d="M1577 4205 c-35 -30 -72 -55 -82 -55 -10 0 -40 20 -66 45 -53 49 -75 55 -110 26 l-24 -19 -5 -448 -5 -448 -32 -8 c-55 -14 -66 -73 -17 -92 21 -8 1137 -8 1158 0 49 19 38 78 -17 92 l-32 8 -5 448 -5 448 -24 19 c-35 29 -57 23 -110 -26 -26 -25 -57 -45 -68 -45 -10 0 -32 13 -48 30 -16 16 -46 42 -67 56 -33 23 -41 25 -66 15 -16 -7 -49 -32 -73 -57 -24 -24 -52 -44 -62 -44 -11 0 -46 25 -79 55 -32 30 -68 55 -78 55 -10 0 -47 -25 -83 -55z m474 -120 c57 -55 92 -68 116 -44 6 6 23 9 39 7 l29 -3 0 -370 0 -370 -420 0 -420 0 0 370 0 370 29 3 c16 2 33 -1 39 -7 22 -22 57 -10 113 39 36 31 66 50 82 50 16 0 44 -17 78 -50 70 -66 89 -66 162 5 32 30 66 55 76 55 11 0 45 -25 77 -55z"/>
    <path d="M1587 3832 c-23 -25 -21 -48 5 -66 19 -13 58 -16 223 -16 213 0 245 6 245 50 0 46 -18 50 -244 50 -194 0 -214 -2 -229 -18z"/>
    <path d="M1600 3587 c-30 -15 -37 -48 -15 -71 14 -14 46 -16 230 -16 184 0 216 2 230 16 9 9 15 26 13 37 -6 40 -41 47 -243 47 -133 0 -198 -4 -215 -13z"/>
    <path d="M2942 3710 c-64 -39 -72 -67 -72 -240 0 -184 9 -212 80 -248 l45 -22 497 2 c484 3 497 4 524 24 64 47 69 62 72 227 3 142 1 155 -20 196 -43 84 -24 81 -593 81 -481 0 -501 -1 -533 -20z m1046 -236 c2 -119 0 -154 -11 -163 -11 -8 -147 -10 -508 -9 l-494 3 -3 150 c-1 82 0 155 2 162 4 11 102 13 508 11 l503 -3 3 -151z"/>
    <path d="M1385 2706 c-41 -18 -83 -69 -91 -111 -12 -68 0 -168 24 -203 41 -57 72 -67 207 -67 136 0 169 12 208 75 31 49 32 188 2 240 -35 59 -90 80 -215 80 -64 -1 -117 -6 -135 -14z m263 -98 c7 -7 12 -41 12 -82 0 -101 -7 -106 -140 -106 -69 0 -110 4 -118 12 -7 7 -12 42 -12 88 0 46 5 81 12 88 17 17 229 17 246 0z"/>
    <path d="M2070 2698 c-66 -34 -85 -74 -85 -178 1 -98 18 -138 75 -173 31 -19 49 -22 155 -22 132 0 166 11 209 68 32 44 36 180 6 241 -32 67 -78 86 -211 86 -89 0 -112 -3 -149 -22z m268 -90 c7 -7 12 -42 12 -88 0 -46 -5 -81 -12 -88 -8 -8 -49 -12 -117 -12 -134 0 -141 5 -141 105 0 42 5 76 12 83 17 17 229 17 246 0z"/>
    <path d="M2764 2700 c-26 -13 -49 -35 -63 -61 -20 -35 -23 -51 -19 -129 3 -99 18 -129 79 -165 29 -17 52 -20 149 -20 188 0 230 36 230 200 0 108 -18 146 -83 175 -62 29 -235 28 -293 0z m274 -168 c2 -53 -1 -89 -9 -98 -15 -18 -219 -20 -237 -2 -13 13 -17 169 -5 182 4 3 61 5 127 4 l121 -3 3 -83z"/>
    <path d="M3473 2709 c-69 -20 -109 -107 -100 -216 6 -74 30 -120 77 -148 30 -17 52 -20 155 -20 143 0 176 13 213 85 20 38 23 57 20 127 -3 88 -16 116 -72 157 -24 18 -44 21 -144 23 -64 1 -131 -2 -149 -8z m255 -101 c7 -7 12 -42 12 -88 0 -46 -5 -81 -12 -88 -15 -15 -211 -16 -239 -2 -16 9 -19 22 -19 88 0 47 5 83 12 90 17 17 229 17 246 0z"/>
    <path d="M1362 2050 c-47 -29 -72 -89 -72 -174 0 -95 25 -147 85 -176 40 -19 58 -21 164 -18 133 3 157 13 196 79 28 49 28 178 -1 233 -33 64 -68 76 -214 76 -108 0 -130 -3 -158 -20z m286 -92 c17 -17 16 -152 -2 -167 -9 -8 -54 -11 -132 -9 l-119 3 -3 79 c-2 44 -1 85 2 93 7 18 236 19 254 1z"/>
    <path d="M2059 2050 c-53 -28 -73 -77 -74 -175 0 -89 17 -131 68 -169 24 -18 45 -21 148 -24 106 -3 124 -1 164 18 61 29 85 81 85 181 0 87 -19 132 -70 167 -30 20 -44 22 -159 22 -104 -1 -132 -4 -162 -20z m286 -175 l0 -90 -119 -3 c-78 -2 -123 1 -132 9 -18 15 -19 150 -2 167 9 9 48 12 133 10 l120 -3 0 -90z"/>
    <path d="M2749 2047 c-51 -34 -69 -78 -69 -170 0 -156 49 -196 235 -197 125 0 164 13 203 70 19 28 22 46 22 124 0 103 -16 142 -72 176 -28 17 -51 20 -159 20 -114 0 -129 -2 -160 -23z m286 -172 l0 -90 -125 0 -125 0 -3 79 c-2 44 -1 85 2 93 4 11 32 13 128 11 l123 -3 0 -90z"/>
    <path d="M3453 2054 c-52 -26 -76 -75 -80 -163 -4 -65 -1 -83 19 -120 39 -76 61 -85 199 -89 106 -3 124 -1 164 18 60 29 85 81 85 176 0 88 -28 151 -80 176 -48 25 -259 25 -307 2z m285 -167 c2 -50 -1 -84 -9 -93 -15 -18 -229 -20 -247 -2 -13 13 -17 159 -5 172 4 3 63 5 132 4 l126 -3 3 -78z"/>
    <path d="M2500 1183 c-66 -24 -151 -113 -173 -182 -22 -70 -21 -118 3 -184 27 -73 79 -130 148 -164 151 -74 331 1 392 164 40 108 17 207 -70 294 -84 85 -190 110 -300 72z m189 -97 c36 -19 89 -79 97 -112 4 -13 4 -51 2 -83 -7 -102 -83 -171 -188 -171 -105 0 -181 69 -188 171 -2 32 -2 69 2 83 8 32 52 84 91 107 37 23 143 26 184 5z"/>
  </g>
</svg>
