<svg
  xmlns="http://www.w3.org/2000/svg"
  class="feather feather-info-without-circle-custom feather-size-default info-without-circle-custom"
  width="1em"
  height="1em"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
>
  <line x1="12" y1="19" x2="12" y2="10.5"></line>
  <line x1="12" y1="6" x2="12" y2="5"></line>
  <line x1="12" y1="10.5" x2="10" y2="10.5"></line>
  <line x1="9.5" y1="19" x2="14.5" y2="19"></line>
</svg>
