<script>
  import ModalDialog from './ModalDialog.svelte'
  import { tE } from '../stores/i18n'
  import { Button, Dialog } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import counterBundle from '../stores/counterBundle'

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('close')
  }

  const data = {
    language: window.appVariables.configParams.localization.defaultLocale,
    phoneNumber: '',
    priority: false
  }

  function switchLanguage () {
    const index = window.appVariables.configParams.localization.locales.findIndex(l => l.code === data.language)
    const newIndex = (index + 1) % window.appVariables.configParams.localization.locales.length
    data.language = window.appVariables.configParams.localization.locales[newIndex].code
  }

  async function setPhoneNumber () {
    const newPhoneNumber = await Dialog.prompt({
      title: $tE({ de: 'Handynummer', en: 'Phone Number' }),
      message: $tE({ de: 'Handynummer des Kunden eingeben:', en: 'Enter customer\'s phone number:' }),
      icon: 'phone',
      type: 'is-success',
      confirmText: $tE({ de: 'OK', en: 'OK' }),
      cancelText: $tE({ de: 'Abbrechen', en: 'Cancel' }),
      inputType: 'phone',
      size: 'is-large'
    })

    if (newPhoneNumber != null) data.phoneNumber = newPhoneNumber.replace(/[^\d+]/g, '').replace(/^00/, '+').replace(/^(?=6)/, '0').replace(/^0(?=[1-9])/, window.appVariables.configParams.localization.phonePrefix).replace(/^(?=\d)/, '+')
  }
</script>

<svelte:window on:employeeMenuTimeout={close} />

<ModalDialog --width="95vw" class="employee-ui">
  <main>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">{$tE({ de: 'Kunde in Warteschlange registrieren', en: 'Register Customer in Queue' })}</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item"></div>
      </div>
    </div>

    <div class="big-buttons">
      <Button class="big-button" type="is-dark" on:click={close} iconLeft="arrow-left">{$tE({ de: 'Zurück', en: 'Back' })}</Button>

      <Button class="big-button" type="is-secondary" on:click={switchLanguage} iconLeft="globe-americas">{$tE({ de: 'Sprache', en: 'Language' })} [<strong class="has-text-info">{data.language.toUpperCase()}</strong>]</Button>

      <Button class="big-button" type="is-secondary" on:click={setPhoneNumber} iconLeft="phone">{$tE({ de: 'Handy', en: 'Phone' })} [<strong class="has-text-info">{data.phoneNumber || '---'}</strong>]</Button>

      <Button class="big-button" type="is-secondary" on:click={() => (data.priority = !data.priority)} iconLeft="fast-forward">{$tE({ de: 'Priorität', en: 'Priority' })} [{#if data.priority}<strong class="has-text-success">{$tE({ de: 'JA', en: 'YES' })}</strong>{:else}<strong class="has-text-danger">{$tE({ de: 'NEIN', en: 'NO' })}</strong>{/if}]</Button>

      <Button class="big-button" type="is-success" on:click={() => dispatch('close', data)} iconLeft="check">{$tE({ de: 'Speichern', en: 'Save' })}</Button>

      <h3 class="title is-3 is-inline-block">
        {$tE({ de: 'Wartezeit ca. {n} Min.', en: 'Approx. {n} min. waiting time' }, { n: Math.round($counterBundle.queueStats.totalWaitingDuration) || '???' })}
      </h3>
    </div>
  </main>
</ModalDialog>
