<script>
  /* eslint-disable svelte/no-at-html-tags */

  import { tC } from '../stores/i18n'
  import session, { cart, wishlist, currentView, cartTotal, isMatchingFilter, paymentState, newCustomer } from '../stores/session'
  import { bundle } from '../stores/bundle'
  import { formatCurrency, getDescriptionPreview, handleOverscroll, noopScroll, rippleOnTouch, validateArticleConfiguration } from '../lib/utils'
  import BrandButton from './BrandButton.svelte'
  import BrandButtonRow from './BrandButtonRow.svelte'
  import Plus from 'svelte-feather/components/Plus.svelte'
  import Minus from 'svelte-feather/components/Minus.svelte'
  import X from 'svelte-feather/components/X.svelte'
  import ShoppingCart from 'svelte-feather/components/ShoppingCart.svelte'
  import Heart from 'svelte-feather/components/Heart.svelte'
  import ArrowLeft from 'svelte-feather/components/ArrowLeft.svelte'
  import AlertTriangle from 'svelte-feather/components/AlertTriangle.svelte'
  import Info from 'svelte-feather/components/Info.svelte'
  import HelpCircle from 'svelte-feather/components/HelpCircle.svelte'
  import { fly, scale, slide } from 'svelte/transition'
  import Debouncer from '../lib/Debouncer'
  import { createEventDispatcher } from 'svelte'
  import SpicinessIndicator from './SpicinessIndicator.svelte'
  import MobileTooltip from './MobileTooltip.svelte'
  import DietIndicator from './DietIndicator.svelte'
  import dialogs from '../stores/dialogs'
  import MessageDialog from './MessageDialog.svelte'
  import { table, isTestTableMode } from '../stores/table'
  import PhoneNumberDialog from './PhoneNumberDialog.svelte'
  import ChevronsLeft from 'svelte-feather/components/ChevronsLeft.svelte'
  import PreviousOrderDialog from './PreviousOrderDialog.svelte'
  import uri from 'uri-tag'
  import { apiCall } from '../lib/api'

  import { createLoadingStore } from '../stores/loading'

  const previousOrderLoading = createLoadingStore()

  const dispatch = createEventDispatcher()

  export let type = 'cart'

  $: store = type === 'cart' ? cart : wishlist

  $: total = type === 'cart' ? $cartTotal : 0

  const dietOrder = { na: 0, vegan: 1, vegetarian: 2, omnivore: 3 }
  $: overallSpiciness = type === 'cart' && Math.max(0, ...$store.flatMap(item => [item, ...item.subArticles]).map(item => $bundle.articles[item.article]?.spiciness ?? 0))
  $: overallDiet = type === 'cart' && Object.keys(dietOrder)[Math.max(0, ...$store.flatMap(item => [item, ...item.subArticles]).map(item => dietOrder[$bundle.articles[item.article]?.diet ?? 'na'] ?? 0))]
  $: overallAgeRestriction = type === 'cart' && Math.max(0, ...$store.flatMap(item => [item, ...item.subArticles]).map(item => $bundle.articles[item.article]?.ageRestriction ?? 0))
  $: overallAllergens = type === 'cart' && [...new Set($store.flatMap(item => [item, ...item.subArticles]).flatMap(item => $bundle.articles[item.article]?.allergens ?? []).filter(x => x !== 'none'))].sort()

  function updateQuantity (item, quantity) {
    item.quantity = quantity

    if (quantity <= 0) {
      $store = $store.filter(i => i !== item)
    } else {
      $store = $store
    }

    dispatch('change', { item })
  }

  let listEl
  function setListEl (element) {
    // Can't use bind:this because of the transitions, it would get overridden with null when the other list gets destroyed when switching between tabs
    listEl = element
  }

  const debouncer = new Debouncer(750, true)

  function scrollToNewItem (e) {
    debouncer.debounce(() => {
      // 150 = overscroller height, 24 = $default-margin
      // There is still something not quite right atm because of the padding, somehow. But this causes a little "hop" that does look good, actually.
      listEl.scrollTo({ top: Math.min(Math.max(e.target.offsetTop + e.target.offsetHeight - listEl.offsetHeight + 24 + (listEl.parentNode.querySelector('.properties')?.offsetHeight ?? 0), 150), listEl.scrollHeight - 2 * 150), behavior: 'smooth' })
    })
  }

  async function payNow (fromNfc = false) {
    const isValid = $store.every(item => validateArticleConfiguration(item, $bundle).isValid) && total > 0
    if (!isValid) {
      dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Der Warenkorb enthält Artikel, die nicht mehr verfügbar sind. Bitte kontrollieren Sie Ihre Auswahl und versuchen Sie es erneut!', en: 'The cart contains articles that are no longer available. Please check your selection and try again!' }), iconClass: 'has-text-danger', Icon: AlertTriangle })
      return
    }

    if ($isTestTableMode) {
      dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Testmodus ist aktiv - keine Bezahlung möglich.', en: 'Test mode is active - no payment possible.' }), iconClass: 'has-text-primary', Icon: Info })
      return
    }

    if ($table?.type === 'kiosk') {
      const data = await dialogs.open(PhoneNumberDialog, { ...$session.userData, forPickup: true })
      if (data == null) return
      if (data) {
        $session.userData = Object.assign($session.userData, data)
      }
    }

    $paymentState = fromNfc ? 'fromNfc' : 'choice'
    $currentView = 'payment'
  }

  async function onUnknownNfcTag () {
    if (!(total > 0)) {
      await dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Der Warenkorb ist leer. Bitte legen Sie Artikel in den Warenkorb, bevor Sie bezahlen.', en: 'The cart is empty. Please add items to the cart before paying.' }), iconClass: 'has-text-danger', Icon: AlertTriangle })
      return
    }

    if (await dialogs.open(MessageDialog, { title: $tC({ de: 'Jetzt zahlen?', en: 'Pay Now?' }), text: $tC({ de: 'Scheinbar haben Sie eine Karte vorgehalten. Möchten Sie jetzt Ihre Bestellung über {amount} direkt bezahlen?', en: 'Apparently you have tapped a card. Would you like to pay your order of {amount} right now?' }, { amount: formatCurrency(total) }), iconClass: 'has-text-primary', Icon: HelpCircle, yesNo: true })) {
      payNow(true)
    }
  }

  async function newCustomerConfirm () {
    if (!$cart.length || await dialogs.open(MessageDialog, { title: $tC({ de: 'Abbrechen?', en: 'Cancel?' }), text: $tC({ de: 'Sind Sie sicher, dass Sie abbrechen möchten? Ihre aktuelle Bestellung wird gelöscht.', en: 'Are you sure you want to cancel? Your current order will be deleted.' }), iconClass: 'has-text-danger', Icon: HelpCircle, yesNo: true })) {
      newCustomer()
    }
  }

  async function openPreviousOrder () {
    await previousOrderLoading(async () => {
      try {
        const order = await apiCall('GET', uri`/api/app/lastOrder/${$session.id}`)
        const result = await dialogs.open(PreviousOrderDialog, { order })
        if (result?.addToCartItem) dispatch('addExtraItemToCart', { item: result.addToCartItem })
      } catch (e) {
        console.error('Failed to load last order', e)
        await dialogs.open(MessageDialog, { title: $tC({ de: 'Fehler', en: 'Error' }), text: $tC({ de: 'Leider war es nicht möglich, Ihre letzte Bestellung zu laden! Bitte wenden Sie sich an einen Mitarbeiter.', en: 'Unfortunately, it was not possible to load your last order! Please contact a staff member.' }), iconClass: 'has-text-danger', Icon: AlertTriangle })
      }
    })
  }
</script>

<style lang="scss">
  main {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    padding: $default-margin;

    > * {
      min-width: 0;
      min-height: 0;
    }

    .bg-icon {
      grid-area: 1 / 1 / span 2 / span 1;

      padding: 20%;
      opacity: 0.3;
      pointer-events: none;

      :global(svg) {
        width: 100%;
        height: 100%;
        object-fit: contain;
        stroke-width: 0.1px;
      }
    }

    p.empty-cart-msg {
      grid-area: 1 / 1 / span 2 / span 1;
    }

    .list-container {
      grid-area: 1 / 1 / span 1 / span 1;
      overflow: auto;
      position: relative;
      margin-top: -$default-margin;

      ul {
        margin-top: $default-margin;
        padding-bottom: 1rem;
      }
    }

    .button-container {
      grid-area: 2 / 1 / span 1 / span 1;
      position: relative;

      $fade-out-height: 2rem;

      &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: $fade-out-height;
        background: linear-gradient(to bottom,
          rgba(white, 0) 0%,
          rgba(white, 0.005) 2%,
          rgba(white, 0.01) 4%,
          rgba(white, 0.02) 6%,
          rgba(white, 0.035) 8%,
          rgba(white, 0.05) 10%,
          rgba(white, 0.125) 12%,
          rgba(white, 0.25) 14%,
          rgba(white, 0.4) 16%,
          rgba(white, 0.6) 20%,
          rgba(white, 0.75) 30%,
          rgba(white, 0.9) 40%,
          rgba(white, 0.95) 50%,
          rgba(white, 1) 75%,
          rgba(white, 1) 100%
        );

        background-position-y: calc($fade-out-height * 0.25);
        background-repeat: no-repeat;

        pointer-events: none;
      }
    }

    li {
      @property --bg-color {
        syntax: '<color>';
        initial-value: white;
        inherits: false;
      }

      --bg-color: white;

      padding: 0.75rem 0;
      border-bottom: 1px solid $border;
      // padding-bottom: 0.75rem;
      background: var(--bg-color);
      cursor: pointer;
      position: relative;
      hyphens: auto;
      hyphenate-limit-chars: 8 4 4;

      display: grid;
      grid-template-columns: min-content 1fr;
      grid-auto-rows: auto;
      column-gap: 0.5rem;
      row-gap: 0.25rem;

      > * {
        min-width: 0;
        min-height: 0;
      }

      &.is-wishlist-item {
        column-gap: 0;
      }

      .quantity {
        grid-column: 1;
      }

      .name-and-price {
        grid-column: 2;

        display: grid;
        grid-template-columns: 1fr min-content;
        grid-template-rows: auto;
        column-gap: 0.25rem;

        .name {
          text-transform: uppercase;
          font-weight: bold;
        }

        .inside-container {
          grid-row: 1 / 3;
          grid-column: 1;
          overflow: hidden;
          height: 3.75rem;
          position: relative;

          -webkit-line-clamp: 3;
          display: -webkit-box;
          -webkit-box-orient: vertical;

          .price {
            grid-row: 1;
            justify-self: start;
          }
        }

        .name.sub-item {
          grid-row: 1;
          grid-column: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .price {
          grid-column: 2;
          white-space: nowrap;
          text-align: right;
          margin-left: 0.25rem;
        }

        :global(.brand-button-row) {
          grid-row: 2;
          justify-self: end;
          margin-bottom: -0.25rem;
        }
      }

      .comment {
        grid-column: 2;
        font-style: italic;
      }
    }
  }

  .properties {
    text-align: center;
    padding-bottom: 1rem;

    :global(.spiciness), :global(.diet) {
      font-size: 1.25em;
      transform: translateY(-0.05em);
      vertical-align: middle;
    }

    .age-restriction {
      display: inline-flex;
      font-size: 75%;
      background: $danger;
      color: white;
      border-radius: 50%;
      letter-spacing: -1px;
      width: 1.25em;
      height: 1.25em;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      transform: translateY(-0.1em);
      padding-right: 1px;
      padding-top: 1.5px;
    }
  }

  .is-unavailable {
    color: inherit;
    opacity: inherit;

    .name, .price {
      color: $danger;
      text-decoration: line-through;
    }
  }
</style>

<svelte:window on:unknownNfcTag={onUnknownNfcTag} />

{#key type}
  <main in:fly={{ x: type === 'cart' ? -24 : 24, delay: 400 }} out:fly={{ x: type === 'cart' ? 24 : -24 }}>
    {#if $store.length === 0}
      <div class="bg-icon" transition:scale>
        {#if type === 'cart'}
          <ShoppingCart />
        {:else if type === 'wishlist'}
          <Heart />
        {/if}
      </div>
      <p class="has-text-centered empty-cart-msg" transition:fly={{ y: 30 }}>{$tC(type === 'cart' ? { de: 'Der Warenkorb ist noch leer.', en: 'The cart is still empty.' } : { de: 'Die Vielleicht-Liste ist noch leer.', en: 'The wishlist is still empty.' })}</p>
    {/if}

    <div class="list-container" on:scroll={() => listEl.querySelectorAll('.ripple-container').forEach(container => container.remove())} use:handleOverscroll use:setListEl>
      <ul>
        {#each $store as item (item.uuid ?? item.article)}
          {@const article = $bundle.articles[item.article]}
          <li class:is-wishlist-item={type === 'wishlist'} use:rippleOnTouch transition:slide on:introend={scrollToNewItem} on:outroend={() => noopScroll(listEl)} on:click={() => dispatch('itemClick', item)} data-uuid={item.uuid} class:is-unavailable={!article?.available}>
            {#if type !== 'wishlist'}
              <div class="quantity">
                {item.quantity}&times;
              </div>
            {/if}
            <div class="name-and-price">
              <div class="inside-container">
                <div class="name" class:is-not-matching-filter={!$isMatchingFilter(article)}>
                  {$tC(article?.name ?? { de: 'Unbekannter Artikel', en: 'Unknown Article' })}
                </div>
                <div class="description">
                  {$tC(article?.shortDescription)?.trim() || getDescriptionPreview($tC(article?.description))}
                </div>
              </div>
              <div class="price">
                {$tC && formatCurrency(type === 'wishlist' ? article?.minPrice : ((article?.posArticle?.price ?? 0) * item.quantity))}
              </div>
              <BrandButtonRow>
                <div style:flex="1"></div>
                {#if type === 'cart'}
                  <BrandButton icon small on:click={() => updateQuantity(item, item.quantity + 1)} disabled={article?.allowOnlySingleQuantity}><Plus /></BrandButton>
                  <BrandButton icon small on:click={() => updateQuantity(item, item.quantity - 1)}><Minus /></BrandButton>
                {:else if type === 'wishlist'}
                <BrandButton icon small on:click={() => updateQuantity(item, 0)}><X /></BrandButton>
                {/if}
              </BrandButtonRow>
            </div>

            {#if type !== 'wishlist'}
              {#each item.subArticles as subArticleInfo}
                {@const subArticle = $bundle.articles[subArticleInfo.article]}
                <div class="sub-item quantity">
                  {subArticleInfo.quantity * item.quantity}&times;
                </div>
                <div class="sub-item name-and-price" class:is-unavailable={!subArticle?.available}>
                  <div class="sub-item name" class:is-not-matching-filter={!$isMatchingFilter(subArticle)}>
                    {$tC(subArticle?.name ?? { de: 'Unbekannter Artikel', en: 'Unknown Article' })}
                  </div>
                  <div class="sub-item price">
                    {$tC && formatCurrency((subArticle?.posArticle?.price ?? 0) * subArticleInfo.quantity * item.quantity)}
                  </div>
                </div>
              {/each}
              {#if item.comment}
                <div class="comment">
                  {item.comment}
                </div>
              {/if}
            {/if}
          </li>
        {/each}
      </ul>

      {#if type === 'cart'}
        <div class="properties">
          <SpicinessIndicator spiciness={overallSpiciness} withTooltip />
          <DietIndicator diet={overallDiet} withTooltip showOmnivoreIcon />
          {#if overallAgeRestriction > 0}
            <MobileTooltip label={$tC({ de: 'Ab {y} Jahren', en: 'From {y} years' }, { y: overallAgeRestriction })}><span class="age-restriction">{overallAgeRestriction}</span></MobileTooltip>
          {/if}
          {#if overallAllergens.length}
            <MobileTooltip label={$tC({ de: 'Allergene', en: 'Allergens' })}><div class="allergens">{overallAllergens.join(', ')}</div></MobileTooltip>
          {/if}
        </div>
      {/if}
    </div>

    <div class="button-container">
      {#if type === 'cart'}
        <BrandButton class="is-fullwidth is-primary" disabled={!(total > 0)} on:click={() => payNow(false)}><strong>{$tC({ de: 'Bezahlen', en: 'Pay Now' })} ({$tC && formatCurrency(total)})</strong></BrandButton>
      {:else if type === 'wishlist'}
        <BrandButton class="is-fullwidth" on:click={() => (type = 'cart')}><ArrowLeft /> {$tC({ de: 'Zum Warenkorb', en: 'To Cart' })}</BrandButton>
      {/if}
      {#if $table?.type === 'kiosk'}
        <BrandButton class="is-fullwidth is-secondary mt-3" on:click={newCustomerConfirm}><strong><X width="2px" /> {$tC({ de: 'Neustart', en: 'Start Over' })}</strong></BrandButton>
      {/if}
      {#if $session.orders.length && type === 'cart'}
        <BrandButton class="is-fullwidth is-secondary mt-3" loading={$previousOrderLoading} on:click={openPreviousOrder}><strong><ChevronsLeft width="2px" /> {$tC({ de: 'Vorige Bestellung', en: 'Previous Order' })}</strong></BrandButton>
      {/if}
    </div>
  </main>
{/key}
