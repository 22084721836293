<script>
  import html from 'html-template-tag'

  import { click, goto, pattern } from 'svelte-pathfinder'
  import routes from './routes'

  import { Toast } from 'svelma-fixed'

  import { initAppInfo } from './lib/appInfo'
  import lastKnownVersionId from './stores/version'
  import { formatError, withProcessingOverlay } from './lib/utils'
  import dialogs from './stores/dialogs'
  import { initYaoKiosk, isYaoKiosk } from './stores/yaoKiosk'
  import { connectWebsocket, loadSelfUserInfo, selfUserInfo } from './lib/api'
  import { updateBundle } from './stores/bundle'
  import { updateCounterBundle } from './stores/counterBundle'
  import { updateTable } from './stores/table'
  import { session, updateSession } from './stores/session'
  import { localState } from './stores/localState'
  import { currentDevOptions } from './stores/devOptions'
  import './lib/employeeMenu'
  import Processing from './components/Processing.svelte'

  $lastKnownVersionId = window.appVariables.versionId // eslint-disable-line prefer-const
  $: if ($lastKnownVersionId !== window.appVariables.versionId) {
    console.log('Detected new version from another tab:', window.appVariables.versionId)
    location.reload()
  }

  let params
  $: page = routes.find(route => (params = $pattern(route.path))) || routes.find(route => route.path === '*') || null
  $: if (page.redirect) goto(page.redirect)

  const appInfoPromise = (async () => {
    try {
      await initYaoKiosk()
      await initAppInfo()
      await Promise.all([loadSelfUserInfo(), updateBundle(), updateTable(true)])
      await updateSession() // After updateTable because it needs the tableId

      if (selfUserInfo.role === 'employee') await updateCounterBundle()

      await connectWebsocket()

      if ($localState.paymentInProgress?.sessionId === $session.id) {
        console.log('Resuming payment', $localState.paymentInProgress)
        // If the payment was in progress when the app was reloaded, we need to resume it
        $session.uiState.tipAmount = $localState.paymentInProgress.tipAmount
        $session.uiState.paymentState = 'processing'
        $session.uiState.currentView = 'payment'
      }

      window.onerror = (message, file, row, col, error) => {
        if (message === 'ResizeObserver loop limit exceeded') return // Not sure where this error comes from
        if (message === 'Uncaught Error: zoom requires valid numbers') return // Hard-to-fix issue with pan & zoom library when pinching on close button
        if (message.includes('Still in CONNECTING')) return // This error is thrown when the websocket is not ready, but it looks ugly
        Toast.create({ message: html`An error occured: ${formatError(error) ?? message}`, type: 'is-danger' })
      }

      window.onunhandledrejection = ({ reason }) => {
        if (String(reason).includes('Still in CONNECTING')) return // This error is thrown when the websocket is not ready, but it looks ugly
        Toast.create({ message: html`An error occured: ${formatError(reason)}`, type: 'is-danger' })
      }

      document.body.classList.add('app-ready')

      if (!currentDevOptions.debugOptions.allowSelection && isYaoKiosk) {
        document.body.classList.add('prevent-selection')
      }
    } catch (e) {
      setTimeout(() => { throw e }, 0) // We want the global onerror to handle this one too
      throw e
    }
  })()
</script>

<style lang="scss" global>
  @import 'styles/app.scss';
</style>

<svelte:window on:click={click} />

{#await appInfoPromise}
  <!-- Loading -->
{:then}
  {#if page}
    <svelte:component this={page.component} {params} />
  {/if}
{:catch}
  <!-- onerror will show the error here -->
{/await}

{#each $dialogs as dialog (dialog.id)}
  <svelte:component this={dialog.Component} {...dialog.props} on:close={e => dialogs.close(dialog.id, e.detail)} />
{/each}

{#if $withProcessingOverlay}
  <Processing />
{/if}
